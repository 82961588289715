import HeroSliderLeft from "pages/liveTvPage/home/HeroSliderLeft";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay, Pagination } from "swiper";
import { useEffect, useState } from "react";
import Sliders from "services/sliders/Sliders";
import WelcomeHeroSlider from "components/skeleton/WelcomeHeroSlider";
function HeroSlider() {
  const [slider, setSlider] = useState<any[]>([]);
  const [isLoading, setIsLoding] = useState<any>(false);

  useEffect(() => {
    setIsLoding(true);
    const getSliderData = async () => {
      await Sliders.welcomePageSlider().then((res) => {
        setIsLoding(false);
        setSlider(res.data);
      });
    };

    getSliderData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoding(false);
    }, 3000);
  }, [isLoading]);

  return (
    <>
      {!isLoading && slider && slider?.length > 0 && (
        <>
          <Swiper
            navigation={false}
            modules={[Navigation, Autoplay, Pagination]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: true,
            }}
            className="mt-4 mb-10 mySwiper"
          >
            {slider?.map((d: any, i) => (
              <SwiperSlide key={i}>
                <HeroSliderLeft data={d} />
              </SwiperSlide>
            ))}
          </Swiper>
          
        </>
      )}
      {isLoading && slider?.length > 0 && <WelcomeHeroSlider />}
    </>
  );
}

export default HeroSlider;
