import PlayerShowNext from "components/PlayerShowNext";
import { AssetTypes } from "constant/AssetTypes";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { adjustVolume } from "redux/AudioPlayerRedux";
import { addLastWatch, adjustVolumeVideo } from "redux/VideoPlayerRedux";
import VideoService from "services/video/VideoService";
import videojs from "video.js";

// add viewport height and width
const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  width: 1920,
  height: 1080,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};

// var CryptoJS = require("crypto-js");

export interface VideoLastWatch {
  currentTime: number;
  id: number;
  type: AssetTypes.Movie | AssetTypes.TVSeries;
  slug: string;
  title: string;
  duration: number;
  thumbnail: string;
  tv_show_name?: string;
  season_name?: string;
  stream?: string;
}

const VideoPlayer = ({ videoDetails, currentTime }: any) => {
  const dispatch = useDispatch<any>();
  const { slug } = useParams();
  const videoNode = useRef<HTMLVideoElement | null>(null);
  const player = useRef<videojs.Player>();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const iframeURL = process.env.REACT_APP_VIDEOPLAYER_IFRAME_URL;
  const BASE_URL = process.env.REACT_APP_PLAYERHOST;
  const { currentVideo, isFetching } = useSelector(
    (state: any) => state?.video
  );
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [lastWatchTime, setLastWatchTime] = useState<number>(0);
  const [duration, setDuration] = useState<any>(0);
  const [showNext, setShowNext] = useState<boolean>(false);
  const [sendCount, setSendCount] = useState<boolean>(false);

  const [timeArr, setTimeArr] = useState<any[]>([]);
  const [triggerRemoveLastWatch, setTriggerRemoveLastWatch] =
    useState<boolean>(false);

  const [dimensions, setDimensions] = useState({
    width: "100%",
    height: "100%",
  });

  const volume = useSelector((state: any) => state?.video?.volume);

  console.log(volume);

  useEffect(() => {
    // console.log('Data in react server: ' + lastWatchTime);

    let objectToSave: any = {
      id: currentVideo?.id,
      type: currentVideo?.season_name ? AssetTypes.TVSeries : AssetTypes.Movie,
      stream: currentVideo?.stream_key,
      tv_show_name: currentVideo?.tv_show_name,
      season_name: currentVideo?.season_name,
      currentTime: lastWatchTime,
      slug: slug,
      title: currentVideo?.title,
      duration: duration,
      thumbnail: currentVideo?.season_name
        ? currentVideo?.thumbnail
        : currentVideo?.thumbnail_url,
    };
    // console.log(objectToSave);
    // saveDataToLocalStorage(objectToSave);
    if (lastWatchTime > 8 && duration > 5) {
      dispatch(addLastWatch(objectToSave));

      if (loggedInUser) {
        // console.log('Saved to redux and send api call : ', objectToSave);

        if ((lastWatchTime / duration) * 100 > 95 && !triggerRemoveLastWatch) {
          // remove the last watch if user reach 95% of the totla duration of the video
          const apiObj = {
            content_id: currentVideo?.id,
            content_type: currentVideo?.season_name ? "tv_show" : "movie",
          };
          console.log("remove last watch : ", apiObj);
          VideoService.removeLastWatch(apiObj);
          setTriggerRemoveLastWatch(true);
        } else {
          //  else update the last watch in every 5 mins
          if (lastWatchTime % (5 * 60) === 0) {
            const apiObj = {
              content_id: currentVideo?.id,
              current_time: lastWatchTime,
              duration: duration,
            };

            if (currentVideo?.season_name) {
              VideoService.updateTVSeriesLastWatch(apiObj);
            } else {
              VideoService.updateMovieLastWatch(apiObj);
            }
            console.log("add last watch : ", apiObj);
          }
        }
      }
    }

    if (currentVideo?.season_name) {
      showNextSeries(lastWatchTime);
    }

    if (!sendCount) {
      updateTimeArray(lastWatchTime);
    }
    resizeIframe();
  }, [lastWatchTime]);

  const updateTimeArray = (time: number) => {
    if (time - timeArr[timeArr.length - 1] === 10) {
      setTimeArr([...timeArr, time]);
    } else {
      setTimeArr([time]);
    }
  };

  useEffect(() => {
    if (timeArr[timeArr.length - 1] - timeArr[0] === 30) {
      if (!sendCount) {
        // console.log('--------------- update count ---------------------------');
        updatePlayCount();
      }
      setSendCount(true);
    }
  }, [timeArr]);

  useEffect(() => {
    setTimeout(() => {
      const videoJsOptions = {
        sources: [
          {
            controls: true,
            responsive: true,
            fluid: true,
            src: currentVideo?.url,
            currentSrc: currentVideo?.url,
            buffered: [0.0, 0.04],
            // type: 'video/mp4',
          },
        ],
      };
      player.current?.dispose();
      if (videoNode.current) {
        player.current = videojs(videoNode.current, {
          ...initialOptions,
          ...videoJsOptions,
        }).ready(function () {
          videoNode.current?.play();
        });
      }
      return () => {
        if (player.current) {
          player.current.dispose();
        }
      };
    }, 3000);
  }, [currentVideo]);

  // console.log(currentVideo);
  const handleMessage = (event: any) => {
    if (event.origin === BASE_URL) {
      // if (event.origin === 'http://127.0.0.1:5500') {
      // console.log(event);
      const receivedData = event.data;
      if (typeof receivedData === "number") {
        // console.log(Math.floor(receivedData));

        setLastWatchTime(Math.floor(receivedData));
        // console.log("trigger nextt", Math.floor(receivedData) / duration);
      }
      if (event.data.type === "duration") {
        // console.log('duration', event.data.time);
        setDuration(event.data.time);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const updatePlayCount = () => {
    const obj = {
      content_id: currentVideo?.id,
      content_type: currentVideo?.season_name ? "tv_show_episode" : "movie",
    };

    VideoService.updatePlayCount(obj);
  };

  const showNextSeries = (receivedData: number) => {
    // console.log(
    //   'time to show next',
    //   receivedData,
    //   'action',
    //   duration - Math.floor(receivedData)
    // );
    if (duration > 300) {
      if (duration - Math.floor(receivedData) < 60) {
        // console.log('trigger show next', duration - Math.floor(receivedData));
        setShowNext(true);
      } else {
        setShowNext(false);
      }
    } else if (duration < 300 && duration > 100) {
      if (duration - Math.floor(receivedData) < 30) {
        // console.log('trigger show next', duration - Math.floor(receivedData));
        setShowNext(true);
      } else {
        setShowNext(false);
      }
    } else {
      setShowNext(false);
    }
  };

  const resizeIframe = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      const aspectRatio = 16 / 9; // Assuming 16:9 aspect ratio
      const maxWidth = window.innerWidth;
      const maxHeight = maxWidth / aspectRatio;

      console.log("Initial max width : " + maxWidth);
      console.log("Initial max height : " + maxHeight);

      // Set dimensions while maintaining aspect ratio
      let newWidth, newHeight;
      if (maxHeight > window.innerHeight) {
        newHeight = window.innerHeight;
        newWidth = newHeight * aspectRatio;
      } else {
        newWidth = maxWidth;
        newHeight = newWidth / aspectRatio;
      }

      setDimensions({
        width: `${newWidth}px`,
        height: `${newHeight}px`,
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      resizeIframe();
    };

    resizeIframe();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleVolume = (event: any) => {
      if (
        event.source === iframeRef?.current?.contentWindow &&
        event.data.type === "volumeChange"
      ) {
        dispatch(adjustVolume(event.data.updatedVolume));
        dispatch(adjustVolumeVideo(event.data.updatedVolume));
      }
    };
    window.addEventListener("message", handleVolume);
    return () => {
      window.removeEventListener("message", handleVolume);
    };
  }, []);

  return (
    <div className="flex justify-center w-full mx-auto max-sm:w-full lg:h-max rounded-xl">
      {/* <video
        ref={videoNode}
        poster={currentVideo?.thumbnail_url}
        id="my-player"
        className="video-js vjs-luxmty vjs-16-9"
        src="http://127.0.0.1:5500/iframe/VideoPlayer.html"
      /> */}

      {!isFetching && (
        <iframe
          title={`AYOZAT Video Player`}
          ref={iframeRef}
          // width="100%"
          // height={"1200px"}
          width={dimensions.width}
          height={dimensions.height}
          // src={"http://127.0.0.1:5500/iframe/VideoPlayer.html"}
          src={iframeURL}
          onLoad={() => {
            const data = {
              source: "my-video",
              src: currentVideo?.url,
              userCurrentTime: currentTime
                ? currentTime
                : currentVideo?.userCurrentTime,
              poster: currentVideo?.poster,
              vastEnabled: true,
              vastTagPreroll: currentVideo?.vast_tag_url,
              pre_ad: currentVideo?.pre_ad,
              vastDuration: currentVideo?.duration
                ? currentVideo?.duration
                : 10,
              volume: volume,
            };
            const iframeWindow = iframeRef.current?.contentWindow;
            iframeWindow?.postMessage(JSON.parse(JSON.stringify(data)), "*");
          }}
          allow="autoplay; clipboard-write; encrypted-media; fullscreen"
          loading="lazy"
        ></iframe>
      )}

      {showNext ? (
        <PlayerShowNext
          videoDetails={videoDetails}
          show={showNext}
          setShowNext={setShowNext}
        />
      ) : (
        <></>
      )}
      {/* <PlayerShowNext
        videoDetails={videoDetails}
        show={true}
        setShowNext={setShowNext}
      /> */}
    </div>
  );
};

export default VideoPlayer;
