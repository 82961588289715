import { BackTop } from "antd";
import { ChatContext } from "context/ChatContext";
import { useUserChatWebSocket } from "hooks/useUserChatWebSocket";
import AllChats from "pages/UserChat/shared/AllChats";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRouter from "routes/Router";
import useQuantcast from "useQuantcast";

function App() {
  const CHAT_SOCKET_URL = process.env.REACT_APP_CHAT_SERVER;
  useQuantcast();

  const [isNewChat, setIsNewChat] = useState<boolean>(false);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const {
    messages,
    chats,
    createNewChat,
    sendMessage,
    notifyTyping,
    changeChatStatus,
    unreadChats,
    setUnreadChats,
    showChatProgress,
    initialScroll,
    latestChat,
    handleLatestChat,
  } = useUserChatWebSocket(
    loggedInUser?.access_token,
    loggedInUser?.id,
    CHAT_SOCKET_URL ? `${CHAT_SOCKET_URL}/user-chat` : "",
    // "ws://localhost:3001/user-chat",
    () => setIsNewChat(true)
  );

  const [allChats, setAllChats] = useState<any>(chats);
  const [selectedChat, setSelectedChat] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChatFirstLoading, setIsChatFirstLoading] = useState<boolean>(false);

  useEffect(() => {
    if (chats.length > 0 && !isChatFirstLoading) {
      console.log("chats updated");
      setAllChats(chats);
      setIsChatFirstLoading(true);
    }
  }, [chats]);

  useEffect(() => {
    const chatIndex = allChats?.findIndex((chat: any) => {
      return chat.id == messages?.chat_id;
    });

    if (chatIndex !== -1) {
      let updatedChats = [...allChats];

      const convertedLatsetMsg = {
        ...messages,
        chat_id: parseInt(messages?.chat_id),
      };

      // const newChatIndex = updatedChats?.findIndex((chat: any) => {
      //   return chat.id == messages?.chat_id;
      // });

      const updatedChat = {
        ...updatedChats[chatIndex],
        latest_message: convertedLatsetMsg,
      };

      updatedChats[chatIndex] = updatedChat;

      setIsLoading(true);
      const sortedChats = updatedChats?.sort((a: any, b: any) => {
        const dateA = new Date(a.latest_message?.updated_at || 0);
        const dateB = new Date(b.latest_message?.updated_at || 0);
        return dateB.getTime() - dateA.getTime();
      });

      console.log("test", sortedChats);

      // setAllChats(sortedChats);
      setIsLoading(false);
    }
  }, [messages]);

  useEffect(() => {
    console.log("allChats", allChats);
  }, [allChats]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BackTop />

      <ChatContext.Provider
        value={{
          messages,
          chats,
          createNewChat,
          sendMessage,
          notifyTyping,
          changeChatStatus,
          showChatProgress,
          initialScroll,
          latestChat,
          selectedChat,
          setSelectedChat,
          isLoading,
          setIsLoading,
          allChats,
          setAllChats,
          isNewChat,
          handleLatestChat,
          unreadChats,
          setUnreadChats,
        }}
      >
        <AppRouter />
      </ChatContext.Provider>
    </>
  );
}

export default App;
