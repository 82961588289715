import React, { useEffect } from "react";
import BlogHeroHome from "./Home/BlogHeroHome";
import BlogCategoryCarousal from "./Home/BlogCategoryCarousal";
import BlogPageHeader from "./Home/BlogPageHeader";
import Logo from "Assets/ayozat_logo.svg";
import { RouteName } from "constant/routeNames";
import { Helmet } from "react-helmet-async";
// import ppvHeader from "Assets/Images/ppv-landing-header.jpeg";

const FE_URL = process.env.REACT_APP_FRONTEND_URL;

const BlogsHome = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);
  return (
    <div className="w-full ">
      <Helmet>
        <title>
          AYOZAT Blog: Dive Deeper into Your Favorite Shows & Movies
        </title>
        <meta
          name="description"
          content="Go beyond the screen with exclusive interviews, behind-the-scenes content, and insightful articles."
        />
        <link rel="canonical" href={`${FE_URL}/blogs`} />
        <meta
          property="og:title"
          content="AYOZAT Blog: Dive Deeper into Your Favorite Shows & Movies"
        />
        <meta
          property="og:description"
          content="Go beyond the screen with exclusive interviews, behind-the-scenes content, and insightful articles."
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:type" content="website" />
        <meta name="og:url" content={`${FE_URL}/blogs`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="AYOZAT Blog: Dive Deeper into Your Favorite Shows & Movies"
        />
        <meta
          name="twitter:description"
          content="Go beyond the screen with exclusive interviews, behind-the-scenes content, and insightful articles."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT STREAM" />
        <meta name="twitter:url" content={`${FE_URL}/blogs`} />
      </Helmet>
      <BlogPageHeader
        Logo={Logo}
        link={RouteName.BlogDiscoverRoute.replace("*", "")}
        // image={ppvHeader}
      />
      <BlogHeroHome />
      <BlogCategoryCarousal />
    </div>
  );
};

export default BlogsHome;
