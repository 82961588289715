import { useState } from "react";
import ChatWidget from "../../../components/real-chat/ChatWidget";

function PPVideoPlayerRightSidePanel({
  isActiveRight,
  videoDetails,
  setIsNewChat,
  isNewChat,
  star,
}: any) {
  return (
    <>
      <div
        className={`h-[150vh] !z-50 overflow-y-hidden -mt-[88px] duration-500 fixed max-sm:mt-[10px] max-sm:w-full max-sm:h-auto max-sm:relative max-sm:px-0 max-md:w-full max-md:relative max-md:px-0 max-md:h-auto bg-[#131313]

  ${
    isActiveRight
      ? "right-0  md:w-[480px]  "
      : "md:right-[-100px] right-[-60px]  md:w-[100px] max-sm:right-0 max-md:right-0 max-lg:right-0"
  }`}
      >
        {/* {/ icon bar /} */}
        <div className="flex flex-col text-gray-100 pt-32 px-5  text-left justify-between w-[480px] max-sm:p-0 max-md:p-0  !max-sm:w-[100%] max-md:w-[100%] mx-auto max-sm:justify-center">
          <div className="flex flex-col ">
            {/* <h1 className="text-[20px] max-sm:h-[100px] max-md:h-[50px] absolute font-bold mt-[-10px] bg-[#131313] pb-[38px] px-1 w-[90%] max-sm:w-full max-md:w-full">
              <span className="max-sm:hidden max-md:hidden">
                Chat Section
                <p className="text-gray-500 text-[15px]">
                  {videoDetails?.discord_channel_id}
                </p>
              </span>
            </h1> */}
            <div className="pt-12 max-sm:w-[95%] max-sm:mx-auto h-[100vh]  ">
              {/* <DiscordChat
                channelId={`${videoDetails?.discord_channel_name}`}
                width={"100%"}
                height={"550px"}
                serverId="1181252454935961680"
              /> */}
              <ChatWidget
                event={videoDetails}
                setIsNewChat={setIsNewChat}
                isActiveRight={isActiveRight}
                isNewChat={isNewChat}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PPVideoPlayerRightSidePanel;
