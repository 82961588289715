import { useEffect, useState } from "react";

export interface IUnreadChat {
  chatId: number;
  isRead: boolean;
  count: number;
}

export const useUserChatWebSocket = (
  token: string,
  userId: number,
  wsUrl: string,
  onNewMessage: () => void
) => {
  const [messages, setMessages] = useState<any>();
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [showChatProgress, setChatProgress] = useState();
  const [initialScroll, setInitialScroll] = useState<boolean>(false);
  const [chats, setChats] = useState<any[]>([]);
  const [latestChat, setLatestChat] = useState<any>();
  const [unreadChats, setUnreadChats] = useState<IUnreadChat[]>([]);
  // useEffect(() => {
  //   console.log("latestChat : ", latestChat);
  // }, [latestChat]);
  const handleNewChat = (newChat: any) => {
    setChats((prevChats) => {
      // Combine the existing chats with the new chat
      const combinedChats = [newChat, ...prevChats];

      // Use a Set to filter out duplicates
      const uniqueChats = Array.from(
        new Map(combinedChats.map((chat) => [chat.id, chat])).values()
      );

      return uniqueChats;
    });
  };

  useEffect(() => {
    const socket = new WebSocket(wsUrl);
    setWs(socket);

    socket.onopen = () => {
      console.log("Connected to WebSocket");
      socket.send(
        JSON.stringify({ event: "joinRoom", data: { userId, token } })
      );

      setInitialScroll(true);
    };

    socket.onmessage = (event) => {
      const messageData = JSON.parse(event.data);
      const { event: eventType, data } = messageData;

      if (eventType === "get-user-chats") {
        const sortedChats = data.data.sort((a: any, b: any) => {
          const dateA = new Date(a.latest_message?.updated_at || 0);
          const dateB = new Date(b.latest_message?.updated_at || 0);
          return dateB.getTime() - dateA.getTime();
        });

        setChats(sortedChats);
      }

      if (eventType === "new-chat-created") {
        const newChat = data.data;
        // console.log("newChat: ", newChat);

        setLatestChat(newChat);
        setChats((prevChats) => {
          // Combine the existing chats with the new chat
          const combinedChats = [newChat, ...prevChats];

          // Use a Set to filter out duplicates
          const uniqueChats = Array.from(
            new Map(combinedChats.map((chat) => [chat.id, chat])).values()
          );

          // Sort unique chats by latest_message?.updated_at property in descending order
          // const sortedChats = uniqueChats.sort((a, b) => {
          //   const dateA = new Date(a.latest_message?.updated_at || 0);
          //   const dateB = new Date(b.latest_message?.updated_at || 0);
          //   return dateB.getTime() - dateA.getTime();
          // });

          return uniqueChats;
        });
      }
      if (eventType === "updateMessages") {
        setMessages(data.data);
        console.log("websocket messages : ", data.data);
        onNewMessage();
        if (userId !== data.data.user.id) {
          setUnreadChats((prevUnread: IUnreadChat[]) => {
            const chatExists = prevUnread.some(
              (chat) => chat.chatId === data.data.chat_id
            );
            if (chatExists) {
              // If it exists, map through the array and update the count
              return prevUnread.map(
                (chat) =>
                  chat.chatId === data.data.chat_id
                    ? { ...chat, count: chat.count + 1 } // Update count if chatId matches
                    : chat // Keep the other chats unchanged
              );
            } else {
              // If it doesn't exist, add a new chat with count 1
              return [
                ...prevUnread,
                { chatId: data.data.chat_id, isRead: false, count: 1 },
              ];
            }
          });
        }
      }
      if (eventType === "chat-onChangeReceive") {
        // console.log("User is typing:", data);
        if (data.userId === userId) {
          setChatProgress({ ...data, onchange: false });
        } else {
          setChatProgress(data);
        }
      }

      if (eventType === "updated-chat-status") {
        console.log("Chat status changed:", data?.data);
        const approvedChat = data?.data.chat;
        setChats((prevChats) => {
          const updatedChat = prevChats.map((chat) => {
            if (chat.id === approvedChat.id) {
              const { status, updated_at, ...otherData } = chat;
              return {
                ...otherData,
                status: approvedChat.status,
                updated_at: updated_at,
              };
            }
            return chat;
          });
          // Use a Set to filter out duplicates
          const uniqueChats = Array.from(
            new Map(updatedChat.map((chat) => [chat.id, chat])).values()
          );

          // Sort unique chats by latest_message?.updated_at property in descending order
          // const sortedChats = uniqueChats.sort((a, b) => {
          //   const dateA = new Date(a.latest_message?.updated_at || 0);
          //   const dateB = new Date(b.latest_message?.updated_at || 0);
          //   return dateB.getTime() - dateA.getTime();
          // });

          return uniqueChats;
        });
      }
    };

    socket.onclose = () => {
      console.log("Disconnected from WebSocket");
    };

    return () => {
      socket.close();
    };
  }, [userId, token]);

  const createNewChat = (receiverId: number, chatName: string) => {
    // console.log(receiverId, chatName);
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          event: "new-chat",
          data: { receiverId, chatName, userId, token },
        })
      );
    }
  };

  const sendMessage = (chatId: number, receiverId: number, message: string) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          event: "send-message",
          data: { chatId, message, receiverId, userId, token },
        })
      );

      notifyTyping(chatId, receiverId, false);
    }
  };

  const notifyTyping = (chatId: number, receiverId: number, state: boolean) => {
    // console.log("User is typing:", userId, state, receiverId);
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          event: "chat-onChange",
          data: { chatId, onChange: state, userId, receiverId },
        })
      );
    }
  };
  const changeChatStatus = (
    chatId: number,
    receiverId: number,
    status: string
  ) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          event: "update-chat-status",
          data: { chatId, userId, receiverId, token, status: status },
        })
      );
    }
  };
  const handleLatestChat = (object: any) => {
    setLatestChat(object);
  };

  return {
    messages,
    chats,
    createNewChat,
    sendMessage,
    notifyTyping,
    changeChatStatus,
    unreadChats,
    setUnreadChats,
    showChatProgress,
    initialScroll,
    latestChat,
    handleLatestChat,
  };
};
