import React from "react";
import { Skeleton } from "antd";

function UserChatSkeleton({ msgCount = 12 }: any) {
  const messageElements = [];

  for (let i = 0; i < msgCount; i++) {
    messageElements.push(
      <div
        className={`animate-pulse flex items-center ${
          i % 2 == 0 ? `justify-end` : `justify-start`
        } w-full`}
        key={i}
      >
        <div
          className={`rounded-xl ${
            i % 2 == 0
              ? `bg-gray-700 rounded-tr-none`
              : `bg-gray-600 rounded-tl-none`
          } h-10 w-2/5`}
        ></div>
      </div>
    );
  }

  return <div className="h-screen">{messageElements}</div>;
}

export default UserChatSkeleton;
