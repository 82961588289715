import React, { ReactNode, useEffect } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  width: string;
  showCloseButton?: boolean;
}
const TermsPopUpModel: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  width,
  showCloseButton = true,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return isOpen ? (
    <div className="fixed inset-0 !z-100 flex items-center justify-center w-full login_modal">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="fixed inset-0 flex items-center justify-center backdrop-blur">
        <div
          className={`relative w-[${width}] px-6 py-3 bg-black rounded-lg shadow-xl`}
        >
          <div className="mt-4">{children}</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default TermsPopUpModel;
