import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import MovieCarouselCard from "./MovieCarouselCard";
import { useParams } from "react-router-dom";

function SuggestedSection({ video }: any) {
  return (
    <>
      {video?.length > 0 && video ? (
        <>
          <div>
            <div className="">
              <Swiper
                slidesPerView={7}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                  },
                  380: {
                    slidesPerView: 2,
                  },
                  700: {
                    slidesPerView: 3,
                  },
                  1000: {
                    slidesPerView: 4,
                  },
                  1200: {
                    slidesPerView: 5,
                  },
                  1400: {
                    slidesPerView: 6,
                  },
                  1600: {
                    slidesPerView: 7,
                  },
                }}
                modules={[Pagination, Navigation]}
                className="mt-4 mb-5 mySwiper"
                navigation={true}
              >
                {video &&
                  video?.map((card: any, i: number) => (
                    <SwiperSlide key={i}>
                      <div className="">
                        <MovieCarouselCard
                          movie={card}
                          id={card.id}
                          time={card.runtime}
                          title={card.title}
                          info={card.title}
                          year={card.release}
                          type={card.genre}
                          img={card.thumbnail}
                          slug={card.slug}
                          seasons={card.no_of_seasons}
                          // user={user}
                          //   isLoading={isLoading}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </>
      ) : (
        <>
          {video?.length > 0 && video ? (
            <>
              <div>
                <div className="">
                  <Swiper
                    slidesPerView={7}
                    breakpoints={{
                      0: {
                        slidesPerView: 2,
                      },
                      380: {
                        slidesPerView: 2,
                      },
                      700: {
                        slidesPerView: 3,
                      },
                      1000: {
                        slidesPerView: 4,
                      },
                      1200: {
                        slidesPerView: 5,
                      },
                      1400: {
                        slidesPerView: 6,
                      },
                      1600: {
                        slidesPerView: 7,
                      },
                    }}
                    modules={[Pagination, Navigation]}
                    className="mt-4 mb-5 mySwiper"
                    navigation={true}
                  >
                    {video &&
                      video?.map((card: any, i: number) => (
                        <SwiperSlide key={i}>
                          <div className="">
                            <MovieCarouselCard
                              id={card?.id}
                              // time={card.runtime}
                              title={card?.title}
                              // info={card.description}
                              // year={card.release}
                              // type={card.video_type}
                              img={card?.thumbnail}
                              seasons={card?.no_of_seasons}
                              slug={card?.slug}
                              tv={true}
                              //  favoriteTvSeries={favorite}
                              tvSeries={card}
                              first_episode={card?.first_episode}
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default SuggestedSection;
