import { Drawer } from "antd";
import "styles/antDDrawer/antDdrawer.css";

function AntDDrawer({ onClose, open, title = "", children, bgColor }: any) {
  return (
    <>
      <Drawer
        title={title}
        placement={"right"}
        width={500}
        onClose={onClose}
        open={open}
        style={{
          backgroundColor: `${bgColor ? bgColor : "var(--Color1E1E1E)"}`,
        }}
        // extra={
        //   <Space>
        //     <Button onClick={onClose}>Cancel</Button>
        //     <Button type="primary" onClick={onClose}>
        //       OK
        //     </Button>
        //   </Space>
        // }
      >
        <div className="">{children}</div>
      </Drawer>
    </>
  );
}

export default AntDDrawer;
