import MovieAndSeriesCardSkeleton from "./MovieAndSeriesCardSkeleton";

const MusicSeeAllSkeleton = () => {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className="flex flex-col">
      <div className="w-[50px] h-[50px] rounded-full bg-[#1a1919] animate-pulse"></div>
      <div className="w-[300px] h-[40px] bg-[#1a1919] animate-pulse mt-5"></div>
      <div className="grid mt-10 lg:grid-cols-5 max-md:grid-cols-3 max-sm:grid-cols-2">
        {arr.map((item: any) => (
          <div key={item} className="mb-5">
             <div className="lg:w-[210px] h-[230px] w-[130px] bg-[#1a1919] animate-pulse mt-2 rounded-lg"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MusicSeeAllSkeleton;
