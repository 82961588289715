import SubText from "components/shared/SubText";
import CategoryTrack from "./category/CategoryTrack";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useState } from "react";
import CategoryAlbumSection from "./category/CategoryAlbumSection";
import CategoryArtistsSection from "./category/CategoryArtistsSection";
import GenreService from "services/GenreService";
import MusicService from "services/music/MusicService";
import AlbumService from "services/music/AlbumService";
import ArtistService from "services/music/ArtistService";
import { useSelector } from "react-redux";
import PlaylistService from "services/music/PlaylistService";
import MusicCategorySkeleton from "components/skeleton/MusicCategorySkeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NoSearch from "pages/searchPage/shared/NoSearch";
import { useNavigate } from "react-router-dom";
import { HiArrowLeft } from "react-icons/hi";

function MusicCategoryPage() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [seeAllArtist, setSeeAllArtist] = useState<boolean>(false);
  const [seeAllAlbum, setSeeAllAlbum] = useState<boolean>(false);
  const [seeAllTrack, setSeeAllTrack] = useState<boolean>(false);
  const [categoryName, setCategoryName] = useState<any>("");
  const [totalResults, setTotalResults] = useState<number>(0);

  // tracks
  const [tracks, setTracks] = useState<Array<any>>([]);
  const [albums, setAlbums] = useState([]);
  const [artists, setArtists] = useState([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  // useEffect(() => {
  //   console.log(tracks);
  // }, [tracks]);

  // console.log(slug);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    MusicService.getSongsByGenre(slug).then((res) => {
      setIsLoading(false);
      setTracks(res);
      // console.log(res)
    });

    AlbumService.getAlbumsByGenre(slug).then((res) => {
      setIsLoading(false);
      setAlbums(res);
    });

    // ArtistService.getArtistsByGenre(slug).then((res) => {
    //   setIsLoading(false);
    //   setArtists(res);
    // });
  }, [slug]);

  useEffect(() => {
    if (tracks && albums) {
      setTotalResults(tracks.length + albums.length);
    }
  }, [tracks, albums]);

  // get category name
  useEffect(() => {
    GenreService.getAllGenres()
      .then((res) => {
        const filteredData = res.data.filter(
          (item: any) => item.alt_name === slug
        );
        setCategoryName(filteredData[0].name);
      })
      .catch((e) => {
        // console.log(e);
      });
  }, [slug]);

  // playlist
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [userPlaylists, setUserPlaylists] = useState([]);

  useEffect(() => {
    if (loggedInUser) {
      PlaylistService.getUserPlaylists().then((res) => {
        setUserPlaylists(res.data.data);
      });
    }
  }, [loggedInUser]);

  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  return (
    <>
      <Helmet>
        <title>
          {categoryName ? `${categoryName} - AYOZAT Music` : "AYOZAT Music"}
        </title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <link
          rel="canonical"
          href={`${FE_URL}/music/category/${categoryName}`}
        />
        <meta
          property="og:title"
          content={
            categoryName ? `${categoryName} - AYOZAT Music` : "AYOZAT Music"
          }
        />
        <meta
          property="og:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta
          property="og:image:secure_url"
          content="https://ayozat.co.uk/logo192.png"
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta
          property="og:url"
          content={`${FE_URL}/music/category/${categoryName}`}
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={
            categoryName ? `${categoryName} - AYOZAT Music` : "AYOZAT Music"
          }
        />
        <meta
          name="twitter:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT" />
        {/* <meta property="twitter:url" content={`${FE_URL}/music`}/> */}
      </Helmet>

      <div
        className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer max-sm:ml-[7px] mb-3`}
        onClick={() => {
          navigate(-1);
        }}
      >
        <HiArrowLeft className="text-black" />
      </div>
      <h1 className="hidden">AYOZAT Music Categories</h1>
      <div className="flex items-center mb-1 text-white ">
        <SubText text="Showing result for" className="font-light !text-2xl" />
        <p className="mx-3">:</p>
        <SubText
          text={categoryName}
          className="font-bold  !text-red-600 !text-2xl "
        />
      </div>
      <p className="mt-1 text-xs text-white max-sm:ml-2">
        {totalResults === 0
          ? `No results`
          : totalResults > 1
          ? `${totalResults} results`
          : `${totalResults} result`}
      </p>

      {/* filter section */}

      {/* <MusicFilter /> */}
      {/* flex section  */}
      {!isLoading && (
        <div className="flex justify-between gap-3 mt-10 max-sm:block max-md:block">
          {/* artist and album section */}
          <div className="w-6/12 mr-4 max-sm:w-full max-md:w-full max-sm:block max-md:block max-lg:block">
            {/* <div className="flex items-center justify-between w-11/12 max-sm:w-full">
              <SubText text="Artist" className="my-4 font-bold" />
              {artists && artists.length > 8 && (
                <div
                  onClick={() => {
                    setSeeAllArtist(!seeAllArtist); //
                  }}
                >
                  <SubText
                    text={!seeAllArtist ? `See All` : `See less`}
                    className="my-4 font-bold !text-red-600 !text-sm cursor-pointer"
                  />
                </div>
              )}
            </div> */}
            {/* {artists && artists.length > 0 ? (
              <CategoryArtistsSection
                artists={artists && artists}
                seeAll={seeAllArtist}
              />
            ) : (
              <NoSearch text="Artists" />
            )} */}

            <div className="flex items-center justify-between w-full max-sm:w-full max-md:w-full">
              <SubText text="Albums" className="my-6 font-bold" />
              {albums && albums.length > 3 && (
                <div
                  onClick={() => {
                    setSeeAllAlbum(!seeAllAlbum);
                  }}
                >
                  <SubText
                    text={!seeAllAlbum ? `See All` : `See less`}
                    className="my-4 font-bold !text-red-600 !text-sm cursor-pointer"
                  />
                </div>
              )}
            </div>
            {albums && albums.length > 0 ? (
              <CategoryAlbumSection
                albums={albums && albums}
                seeAll={seeAllAlbum}
              />
            ) : (
              <NoSearch text="Album" />
            )}
          </div>
          {/* Trsacks section */}
          <div className="w-6/12 max-sm:w-full max-md:w-full max-sm:block max-md:block max-lg:block">
            <div className="flex items-center justify-between w-[95%] max-sm:w-full max-md:w-full">
              <SubText text="Tracks" className="my-4 font-bold" />
              {tracks && tracks.length > 9 && (
                <div
                  onClick={() => {
                    setSeeAllTrack(!seeAllTrack);
                  }}
                >
                  <SubText
                    text={!seeAllTrack ? `See All` : `See less`}
                    className="my-4 font-bold !text-red-600 !text-sm cursor-pointer"
                  />
                </div>
              )}
            </div>

            {tracks && tracks.length > 0 ? (
              <CategoryTrack
                tracks={tracks}
                seeAll={seeAllTrack}
                userPlaylists={userPlaylists}
                setUserPlaylists={setUserPlaylists}
              />
            ) : (
              <NoSearch text={slug} />
            )}
          </div>
        </div>
      )}
      {isLoading && <MusicCategorySkeleton />}
    </>
  );
}

export default MusicCategoryPage;
