import BlogPageDetailHeader from "./shared/BlogPageDetailHeader";
import BlogBody from "./shared/BlogBody";
import { useEffect, useState } from "react";
import BlogService from "services/blogs/BlogService";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const BlogsDetails = () => {
  const [blog, setBlog] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false); // Remove <any> type here
  const param = useParams();
  const slug = param.slug;

  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await BlogService.getBlogBySlug(slug);
      setBlog(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      getData();
    }
  }, [slug]);

  function removeHTMLTags(str: any) {
    if (!str) return "";
    return str.replace(/<[^>]*>/g, "");
  }

  const shortContent = removeHTMLTags(blog?.short_content);
  // console.log(blog);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {blog &&
            (blog?.meta_title
              ? blog?.meta_title
              : blog?.title
              ? `${blog?.title} - AYOZAT`
              : "AYOZAT Blogs")}
        </title>
        <meta name="title" content={blog && blog?.title} />
        <meta name="description" content={shortContent} />
        <meta property="og:image" content={blog && blog?.thumbnail_url} />

        <link rel="canonical" href={`${FE_URL}/blogs/${blog && blog?.slug}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog && blog?.title} />
        <meta name="twitter:description" content={shortContent} />
        <meta name="twitter:image" content={blog && blog?.thumbnail_url} />
        <meta
          name="twitter:url"
          content={`${FE_URL}/music/blog/${blog && blog?.slug}`}
        />

        <meta property="og:title" content={blog && blog?.title} />
        <meta property="og:description" content={shortContent} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${FE_URL}/blogs/${blog && blog?.slug}`}
        />
      </Helmet>

      <div>
        {isLoading ? (
          <div></div>
        ) : (
          blog && (
            <>
              <div className="border-gray-700 rounded-xl mx-auto border-[1px] p-5 max-sm:p-0 max-md:p-0 w-[95%]">
                <BlogPageDetailHeader blogsDetails={blog} />
                <BlogBody blogsDetails={blog} />
              </div>
            </>
          )
        )}
      </div>
    </>
  );
};

export default BlogsDetails;
