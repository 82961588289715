import FeaturePages from "layout/FeaturePages";
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import PPVTransection from "./PayPerView/PPVTransection";
import { RouteName } from "constant/routeNames";
import Footer from "components/Footer";
import PPVTranscetionTable from "./PayPerView/shared/PPVTranscetionTable";
import TransectionSkeleton from "components/skeleton/TransectionSkeleton";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ContentNavbar from "components/ContentNavbar";
import WelcomePageSidebar from "./welcome/WelcomePageSidebar";
import PayperviewService from "services/payperview/PayperviewServie";
import { Helmet } from "react-helmet-async";
import { transection } from "constant/Transection";
import NoTransaction from "./PayPerView/shared/NoTransaction";
import { contentNavData } from "constant/ContentNavBarData";

const TransectionPage = ({
  handleClick,
  isActive,
  setIsActive,
  anyPlayerActive,
}: any) => {
  const [transactions, setTransactions] = useState<any>([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    setIsLoading(true);
    PayperviewService.paymentTransection()
      .then((res) => {
        console.log("transections", res?.transactions?.data);
        setIsLoading(false);
        setTransactions(res?.transactions?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <ContentNavbar handleClick={handleClick} />
      <WelcomePageSidebar isActive={isActive} navData={contentNavData} />
      <div
        className={
          isActive
            ? "md:pl-[100px] pl-[80px] duration-500 p-2 mt-2 ml-3 max-sm:pl-0 max-sm:ml-2 "
            : "pl-0 duration-500 p-2 mt-2 ml-8 max-sm:pl-0 max-sm:ml-2 "
        }
      >
        <Helmet>
          <title>AYOZAT Transactions</title>
          <meta
            name="description"
            content="Experience exclusive content and events on demand with our Pay-Per-View service."
          />
          <link rel="canonical" href={`${FE_URL}/transactions`} />
          <meta property="og:title" content="AYOZAT Transactions" />
          <meta
            property="og:description"
            content="Experience exclusive content and events on demand with our Pay-Per-View service."
          />
          {/* <meta property="og:image:secure_url" content="https://ayozat.co.uk/logo192.png" /> */}
          <meta
            property="og:image"
            content="https://ayozat.co.uk/logo192.png"
          />
          <meta property="og:url" content={`${FE_URL}/transactions`} />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="AYOZAT Transactions" />
          <meta
            name="twitter:description"
            content="Experience exclusive content and events on demand with our Pay-Per-View service."
          />
          <meta
            name="twitter:image"
            content="https://ayozat.co.uk/logo192.png"
          />
          <meta name="twitter:site" content="AYOZAT Transactions" />
          <meta name="twitter:url" content={`${FE_URL}/transactions`} />
        </Helmet>
        <div className="mx-auto mt-5 max-sm:ml-5">
          <div>
            <div>
              <button
                className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <AiOutlineArrowLeft className="ml-3" />
              </button>
            </div>
            <div className="mb-8">
              <h1 className="text-xl font-medium text-white lg:text-2xl md:text-3xl max-sm:w-[60%] max-sm:ml-2">
                Transactions
              </h1>
            </div>
            <div className="mb-36">
              {!isLoading && transactions?.length === 0 ? (
                <>
                  <NoTransaction />
                </>
              ) : (
                <>
                  <PPVTranscetionTable transection={transactions} />
                </>
              )}
              {isLoading && <TransectionSkeleton />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TransectionPage;
