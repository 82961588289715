import React, { useState } from "react";
import { useSelector } from "react-redux";
import SocialMediaBtn from "./SocialMediaBtn";
import FollowFollowingSection from "./FollowFollowingSection";
import FollowButton from "pages/userProfile/shared/FollowButton";
import UserMessageBtn from "./UserMessageBtn";
import UserEditProfileButton from "pages/userProfile/shared/UserEditProfileButton";
import NewChatButton from "pages/UserChat/shared/NewChatButton";
import LoginModel from "components/LoginModal";
import { useParams } from "react-router-dom";

function ProfileDetails({
  user,
  updatedUser,
  setUpdatedUser,
  follow,
  setFollow,
}: any) {
  const currentUrl = window.location.pathname;
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [showLoginModal, setShowLoginModal] = useState(false);
  
  const closeLoginModal = () => {
    setShowLoginModal(false);
  };
  return (
    <>
      <div className="flex justify-between items-start relative max-sm:block px-5 max-sm:px-5 max-sm:-mt-[10vh]">
        {/* image */}
        <div className=" w-[15%] max-lg:w-[20%] max-sm:w-full text-center">
          <div className="max-w-[95%] -translate-y-1/4 max-sm:translate-y-0">
            <div>
              <div className="flex items-center justify-center max-sm:justify-start">
                <img
                  src={user?.artwork_url}
                  alt="booster"
                  className="object-cover w-[95%] h-[95%] max-sm:w-[50%] max-sm:h-[50%] rounded-full"
                />
              </div>
              <div className="flex items-center justify-center w-full max-sm:w-1/2">
                <SocialMediaBtn />
              </div>
            </div>
          </div>

          <FollowFollowingSection
            user={user}
            className="justify-start sm:hidden"
          />
        </div>
        {/* name and bio */}
        <div className="w-[55%] max-sm:w-full h-full">
          <h1 className="text-[50px] font-rubik font-bold text-white max-sm:text-[28px] max-md:text-[30px] max-xl:text-[30px] max-sm:mt-5">
            {user?.name}
          </h1>
          <p className="text-sm font-medium w-[100%] text-white max-sm:text-xs max-sm:w-full">
            {user?.bio}
          </p>
        </div>
        {/* msg and setting button */}
        <div className="w-[25%] max-lg:w-[15%] max-sm:w-full h-full flex justify-end max-sm:justify-start items-center py-3 gap-3">
          {/* {loggedInUser && ( */}
          <>
            <div className="flex items-center gap-3 mt-2 ml-4 max-lg:flex-col max-sm:flex max-sm:ml-0">
              {loggedInUser?.id !== user?.id ? (
                <>
                  <FollowButton
                    user={updatedUser}
                    setUpdatedUser={setUpdatedUser}
                    follow={follow}
                    setFollow={setFollow}
                    setShowLoginModal={setShowLoginModal}
                  />

                  {loggedInUser && <NewChatButton user={user} />}
                </>
              ) : (
                <>
                  <UserEditProfileButton />
                </>
              )}
            </div>
          </>
          {showLoginModal ? (
        <LoginModel
          clickLogin={closeLoginModal}
          setShowLoginModal={setShowLoginModal}
          navigateUrl={currentUrl}
        />
      ) : (
        <></>
      )}
          {/* )} */}
        </div>
      </div>
    </>
  );
}

export default ProfileDetails;
