import Picker, { Theme } from "emoji-picker-react";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { FaRegSmile } from "react-icons/fa";
import { IoSendSharp } from "react-icons/io5";
import { MdOutlineGif } from "react-icons/md";
import MediaWindow from "./MediaWindow";

const ChatInput = ({
  inputValue,
  setInputValue,
  sendMessage,
  notifyTyping,
}: any) => {
  const [showPicker, setShowPicker] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const pickerRef = useRef<HTMLDivElement>(null);
  const mediaRef = useRef<HTMLDivElement>(null);
  const [textareaHeight, setTextareaHeight] = useState("40px");
  //Click outside for emoji picker
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        mediaRef.current &&
        !mediaRef.current.contains(event.target as Node)
      ) {
        setShowMedia(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onEmojiClick = (event: any) => {
    setInputValue((prevInput: any) => prevInput + event.emoji);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setInputValue((prevMessage: string) => prevMessage + "\n");
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (inputValue.trim() !== "") {
        handleSubmit(e);
        setInputValue("");
      }
    }
  };

  useEffect(() => {
    const lineHeight = 30;
    const maxRows = 4;

    if (inputValue !== " ") {
      setTextareaHeight("40px");
      const rows = Math.ceil(inputValue.split("\n").length);
      if (rows > 1) {
        const clampedRows = Math.min(rows, maxRows);
        const height = `${lineHeight * clampedRows}px`;
        setTextareaHeight(height);
      }
    }
  }, [inputValue]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    sendMessage(inputValue);
    triggerChatOnChange(false);
    setTextareaHeight("40px");
    setInputValue("");
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setInputValue(e.target.value);
    if (e.target.value !== "") {
      triggerChatOnChange(true);
    } else {
      triggerChatOnChange(false);
    }
  };
  const triggerChatOnChange = (onChnage: boolean) => {
    notifyTyping(onChnage);
  };

  const handleClear = () => {
    setInputValue("");
  };

  const isGifUrl = (url: string) => {
    const gifUrlPattern = /^https:\/\/giphy\.com\/embed\/[a-zA-Z0-9]+$/;
    return gifUrlPattern.test(url);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex items-end justify-between w-full px-3 py-2 border border-gray-400 rounded-xl"
      >
        {isGifUrl(inputValue) ? (
          <div className="relative">
            <iframe
              src={inputValue}
              width="100%"
              height="200"
              frameBorder="0"
              className="rounded-lg"
              allowFullScreen
              title="GIF"
            ></iframe>
            <button
              onClick={handleClear}
              className="absolute flex items-center justify-center top-0 right-0 px-1 w-[20px] h-[20px] text-white bg-red-500 rounded-full text-[12px]"
            >
              X
            </button>
          </div>
        ) : (
          <textarea
            placeholder="Type your message..."
            value={inputValue}
            onKeyDown={handleKeyPress}
            onChange={(e) => onChange(e)}
            className="w-full p-2 pl-5 text-white bg-[#131313] max-sm:text-[13px] max-sm:pl-3 
      "
            style={{ height: textareaHeight, resize: "none" }}
          />
        )}

        <div className="flex items-center justify-center h-[35px]">
          <FaRegSmile
            className="mx-2 text-white cursor-pointer"
            onClick={() => setShowPicker((val) => !val)}
            size={20}
          />

          <MdOutlineGif
            className="mx-2 text-white cursor-pointer"
            onClick={() => setShowMedia((val) => !val)}
            size={25}
          />
        </div>
        {showMedia && (
          <div
            className="absolute w-[70%]  bottom-[36%] right-[20%] overflow-visible max-sm:right-[10%] max-sm:bottom-[20%]"
            ref={mediaRef}
          >
            <MediaWindow
              setInputValue={setInputValue}
              setShowMedia={setShowMedia}
            />
          </div>
        )}
        {showPicker && (
          <div
            className="absolute bottom-[36%] right-[20%] overflow-visible max-sm:right-[10%] max-sm:bottom-[20%]"
            ref={pickerRef}
          >
            <Picker onEmojiClick={onEmojiClick} theme={Theme.DARK} />
          </div>
        )}
        <button
          type="submit"
          className="px-3 py-2 ml-2 text-white bg-[#f30543] rounded-xl"
        >
          <IoSendSharp className="text-white" size={20} />
        </button>
      </form>
    </>
  );
};

export default ChatInput;
