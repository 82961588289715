import SubText from "components/shared/SubText";
import SongList from "pages/musicPage/shared/SongList";
import React, { useState, useEffect } from "react";
import SongListHome from "./SongListHome";
import AlbumService from "services/music/AlbumService";
import AlbumCard from "pages/musicPage/shared/AlbumCard";

function CreatorHomeAlbumsGrid() {
  const [albums, setAlbums] = useState<any>([]);
  const [isLoading, setIsLoding] = useState<any>(false);

  useEffect(() => {
    setIsLoding(true);
    AlbumService.getRandomAlbums().then((res) => {
      // console.log(res.randomAlbums);
      setIsLoding(false);
      setAlbums(res.randomAlbums);
    });
  }, []);
  return (
    <>
      <div className="flex justify-between mt-10 ml-6">
        <SubText text="Albums" className="font-bold" />
      </div>
      <div className="w-[90%] ml-5 text-white max-sm:overflow-x-hidden">
        {albums.slice(0, 4).map((album: any, index: number) => (
          <AlbumCard {...album} />
        ))}
      </div>
    </>
  );
}

export default CreatorHomeAlbumsGrid;
