import { ChatContext } from "context/ChatContext";
import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import UserChatService from "services/chatService/UserChatService";
import ChatHeaderOpenChat from "./ChatHeaderOpenChat";
import UserChatBody from "./UserChatBody";
import UserChatInput from "./UserChatInput";

function OpenChat({ sidePanel = false }: any) {
  const {
    messages,
    createNewChat,
    sendMessage,
    notifyTyping,
    showChatProgress,
    latestChat,
    selectedChat,
    setSelectedChat,
    allChats,
    setAllChats,
    changeChatStatus,
  } = useContext(ChatContext);

  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [chatMessages, setChatMessages] = useState<any>();
  const [inputValue, setInputValue] = useState("");
  const [firstMessage, setFirstMessage] = useState("");
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showTyping, setShowTyping] = useState(false);
  useEffect(() => {
    if (messages?.chat_id == selectedChat?.id) {
      setChatMessages((prev: any) =>
        Array.isArray(prev) ? [...prev, messages] : [messages]
      );
    }
  }, [messages]);

  useEffect(() => {
    if (
      chatMessages &&
      chatMessages.length > 0 &&
      chatMessages[chatMessages.length - 1]?.user_id == loggedInUser?.id
    ) {
      scrollToBottom();
    } else {
      if (!sidePanel) {
        scrollToTop();
      }
      // nearScrollToBottom(500);
    }
  }, [chatMessages]);

  useEffect(() => {
    const chatId = selectedChat?.id;
    if (
      showChatProgress?.chatId == chatId &&
      showChatProgress?.onChange &&
      showChatProgress?.userId != loggedInUser?.id
    ) {
      setShowTyping(true);
      nearScrollToBottom(200);
    } else {
      setShowTyping(false);
    }
  }, [showChatProgress]);

  const getChatMessages = async () => {
    setChatMessages(null);
    UserChatService.getMessages(selectedChat?.id).then((res: any) => {
      setChatMessages(res);
      // console.log(res);
    });
  };

  // fetch messages
  useEffect(() => {
    getChatMessages();
  }, []);

  useEffect(() => {
    getChatMessages();
  }, [selectedChat?.id]);

  const sendMessageData = (message: string) => {
    // console.log("selectedChat : ", selectedChat);
    if (selectedChat && selectedChat?.id !== 0) {
      const chatId = selectedChat?.id;
      const receiverId = selectedChat?.receiver?.id;

      sendMessage(chatId, receiverId, message);
      setFirstMessage(message);
    } else {
      setFirstMessage(message);
      createNewChat(selectedChat?.receiver?.id, selectedChat?.chat_name);
    }
    setInputValue("");
  };

  useEffect(() => {
    if (latestChat && selectedChat?.id === 0) {
      const a = selectedChat;
      const updatedChat = {
        ...a,
        id: latestChat.id,
        pivot: {
          user_id: loggedInUser?.id,
          chat_id: latestChat.id,
        },
        latest_message: { ...latestChat, content: firstMessage },
        receiver: a?.receiver,
      };

      setSelectedChat(updatedChat);

      const updatedChats = [updatedChat, ...allChats];
      console.log("updatedChats", updatedChats);
      setAllChats(updatedChats);

      sendMessage(latestChat?.id, selectedChat?.receiver?.id, firstMessage);
    }
  }, [latestChat]);

  const scrollToBottom = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: 0, // Scroll to the top
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const nearScrollToBottom = (threshold: number) => {
    const container = containerRef.current;
    if (container) {
      // const threshold = 500; // pixels from the bottom
      const currentScrollPosition =
        container.scrollTop + container.clientHeight;
      const maxScrollPosition = container.scrollHeight;

      if (maxScrollPosition - currentScrollPosition <= threshold) {
        container.scrollTo({
          top: maxScrollPosition,
          behavior: "smooth",
        });
      }
    }
  };
  const handleApproveChat = (status: string) => {
    changeChatStatus(selectedChat.id, selectedChat.receiver.id, status);
    setSelectedChat({
      ...selectedChat,
      status: status,
    });
  };

  // console.log("selecrtedChat : ", selectedChat);
  return (
    <div
      className={`w-full text-white ${
        sidePanel ? `h-[100vh]` : `h-[85vh] sm:rounded-md`
      } overflow-y-scroll scrollbar-hide flex flex-col `}
    >
      <ChatHeaderOpenChat
        sidePanel={sidePanel}
        receiver={selectedChat?.receiver}
        setSelectedChat={setSelectedChat}
      />

      {/* display messages */}
      <div
        ref={containerRef}
        className="flex-1 overflow-y-auto bg-[var(--Color1E1E1E)] scrollbar-hide"
        style={{ maxHeight: "calc(100% - 40px)" }}
      >
        <UserChatBody
          sidePanel={sidePanel}
          chatMessages={chatMessages}
          showChatProgress={showTyping}
        />
      </div>

      {/* user input */}
      {selectedChat?.status === "approved" ||
      selectedChat?.chat_name.split("-")[0] == loggedInUser?.id ? (
        <div className="bottom-1 w-full bg-[var(--Color1E1E1E)]">
          <UserChatInput
            inputValue={inputValue}
            setInputValue={setInputValue}
            sendMessage={sendMessageData}
            userIndividualChat={true}
            notifyTyping={notifyTyping}
            selectedChat={selectedChat}
          />
        </div>
      ) : (
        <>
          <div className="flex items-center w-full gap-3 p-5 text-base bg-gray-600">
            <p>
              To proceed, please choose to either accept or reject the chat.
            </p>
            <p
              className="text-green-600 cursor-pointer"
              onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                e.stopPropagation();
                handleApproveChat("approved");
              }}
            >
              Approve
            </p>
            <p
              className="text-red-600 cursor-pointer"
              onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                e.stopPropagation();
                handleApproveChat("reject");
              }}
            >
              Reject
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default OpenChat;
