import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import 'styles/card.css';
import 'swiper/css/navigation';
import MovieCarouselCard from 'pages/videoPage/shared/MovieCarouselCard';
import SubText from 'components/shared/SubText';
import { Link } from 'react-router-dom';
import { RouteName } from 'constant/routeNames';
import MovieCardSkeleton from 'components/skeleton/MovieCardSkeleton';

function VideoPageMovieCoursel({
  title,
  movies,
  user,
  favorite,
  isLoading,
  dontShowSeeAll,
}: any) {
  // console.log(movies);

  // const [cardDetails, setCardDetails] = useState(cards);
  return (
    <div>
      {!isLoading && (
        <>
          <div className="flex mt-10 justify-between w-[95%] max-sm:w-full ">
            {movies && movies?.length > 0 && (
              <SubText
                text={title ? title : "Movies picked for you"}
                className="font-bold max-sm:ml-4"
              />
            )}
            {!dontShowSeeAll ? (
              <Link to={RouteName.SeeAllMoviesRoute + "?page=1"}>
                <p className="text-sm font-bold cursor-pointer red-text place-self-end">
                  {""} See all
                </p>
              </Link>
            ) : (
              <></>
            )}
          </div>
          <div className="">
            <Swiper
              slidesPerView={7}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                380: {
                  slidesPerView: 2,
                },
                700: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                },
                1400: {
                  slidesPerView: 6,
                },
                1600: {
                  slidesPerView: 7,
                },
              }}
              modules={[Pagination, Navigation]}
              className="mt-8 mb-10 mySwiper "
              navigation={true}
            >
              {movies.map((card: any, i: number) => (
                <SwiperSlide key={i}>
                  <div className="mx-3 max-sm:mx-1">
                    <MovieCarouselCard
                      movie={card}
                      id={card.id}
                      time={card.runtime}
                      title={card.title}
                      info={card.title}
                      year={card.release}
                      type={card.genre}
                      img={card.thumbnail}
                      slug={card.slug}
                      // user={user}
                      isLoading={isLoading}
                    />
                  </div>
                </SwiperSlide>
              ))}

              {/* {movies.map((m: any, j: number) => console.log(m.title))} */}
            </Swiper>
          </div>{" "}
        </>
      )}
      {isLoading && <MovieCardSkeleton />}
    </div>
  );
}

export default VideoPageMovieCoursel;
