import React from "react";
import { FiShare2 } from "react-icons/fi";

function UserProfileShareBtn({ openModal }: any) {
  return (
    <>
      <button
        className={`flex items-center min-w-[128px] h-[40px] p-4 text-center text-white max-sm:p-2 `}
        onClick={openModal}
      >
        <div className="flex items-center justify-end w-full gap-5 mx-auto">
          <FiShare2 className="text-3xl " />
        </div>
      </button>
      ;
    </>
  );
}

export default UserProfileShareBtn;
