import axios from "axios";
import authHeader from "utils/auth-header";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;

const BASE_URL = process.env.REACT_APP_BACKEND_SERVER;
const CHAT_SOCKET_URL = process.env.REACT_APP_CHAT_SERVER;

const approveChat = async (chatId: number) => {
  const response = await axios({
    method: "post",
    url: `${BASE_URL}/chats/${chatId}/status`,
    data: {
      status: "approved",
    },
    headers: authHeader(),
  });
  return response.data;
};

const getMessages = async (chatId: number) => {
  const response = await axios({
    method: "get",
    url: `${BASE_URL}/chats/${chatId}/messages`,
    data: {
      status: "approved",
    },
    headers: authHeader(),
  });
  return response.data;
};

const UserChatService = {
  approveChat,
  getMessages,
};

export default UserChatService;
