import React from "react";
import { GiReceiveMoney } from "react-icons/gi";
import { MdOutlineAutoGraph } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdAppShortcut } from "react-icons/md";

const ServiceCardsSection = () => {
  const cards = [
    {
      header: "Maximize Revenue",
      text: "Guaranteed income from your live event with an unlimited audience.",
      icon: <GiReceiveMoney size={30} className="mx-auto text-white" />,
    },
    {
      header: "Control & Flexibility",
      text: "Set your own prices and release dates, ensuring full control over your content.",
      icon: <MdAppShortcut size={30} className="mx-auto text-white" />,
    },
    {
      header: "Audience Engagement",
      text: "Create a sense of exclusivity and urgency, driving higher viewer engagement.",
      icon: <AiOutlineUsergroupAdd size={30} className="mx-auto text-white" />,
    },
    {
      header: "Audience-Driven Ticket Sales",
      text: "Gain new fans and audiences through AYOZAT and our partners' ecosystem.",
      icon: <MdOutlineAutoGraph size={30} className="mx-auto text-white" />,
    },
  ];
  return (
    <div className="w-[80%] mx-auto grid grid-cols-4 gap-10 my-10 max-sm:grid-cols-1 max-md:grid-cols-1 max-lg:grid-cols-2 cursor-default">
      {cards.map((card: any, index: any) => (
        <div className="w-full h-[330px] bg-[#0b0b0b] rounded-2xl text-white hover:border hover:border-red-800 hover:duration-500 text-center p-8 hover:bg-gradient-to-r hover:from-[#0b0b0b] hover:to-[#f305456b] overflow-hidden">
          <div className="w-full">
            <div className="mb-8 text-center w-[50px] h-[50px] flex items-center justify-center rounded-full bg-[#f30545] mx-auto">
              {card.icon}
            </div>
          </div>
          <h1 className="font-bold text-[22px] mb-5">{card.header}</h1>
          <p className="text-[15px] text-gray-300 font-light max-sm:text-[15px]">
            {card.text}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ServiceCardsSection;
