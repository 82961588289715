import React from "react";

const SectionTitle = ({ text }: any) => {
  return (
    <div className="text-[25px] font-semibold text-white w-full my-8 text-center max-sm:text-[20px] cursor-default">
      {text}
    </div>
  );
};

export default SectionTitle;
