import { FaFacebookF, FaTwitter, FaSoundcloud } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
import { useSelector } from "react-redux";

function SocialMediaBtn( user : any) {

  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  return (
    <div className="flex justify-between gap-4 max-sm:p-0 mt-7">
      {loggedInUser?.facebook_url && (
        <div className="">
        <a
          href={loggedInUser?.facebook_url}
          rel="nofollow"
          className="text-3xl text-[var(--red)]"
        >
          <FaFacebookF />
        </a>
      </div>
      )}
       {loggedInUser?.twitter_url && (
      <div className="">
        <a
          href={loggedInUser?.twitter_url}
          rel="nofollow"
          className="text-3xl text-[var(--red)]"
        >
          <FaTwitter />
        </a>
      </div>
      )}
       {loggedInUser?.instagram_url && (
      <div className="">
        <a
          href={loggedInUser?.instagram_url}
          rel="nofollow"
          className="text-3xl text-[var(--red)]"
        >
          <AiFillInstagram />
        </a>
      </div>
      )}
       {loggedInUser?.soundcloud_url && (
      <div className="">
        <a
          href={loggedInUser?.soundcloud_url}
          rel="nofollow"
          className="text-3xl text-[var(--red)]"
        >
          <FaSoundcloud  />
        </a>
      </div>
    )}
    </div>
  );
}

export default SocialMediaBtn;
