import MediaWindow from "components/real-chat/components/MediaWindow";
import Picker, { Theme } from "emoji-picker-react";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { FaRegSmile } from "react-icons/fa";
import { IoSendSharp } from "react-icons/io5";
import { MdOutlineGif } from "react-icons/md";
import { Input } from "antd";
import { BiLogoTelegram } from "react-icons/bi";

const { TextArea } = Input;

function UserChatInput({
  inputValue,
  setInputValue,
  selectedChat,
  sendMessage,
  notifyTyping,
  userIndividualChat = false,
}: any) {
  const [showPicker, setShowPicker] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const pickerRef = useRef<HTMLDivElement>(null);
  const mediaRef = useRef<HTMLDivElement>(null);
  const [textareaHeight, setTextareaHeight] = useState("40px");
  //Click outside for emoji picker
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        mediaRef.current &&
        !mediaRef.current.contains(event.target as Node)
      ) {
        setShowMedia(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onEmojiClick = (event: any) => {
    setInputValue((prevInput: any) => prevInput + event.emoji);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setInputValue((prevMessage: string) => prevMessage + "\n");
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (inputValue.trim() !== "") {
        handleSubmit(e);
        setInputValue("");
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (inputValue !== "") {
      sendMessage(inputValue);
      triggerChatOnChange(false);
      setTextareaHeight("40px");
      setInputValue("");
    }
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setInputValue(e.target.value);
    if (e.target.value !== "") {
      triggerChatOnChange(true);
    } else {
      triggerChatOnChange(false);
    }
  };
  const triggerChatOnChange = (onChnage: boolean) => {
    if (userIndividualChat) {
      notifyTyping(selectedChat.id, selectedChat?.receiver?.id, onChnage);
    } else {
      notifyTyping(onChnage);
    }
  };

  const handleClear = () => {
    setInputValue("");
  };

  const isGifUrl = (url: string) => {
    const gifUrlPattern = /^https:\/\/giphy\.com\/embed\/[a-zA-Z0-9]+$/;
    return gifUrlPattern.test(url);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-between w-full px-2 border bg-white border-gray-400 rounded-lg"
      >
        {isGifUrl(inputValue) ? (
          <div className="relative">
            <iframe
              src={inputValue}
              width="100%"
              height="200"
              frameBorder="0"
              className="rounded-lg"
              allowFullScreen
              title="GIF"
            ></iframe>
            <button
              onClick={handleClear}
              className="absolute flex items-center justify-center top-0 right-0 px-1 w-[20px] h-[20px] text-white bg-red-500 rounded-full text-[12px]"
            >
              X
            </button>
          </div>
        ) : (
          <>
            <textarea
              placeholder="Type your message..."
              value={inputValue}
              onKeyDown={handleKeyPress}
              onChange={(e) => onChange(e)}
              className="w-full p-2 pl-5 bg-white text-[#131313] max-sm:text-[13px] max-sm:pl-3
            "
              style={{ height: textareaHeight, resize: "none" }}
            />
          </>
        )}

        <div className="flex items-center justify-center h-[50px]">
          <FaRegSmile
            className="mx-2 text-black cursor-pointer"
            onClick={() => setShowPicker((val) => !val)}
            size={20}
          />

          <MdOutlineGif
            className="mx-2 text-black cursor-pointer"
            onClick={() => setShowMedia((val) => !val)}
            size={25}
          />
        </div>
        {showMedia && (
          <div
            className="absolute w-[25%] bottom-[9%] right-[7%] overflow-visible max-sm:right-[10%] max-sm:bottom-[20%]"
            ref={mediaRef}
          >
            <MediaWindow
              setInputValue={setInputValue}
              setShowMedia={setShowMedia}
            />
          </div>
        )}
        {showPicker && (
          <div
            className="absolute bottom-[9%] right-[9%] overflow-visible max-sm:right-[10%] max-sm:bottom-[20%]"
            ref={pickerRef}
          >
            <Picker onEmojiClick={onEmojiClick} theme={Theme.DARK} />
          </div>
        )}
        <button
          type="submit"
          className="px-3 py-2 ml-2 text-white bg-[var(--red)] hover:bg-[#d10643] rounded-xl duration-100 ease-in-out"
        >
          <IoSendSharp className="text-white" size={20} />
        </button>
      </form>
    </>
  );
}

export default UserChatInput;
