import { RouteName } from "constant/routeNames";
import FeaturePages from "layout/FeaturePages";
import { Route, Routes } from "react-router-dom";
import BlogsHome from "./Blogs/BlogsHome";
import DiscoverBlogs from "./Blogs/DiscoverBlogCategory";
import SeeAllBlogCategory from "./Blogs/SeeAllBlogCategory";
import BlogsDetails from "./Blogs/BlogsDetails";
import SeeAllBlogs from "./Blogs/SeeAllBlogs";

function BlogPage({
  handleClick,
  isActive,
  setIsActive,
  // setPlayerIsActive,
  anyPlayerActive,
}: any) {
  // useEffect(() => {
  //   setPlayerIsActive(isActive);
  // }, []);
  return (
    <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
      <Routes>
        <Route
          path=""
          element={
            <FeaturePages
              Content={BlogsHome}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Blogs"}
            />
          }
        />
        <Route
          path={RouteName.DiscoverBlogCategory}
          element={
            <FeaturePages
              Content={DiscoverBlogs}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Blogs"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllBlogCategory}
          element={
            <FeaturePages
              Content={SeeAllBlogCategory}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Blogs"}
            />
          }
        />
        <Route
          path={RouteName.BlogDetails}
          element={
            <FeaturePages
              Content={BlogsDetails}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Blogs"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllBlogs}
          element={
            <FeaturePages
              Content={SeeAllBlogs}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Blogs"}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default BlogPage;
