import { RouteName } from "constant/routeNames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setVideoStart } from "redux/VideoPlayerRedux";
import LazyLoading from "./LazyLoading";
import { FaPlay } from "react-icons/fa";
import "styles/card.css";

function EpisodeCard({ video, id, season, slug }: any) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { lastWatched } = useSelector((state: any) => state?.video);
  const [mouseHover, setMouseHover] = useState<boolean>(false);
  const [watchTimePercenatage, setWatchTimePercenatage] = useState<any>();
  const episodeOnClick = () => {
    navigate(
      RouteName.WatchTVSeries.replace(":slug", slug).replace(
        ":stream",
        video?.stream_key
      )
    );
    dispatch(setVideoStart());
    window.location.reload();
  };
  useEffect(() => {
    const findLastWatch = lastWatched.find((v: any) => v.id === video.id);

    if (findLastWatch) {
      setWatchTimePercenatage(
        (findLastWatch.currentTime / findLastWatch.duration) * 100
      );
    }
  }, [video, lastWatched]);

  return (
    <div className="h-[220px] w-[270px] hover:scale-105  place-items-center shadow-lg mx-auto max-sm:w-[160px] max-lg:w-[230px] max-xl:w-[250px]">
      {/* <div className="absolute    bottom-[-10px] max-sm:left-4 max-lg:left-4 left-[-15px] mx-auto items-center flex justify-center !z-50 w-full  h-80">
        <button className="p-3 bg-black/30 rounded-full border-[1px] hover:bg-red-600">
          <FaPlay className="" />
        </button>
      </div> */}
      <div
        className=""
        onMouseEnter={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
        onClick={episodeOnClick}
      >
        {mouseHover && (
          <div className="absolute bottom-0 !z-50 flex items-center justify-center w-[270px] h-[280px] video-details-play-button">
            <button className="p-3 rounded-full">
              <FaPlay />
            </button>
          </div>
        )}

        {/* image */}
        <div className="relative w-[100%] rounded-lg max-sm:w-[160px] max-sm:mx-auto object-cover">
          <LazyLoading
            imgSrc={video?.thumbnail}
            imgAlt={"Card pic"}
            imgHeight={"160px"}
            imgWidth={"100%"}
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
          />

          <div className="absolute h-[30%] inset-x-0 bottom-0 flex items-center justify-between p-2 mt-10 mb-1  !z-50 bg-gradient-to-t from-[#000] to-transparent rounded-lg">
            {video.duration ? (
              <>
                <p className="mt-12 text-sm align-bottom text-slate-100">
                  <span>
                    {Math.floor(video.duration / 60) > 0 &&
                      `${Math.floor(video.duration / 60)}h`}
                  </span>
                  <span className="ml-1">
                    {Math.floor(video.duration % 60) > 0 &&
                      `${Math.floor(video.duration % 60)}min`}
                  </span>
                </p>
                <div></div>
              </>
            ) : (
              <></>
            )}
          </div>

          {/* card inside content */}
        </div>
      </div>

      <div className=" h-[60px] top-1 mb-2 ">
        <div className="my-[4px]  max-sm:ml-0">
          <p className="text-[14px] font-[600] text-white hover:text-zinc-100 max-sm:w-full">
            {video?.title?.length > 50
              ? `${video?.title?.slice(0, 50)}`
              : video?.title}
          </p>
        </div>
        {watchTimePercenatage && (
          <div className="flex justify-center shadow-lg">
            <div className="absolute rounded-lg top-[-35px]  bg-gray-600 w-[95%] h-[6px] mt-2">
              <div
                className={`bg-red-500 h-full `}
                style={{ width: watchTimePercenatage }}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EpisodeCard;
