import PayPerViewCard from "pages/PayPerView/shared/PayPerViewCard";
import NoSearch from "pages/searchPage/shared/NoSearch";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PayperviewService from "services/payperview/PayperviewServie";

function PayperviewPage({ creatorData }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const ppvEvents = creatorData?.ppvEvents?.data || [];
  const [purchased, setPurchased] = useState<any>([]);

  useEffect(() => {
    PayperviewService.getPurchasedEvents(loggedInUser?.id)
      .then((res) => {
        setPurchased(res.data);
      })
      .catch((e: any) => {
      });
  }, []);

  return (
    <div>
      {/* card section */}
      {ppvEvents.length === 0 && ppvEvents ? (
        <NoSearch text="Pay-Per-View" />
      ) : (
        <div>
          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {ppvEvents.length > 0 &&
              ppvEvents.map((event: any, i: any) => (
                <div key={i}>
                  <PayPerViewCard
                    event={event}
                    user={loggedInUser}
                    isPurchased={
                      purchased.some(
                        (payperviewEvent: any) =>
                          payperviewEvent?.id === event?.id
                      )
                        ? true
                        : false
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PayperviewPage;
