import React from "react";
import NoSearch from "./shared/NoSearch";
import { ArtistCard } from "pages/musicPage/shared/ArtistCard";

const SearchArtist = ({ artists }: any) => {
  return (
    <div>
      {artists?.length === 0 && artists ? (
        <NoSearch text="Artists" />
      ) : (
        <div>
          <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
            {artists.length > 0 &&
              artists.map((artist: any, i: any) => (
                <div key={i} className="mx-auto">
                  <ArtistCard artist={artist} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchArtist;
