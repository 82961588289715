import React from "react";
import BlogCardCategory from "./BlogCardCategory";

const BlogBody = ({ blogsDetails }: { blogsDetails: any }) => {
  return (
    <div>
      <div className="flex-col justify-center items-center text-justify w-[95%] mx-auto my-10 text-gray-400 border-gray-700 ">
        <div className="flex items-center justify-between my-10">
          <div className="flex flex-col justify-start">
            <p className="text-white font-medium text-[18px]">Published On</p>
            <p className="text-base text-gray-500">
              {blogsDetails?.created_at}
            </p>
          </div>
          <BlogCardCategory categories={blogsDetails?.categories} />
        </div>
        <p
          dangerouslySetInnerHTML={{ __html: blogsDetails.full_content }}
          className=""
        />
      </div>
    </div>
  );
};

export default BlogBody;
