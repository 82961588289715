import OneRecording from "./OneRecording";

const Recordings = ({ loggedInUser, hasPurchased, event, isReplay }: any) => {
  return (
    <div>
      {loggedInUser && isReplay === 1 ? (
        <>
          {hasPurchased && (
            <div>
              <hr className="w-[95%] mx-auto !mt-5 bg-[#414141] opacity-25" />
              <div className="w-[95%] px-5 py-6 mb-8  rounded max-sm:w-[98%] flex flex-col ">
                <div className="flex items-center justify-between">
                  <h3 className="mb-3 text-xl font-bold text-gray-200">
                    Recordings
                  </h3>
                  <p className="text-[12px] text-gray-600">
                    {event?.replay_videos.length} available
                  </p>
                </div>
                <div
                  className={`${
                    event?.replay_videos.length > 3
                      ? ` h-[300px] !overflow-y-scroll custom-scrollbar  overflow-x-hidden w-full `
                      : ``
                  }`}
                >
                  {event?.replay_videos?.map((replayEvent: any, i: number) => (
                    <div key={i} className="mb-3">
                      <OneRecording recording={replayEvent} event={event} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Recordings;
