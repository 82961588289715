import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay, Pagination } from "swiper";
import { useEffect, useState } from "react";
import Sliders from "services/sliders/Sliders";
import WelcomeHeroSlider from "components/skeleton/WelcomeHeroSlider";
import LiveTVService from "services/liveTv/LiveTVService";
import PPVAdvertiesmentCard from "./PPVAdvertiesmentCard";
import SubText from "components/shared/SubText";

function PPVAdvertiesments() {
  const [slider, setSlider] = useState<any[]>([]);
  const [isLoading, setIsLoding] = useState<any>(false);

  useEffect(() => {
    setIsLoding(true);
    const getSliderData = async () => {
      await LiveTVService.getTVChannelBanner().then((res) => {
        setIsLoding(false);
        // Sort the slider data by order value before setting it to state
        const sortedData = res.data.sort((a: any, b: any) => a.order - b.order);
        setSlider(sortedData);
      });
    };

    getSliderData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoding(false);
    }, 3000);
  }, [isLoading]);

  return (
    <>
      {!isLoading && slider && slider?.length > 0 && (
        <>
          {/* {slider?.length !== 0 && (
            <SubText text={"Advertiesments"} className="font-bold mb-2" />
          )} */}
          <Swiper
            navigation={false}
            modules={[Navigation, Autoplay, Pagination]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: true,
            }}
            className="mt-4 mb-10 mySwiper"
          >
            {slider?.map((d: any, i) => (
              <SwiperSlide key={i}>
                <PPVAdvertiesmentCard data={d} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
      {isLoading && <WelcomeHeroSlider />}
    </>
  );
}

export default PPVAdvertiesments;
