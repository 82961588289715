import { RouteName } from "constant/routeNames";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearDataWhenLogout } from "redux/AudioPlayerRedux";
import { logout } from "redux/userRedux";
import AuthService from "services/auth/AuthService";
import LoadingSpin from "components/Loading spins/LoadingSpin";
import { useState } from "react";

function ProfileIconSubMenu({ className }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const enabled = localStorage.getItem("ppv_enabled");

  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const handleLogout = (e: any) => {
    e.preventDefault();
    setLoading(true);
    AuthService.logout()
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(logout());
          navigate("/login");
          dispatch(clearDataWhenLogout());
        }
        setLoading(false);
      })
      .catch((e: Error) => {
        setLoading(false);
      });
  };

  return (
    <div
      className={`text-white text-left navbar max-sm:min-h-[150px] submenu-main absolute ${
        className && className
      }`}
    >
      <div className="leading-10">
        <Link to={RouteName.UserProfile.replace("*", loggedInUser.username)}>
          <p className="sub-menu-item">My Profile</p>
        </Link>
        {/* <Link to={RouteName.Playlists}>
          <p className="sub-menu-item">My Playlists</p>
        </Link> */}
        <Link to={RouteName.EditProfile}>
          <p className="sub-menu-item">Settings</p>
        </Link>
        {/* <p className="cursor-pointer">Creator Studio</p> */}
        {/* <div className="flex items-center justify-between gap-5">
          <p className="cursor-pointer">Interface</p>
          <ModeChangeSwitch />
        </div> */}
        {enabled === "1" && (
          <>
            <Link to={RouteName.transection}>
              <p className="sub-menu-item">Transactions</p>
            </Link>
            <Link to={RouteName.PaidSubscriptionsRoute}>
              <p className="sub-menu-item">Paid Subscriptions</p>
            </Link>
          </>
        )}
        <Link to={RouteName.BecomeACreator}>
          <p className="sub-menu-item">Become a Creator</p>
        </Link>
        <Link to={RouteName.UserChat.replace("*", "")}>
          <p className="sub-menu-item">Chat</p>
        </Link>

        <div className="mt-3 mb-2">
          <hr />
        </div>

        <p
          className="flex items-center justify-between sub-menu-item"
          onClick={handleLogout}
        >
          <span>Sign out</span>
          <span>
            {loading ? <LoadingSpin fontSize={20} color={"red"} /> : ``}
          </span>
        </p>
      </div>
    </div>
  );
}

export default ProfileIconSubMenu;
