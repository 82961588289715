import { Input } from "antd";
import { ChatContext } from "context/ChatContext";
import { useContext, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import ChatRequest from "./ChatRequest";
import ChatUser from "./ChatUser";

const inputStyles = {
  border: "none",
  backgroundColor: "#434343",
  color: "white",
};

function AllChats({ chatData }: any) {
  const { setSelectedChat, isLoading, allChats } = useContext(ChatContext);

  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const [showRequests, setShowRequests] = useState<boolean>(false);
  const [approvedChats, setApprovedChats] = useState<Array<any>>([]);
  const [pendingChats, setPendingChats] = useState<Array<any>>([]);
  const [searchText, setSeachText] = useState<string>("");

  const extractApprovedChats = () => {
    const approved = allChats?.filter((chat: any) => {
      if (!chat.status) {
        chat.status = "pending";
      }
      return (
        chat?.status === "approved" ||
        (chat.status === "pending" &&
          chat?.chat_name.split("-")[0] == loggedInUser?.id)
      );
    });
    setApprovedChats(approved);
  };

  const extractPendingChats = () => {
    const pendingChatsChats = allChats?.filter((chat: any) => {
      if (!chat.status) {
        chat.status = "pending";
      }
      return (
        chat.status === "pending" &&
        chat?.chat_name.split("-")[0] != loggedInUser?.id
      );
    });
    setPendingChats(pendingChatsChats);
  };
  useEffect(() => {
    extractApprovedChats();
    extractPendingChats();
  }, [allChats]);

  useEffect(() => {
    if (pendingChats?.length == 0) {
      setShowRequests(false);
    }
  }, [pendingChats]);

  const handleShowRequests = () => {
    if (pendingChats?.length > 0) {
      setShowRequests(!showRequests);
    }
  };

  useEffect(() => {
    if (searchText) {
      const filteredChats = allChats?.filter((chat: any) => {
        return (
          chat?.receiver?.name
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) &&
          (chat?.status === "approved" ||
            (chat.status === "pending" &&
              chat?.chat_name.split("-")[0] == loggedInUser?.id))
        );
      });
      setApprovedChats(filteredChats);
    } else {
      extractApprovedChats();
    }
  }, [searchText]);

  useEffect(() => {
    console.log("All Chats", allChats);
  }, [allChats]);

  return (
    <>
      <div
        className={`w-full h-[85vh] p-[15px] bg-[var(--Color1E1E1E)] relative sm:rounded-2xl`}
      >
        <div className="mb-4 h-[5%] ">
          <Input
            size="small"
            placeholder="Search"
            prefix={<BsSearch className="mx-2 font-bold " />}
            style={inputStyles}
            onChange={(e) => setSeachText(e.target.value)}
          />
        </div>
        <div
          className={`flex justify-between items-center ${
            pendingChats?.length > 0
              ? `text-blue-600 cursor-pointer hover:underline`
              : `text-gray-600`
          } duration-200 ease-in-out mt-5 mb-2 h-[5%]`}
          onClick={handleShowRequests}
        >
          {showRequests ? (
            <p>Show chats</p>
          ) : (
            <>
              <p>Message requests</p>
              <p>{pendingChats?.length > 0 && pendingChats.length}</p>
            </>
          )}
        </div>
        <div className="h-[87%] overflow-y-scroll scrollbar-hide">
          {showRequests ? (
            <>
              {pendingChats &&
                pendingChats?.map((data: any, index: number) => (
                  <ChatRequest
                    key={index}
                    chatData={data}
                    // handleChatOpen={handleChatOpen}
                    setSelectedChat={setSelectedChat}
                    setApprovedChats={setApprovedChats}
                    setPendingChats={setPendingChats}
                  />
                ))}
            </>
          ) : (
            <>
              {isLoading ? (
                <>
                  <p>Loading...</p>
                </>
              ) : (
                <>
                  {approvedChats &&
                    (approvedChats?.length > 0 ? (
                      <>
                        {approvedChats?.map((data: any, index: number) => (
                          <ChatUser
                            key={index}
                            // handleChatOpen={handleChatOpen}
                            chatData={data}
                            setSelectedChat={setSelectedChat}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        <p className="text-lg text-center text-gray-400">
                          No messages
                        </p>
                      </>
                    ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AllChats;
