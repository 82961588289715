import { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import SubText from "components/shared/SubText";
import CreatorService from "services/creators/CreatorService";
import CreatorVideoSkeleton from "components/skeleton/CreatorVideoSkeleton";
import VideohomeCreatorCard from "pages/BecomeACreator/shared/VideohomeCreatorCard";

const RandomCreatorVideosCarousal = () => {
  const [followRandomVideos, setFollowRandomVideos] = useState([]);
  const [randomVideos, setRandomVideos] = useState([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    CreatorService.getCreatorsRandomVideo()
      .then((res) => {
        setIsLoading(false);
        setRandomVideos(res.data);
        // console.log("randomVideos", res.data);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    CreatorService.getFollowedCreatorsVideos()
      .then((res) => {
        setIsLoading(false);
        setFollowRandomVideos(res?.randomCreatorVideos);
        // console.log("randomVideos", res?.randomCreatorVideos);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  }, []);

  return (
    <div>
      {!isLoading && (
        <>
          <div className="flex mt-10 justify-between w-[95%] max-sm:w-full  ">
            {randomVideos && randomVideos?.length > 0 && (
              <>
                <SubText
                  text="Creator Videos"
                  className="font-bold max-sm:ml-4"
                />
                <Link to={RouteName.SeeAllCreatorsVideosRoute}>
                  <p className="text-sm font-bold cursor-pointer red-text place-self-end">
                    {""} See all
                  </p>
                </Link>
              </>
            )}
          </div>

          <div className="mx-auto">
            <Swiper
              slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                350: {
                  slidesPerView: 1,
                },
                500: {
                  slidesPerView: 3,
                },
                1100: {
                  slidesPerView: 3,
                },
                1300: {
                  slidesPerView: 4,
                },
                1500: {
                  slidesPerView: 4,
                },
                1700: {
                  slidesPerView: 5,
                },
              }}
              modules={[Pagination, Navigation]}
              navigation={true}
              className="w-full mx-auto mt-5 mb-5 mySwiper"
            >
              {followRandomVideos?.length === 0 ? (
                <>
                  {randomVideos &&
                    randomVideos?.map((video: any, i) => (
                      <SwiperSlide key={i}>
                        <div className="w-full mx-auto">
                          <VideohomeCreatorCard
                            video={video}
                            slug={video?.creator?.slug}
                            isLoading={isLoading}
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                </>
              ) : (
                <>
                  {followRandomVideos &&
                    followRandomVideos?.map((video: any, i) => (
                      <SwiperSlide key={i}>
                        <div className="w-full mx-auto">
                          <VideohomeCreatorCard
                            video={video}
                            slug={video?.creator?.slug}
                            isLoading={isLoading}
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                </>
              )}
            </Swiper>
          </div>
        </>
      )}
      {isLoading && <CreatorVideoSkeleton />}
    </div>
  );
};

export default RandomCreatorVideosCarousal;
