import React, { useContext, useEffect, useState } from "react";
import AllChats from "./shared/AllChats";
import OpenChat from "./shared/OpenChat";
import { IoMdChatbubbles } from "react-icons/io";
import { useSelector } from "react-redux";
import LoginModel from "pages/PayPerView/shared/LoginModal";
import { AllChatData } from "constant/ChatData";
import { IUserChat } from "types/IUserChat";
import { ChatContext } from "context/ChatContext";

function UserChatMain(props: IUserChat) {
  const {
    messages,
    chats,
    createNewChat,
    sendMessage,
    notifyTyping,
    showChatProgress,
    initialScroll,
    latestChat,
    selectedChat,
    setSelectedChat,
    isLoading,
    setIsLoading,
    allChats,
    setAllChats,
  } = useContext(ChatContext);

  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const [chatIsOpen, setChatIsOpen] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  useEffect(() => {
    if (!loggedInUser) {
      setShowLoginModal(true);
    }
  }, [loggedInUser]);

  return (
    <>
      {loggedInUser ? (
        <>
          <div className="sm:pt-5 sm:pb-5 ">
            <div className="flex w-full mx-auto h-[85vh] justify-between  gap-5  max-sm:hidden ">
              <div className="w-[50%] relative ">
                {/* <AllChats chats={allChats} setSelectedChat={setSelectedChat} />
                 */}
                <AllChats />
              </div>

              <div className="w-screen bg-[var(--Color1E1E1E)] rounded-2xl">
                {!selectedChat ? (
                  <>
                    <div className="flex items-center justify-center h-[85vh]">
                      <IoMdChatbubbles className="text-gray-400 text-9xl" />
                      <p className="text-lg text-gray-400">
                        No Conversation Selected
                      </p>
                    </div>
                  </>
                ) : (
                  <OpenChat sidePanel={false} />
                )}
              </div>
            </div>
          </div>

          {/* mobile view */}

          {/* <div className="relative w-full mx-auto h-[85vh]  sm:hidden">
            {chatIsOpen ? (
              <div
                className={`w-full z-10 ${
                  chatIsOpen ? `left-0` : `left-[-120%]`
                }  duration-300 ease-in-out`}
              >
               
              </div>
            ) : (
              <div
                className={`w-full  ${
                  !chatIsOpen ? `right-0` : `right-[-120%]`
                }  duration-300 ease-in-out`}
              >
                <AllChats
                  chats={allChats}
                  setSelectedChat={setSelectedChat}
                  setAllChats={setAllChats}
                  changedMsg={changedMsg}
                  isLoading={isLoading}
                  handleChatOpen={handleMobileChatOpen}
                />
              </div>
            )}
          </div> */}
        </>
      ) : (
        <>
          {showLoginModal ? (
            <LoginModel
              showLoginModal={showLoginModal}
              // clickLogin={closeLoginModal}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default UserChatMain;
