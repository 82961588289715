import React from "react";
import { BsPlayCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { formatToLocalDateString } from "utils/timeFunctions";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useDispatch } from "react-redux";
import { setVideoStart, setVideoSuccess } from "redux/VideoPlayerRedux";

function OneRecording({ recording, event }: any) {
  const navigate = useNavigate();
  console.log("recordings",recording)

  const handleReplayButton = () => {
    navigate("/ppv/replay/" + event?.slug + "/" + recording?.stream_key, {
      state: { prevPage: event?.slug },
    });
  };

  return (
    <div className="flex items-center w-auto justify-start rounded-full max-sm:w-full bg-[#2b2f32] my-5 mr-3 pr-5">
      <button className="" onClick={handleReplayButton}>
        <div className="mr-5">
          <BsPlayCircleFill className=" drop-shadow-2xl text-[50px] hover:scale-102 hover:text-red-300 text-white duration-300 max-sm:text-[50px]" />
        </div>
      </button>
      <div
        className="cursor-default "
        data-tooltip-id={
          recording?.title?.length > 30
            ? `ppv-recording-${recording?.title}`
            : ""
        }
      >
        <p className="text-[15px] max-sm:text-[12px]">
          {/* {event?.title} */}
          {recording?.title?.length > 30
            ? `${recording?.title
                ?.slice(0, 30)
                .replace(/&amp;/g, "&")
                .replace(/&rsquo;/g, "’")
                .replace(/&nbsp;/g, "")}...`
            : recording?.title
                ?.replace(/&amp;/g, "&")
                .replace(/&rsquo;/g, "’")
                .replace(/&nbsp;/g, "")}
        </p>
        <p className="text-[12px] max-sm:text-[8px] text-gray-500">
        {recording?.description?.length > 30
            ? `${recording?.description
                ?.slice(0, 30)
                .replace(/&amp;/g, "&")
                .replace(/&rsquo;/g, "’")
                .replace(/&nbsp;/g, "")}...`
            : recording?.description
                ?.replace(/&amp;/g, "&")
                .replace(/&rsquo;/g, "’")
                .replace(/&nbsp;/g, "")}
        </p>
        <div className="z-50 text-sm">
          <ReactTooltip
            id={`ppv-recording-${recording?.title}`}
            place="top"
            content={recording?.title}
            style={{ width: "180px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default OneRecording;
