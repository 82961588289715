import React, { useEffect, useState } from "react";
import BlogCardSkeleton from "./skeleton/BlogCardSkeleton";
import BlogCard from "./shared/BlogCard";
import { Link, useNavigate } from "react-router-dom";
import SubText from "components/shared/SubText";
import { RouteName } from "constant/routeNames";
import BlogService from "services/blogs/BlogService";
import { HiArrowLeft } from "react-icons/hi";

const SeeAllBlogs = () => {
  const [blogs, setBlogs] = useState<any>(null);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    BlogService.getAllBlogs().then((res) => {
      setIsLoading(false);
      setBlogs(res.data);
      console.log(res.data);
    });
  }, []);
  return (
    <div>
      <div
        className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer max-sm:ml-[7px] mb-3`}
        onClick={() => {
          navigate(RouteName.Blog.replace("/*", ""));
        }}
      >
        <HiArrowLeft className="text-black" />
      </div>
      <div className="flex justify-between w-full ">
        <h1 className="mb-5 text-xl font-medium text-white lg:text-2xl md:text-3xl max-sm:ml-2 max-sm:mb-5">
          All Blogs
        </h1>
      </div>

      {!isLoading ? (
        <>
          <div className="grid w-[99%] grid-cols-3 gap-5 max-sm:grid-cols-1 max-md:grid-cols-1 max-lg:grid-cols-3">
            {blogs?.map((blog: any, i: number) => (
              <div key={i}>
                <BlogCard isLoading={true} blog={blog} />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <BlogCardSkeleton />
        </>
      )}
    </div>
  );
};

export default SeeAllBlogs;
