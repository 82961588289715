import VideosByUser from "./shared/VideosByUser";
import MusicByUser from "./shared/MusicByUser";
import PodcastByUser from "./shared/PodcastByUser";
import RadiosByUser from "./shared/RadiosByUser";
import TvSeriesByUser from "./shared/TvSeriesByUser";
import ArtistByUser from "./shared/ArtistByUser";
import OverviewSkeleton from "components/skeleton/OverviewSkeleton";
import PlaylistsByUser from "./shared/PlaylistsByUser";
import NoFavorite from "./shared/NoFavorite";
import PayperviewByUser from "./shared/PayperviewByUser";
import EpisodesByUser from "./shared/EpisodesByUser";
import FollowedCreators from "./shared/FollowedCreators";

function UserPageOverView({
  user,
  favoriteData,
  favoriteMoviesIds,
  favoriteTvSeriesIds,
  favoriteEpisodesIds,
  favoriteMusicIds,
  favoritePodcastsIds,
  favoriteRadioIds,
  favoriteArtistIds,
  favoritePpvIds,
  favoriteCreatorIds,
  userPublicPlaylists,
  userPlaylists,
  setUserPlaylists,
  isLoading,
}: any) {
  const enabled = localStorage.getItem("ppv_enabled");

  return (
    <>
      {favoriteMoviesIds?.length === 0 &&
      favoriteTvSeriesIds?.length === 0 &&
      favoriteEpisodesIds?.length === 0 &&
      favoriteMusicIds?.length === 0 &&
      favoriteArtistIds?.length === 0 &&
      favoritePodcastsIds?.length === 0 &&
      favoriteRadioIds?.length === 0 &&
      userPublicPlaylists?.length === 0 &&
      favoritePpvIds?.length === 0  && 
      favoriteCreatorIds?.length === 0 ?(
        <>
          <NoFavorite user={user} />
        </>
      ) : (
        <>
          {!isLoading && (
            <div>
              {/* <UploadedByUser /> */}
              {favoriteMoviesIds?.length !== 0 && (
                <VideosByUser
                  user={user}
                  favorite={favoriteMoviesIds}
                  favoriteData={favoriteData}
                />
              )}
              {favoriteTvSeriesIds?.length !== 0 && (
                <TvSeriesByUser
                  user={user}
                  favorite={favoriteTvSeriesIds}
                  favoriteData={favoriteData}
                />
              )}
             {favoriteCreatorIds?.length !== 0 && (
                <FollowedCreators user={user} favoriteData={favoriteData} />
              )}
              {favoriteEpisodesIds?.length !== 0 && (
                <EpisodesByUser
                  user={user}
                  favorite={favoriteTvSeriesIds}
                  favoriteData={favoriteData}
                />
              )}
              {favoriteMusicIds?.length !== 0 && (
                <MusicByUser
                  user={user}
                  favoriteData={favoriteData}
                  favorite={favoriteMusicIds}
                  userPlaylists={userPlaylists}
                  setUserPlaylists={setUserPlaylists}
                />
              )}
              {userPublicPlaylists?.length > 0 && (
                <PlaylistsByUser
                  user={user}
                  isLoading={isLoading}
                  userPublicPlaylists={userPublicPlaylists}
                />
              )}
              {favoriteArtistIds?.length !== 0 && (
                <ArtistByUser user={user} favoriteData={favoriteData} />
              )}
              {favoritePodcastsIds?.length !== 0 && (
                <PodcastByUser
                  user={user}
                  favorite={favoritePodcastsIds}
                  favoriteData={favoriteData}
                />
              )}
              {favoriteRadioIds?.length !== 0 && (
                <RadiosByUser
                  user={user}
                  favorite={favoriteRadioIds}
                  favoriteData={favoriteData}
                />
              )}
              {enabled === "1" && (
                <>
                  {favoritePpvIds?.length !== 0 && (
                    <PayperviewByUser
                      user={user}
                      favorite={favoritePpvIds}
                      favoriteData={favoriteData}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {isLoading && <OverviewSkeleton />}
        </>
      )}
    </>
  );
}

export default UserPageOverView;
