import React from "react";
import { GiReceiveMoney } from "react-icons/gi";
import { MdOutlineAutoGraph } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdAppShortcut } from "react-icons/md";
import SectionTitle from "./SectionTitle";

const ProcessStep = () => {
  const cards = [
    {
      id: 1,
      header: "Setup",
      text: "Easily onboard with AYOZAT and integrate PPV into your stream on ayozat.com.",
      icon: <GiReceiveMoney size={30} className="mx-auto text-white" />,
    },
    {
      id: 2,
      header: "Ticket Sales",
      text: "Market your event across AYOZAT, your platforms, and your brand’s audience to maximize ticket sales.",
      icon: <MdAppShortcut size={30} className="mx-auto text-white" />,
    },
    {
      id: 3,
      header: "Go Live",
      text: "Your event streams live to ticket holders, providing a new distribution point and offering an exclusive experience to your audience in the comfort of their homes.",
      icon: <AiOutlineUsergroupAdd size={30} className="mx-auto text-white" />,
    },
    {
      id: 4,
      header: "Reap the Rewards",
      text: "After your PPV event ends, you’ll get paid, your fan base will grow, and you can focus on planning your next event!",
      icon: <MdOutlineAutoGraph size={30} className="mx-auto text-white" />,
    },
  ];
  return (
    <div className="mb-[100px]">
      <SectionTitle text={"How Does Pay-Per-View Work?"} />
      <div className="w-[80%] mx-auto grid grid-cols-2 gap-10 my-10 max-sm:grid-cols-1 max-md:grid-cols-1 max-lg:grid-cols-1 max-sm:w-[95%] cursor-default">
        {cards.map((card: any, index: any) => (
          <div className="flex justify-between items-center w-full h-[200px] bg-[#0b0b0b] rounded-2xl text-white hover:border hover:border-red-800 hover:duration-500 text-center p-8 hover:bg-gradient-to-r hover:from-[#0b0b0b] hover:to-[#f305456b] overflow-hidden">
            <div className="w-[20%] flex items-center mr-8">
              <div className="text-[18px] font-semibold text-center w-[50px] h-[50px] flex items-center justify-center rounded-full  border border-white mx-auto">
                <p>{card.id}</p>
              </div>
            </div>
            <div className="flex flex-col text-start w-[95%]">
              <h1 className="font-bold text-[22px] mb-2 max-sm:text-[17px]">
                {card.header}
              </h1>
              <p className="text-[15px] text-gray-300 font-light max-sm:text-[12px]">
                {card.text}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProcessStep;
