import React from "react";
import VideoCard1 from "./VideoCard1";
import redElipce from "Assets/Images/red-eclipse.png";

const LeftTextSection = ({ mainText, subText, image }: any) => {
  return (
    <div className="relative w-full cursor-default">
      <img
        src={redElipce}
        alt="red_eclipse"
        className="absolute bottom-[-200px] right-0 max-sm:hidden"
      />
      <VideoCard1 mainText={mainText} subText={subText} image={image} />
    </div>
  );
};

export default LeftTextSection;
