import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const BlogCardSkeleton = () => {
  const arr = [1, 2, 3];
  return (
    <div className="mt-10">
      <div className="">
        <Swiper
          slidesPerView={3}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            650: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 3,
            },
            1500: {
              slidesPerView: 3,
            },
            1600: {
              slidesPerView: 3,
            },
          }}
          className="mx-auto mt-4 mb-5 mySwiper"
        >
          <div className="flex w-full flex-row lg:gap-[20px] sm:gap-[20px]">
            {arr.map((item: any) => (
              <div className="w-full max-sm:my-3 max-sm:block">
                <div className="relative w-[100%] rounded-lg h-[300px] bg-img white-text flex max-sm:w-[100%] bg-[#1a1919] animate-pulse"></div>
              </div>
            ))}
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default BlogCardSkeleton;
