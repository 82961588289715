import SubText from "components/shared/SubText";
import { useSelector } from "react-redux";
import ShareButton from "./ShareButton";
import AddToFavCreator from "./AddToFavCreator";
import VideoDetailsSkeleton from "components/skeleton/VideoDetailsSkeleton";

const VideoCreatorDetails = ({ videoDetails, isLoading }: any) => {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const baseUrl = window.location.origin;
  const currentUrl = baseUrl + window.location.pathname;
  const { currentVideo } = useSelector((state: any) => state?.video);

  return (
    <>
      {isLoading && (
        <div className="w-full max-sm:ml-3">
          <div className="flex justify-between items-center w-full max-sm:w-[98%] max-sm:block ">
            <div className="">
              <SubText text={currentVideo?.title} />
            </div>
            <div className="flex items-center max-sm:mt-3">
              {/* <div className="m-4 cursor-pointer">
            <LikeButton />
          </div> */}
              <AddToFavCreator
                loggedInUser={loggedInUser}
                video={currentVideo}
              />
              <div className="ml-5">
                <ShareButton shareUrl={currentUrl} />
              </div>
            </div>
          </div>

          <div className="flex mt-5">
            <p
              className="text-gray-400 text-[14px] w-full "
              dangerouslySetInnerHTML={{
                __html:
                  currentVideo?.description
                    .replace(/&amp;/g, "&")
                    .replace(/&rsquo;/g, "’")
                    .replace(/&ldquo;/g, '"')
                    .replace(/&rdquo;/g, '"')
                    .replace(/&nbsp;/g, "") || "",
              }}
            />
          </div>
        </div>
      )}
      {!isLoading && <VideoDetailsSkeleton />}
    </>
  );
};

export default VideoCreatorDetails;
