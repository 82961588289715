import img from "Assets/Images/ProfileAvatar.png";
import { IoArrowBackCircle } from "react-icons/io5";
import { Link } from "react-router-dom";

function ChatHeaderOpenChat({ sidePanel, receiver, setSelectedChat }: any) {
  // console.log("ChatHeaderOpenChat : ", receiver);
  return (
    <>
      <div
        className={`w-full flex justify-between items-center bg-[var(--open-chat-header)] p-3 max-sm:pl-1 ${
          sidePanel ? `` : `sm:rounded-t-md`
        }`}
      >
        <div className="flex items-center justify-start gap-2">
          {/* back button */}
          <div
            className={`${
              sidePanel ? `` : `sm:hidden`
            } text-4xl text-white cursor-pointer`}
            onClick={() => {
              setSelectedChat(null);
            }}
          >
            <IoArrowBackCircle />
          </div>
          <Link to={`/profile/${receiver?.username}`}>
            {/* user details - image */}
            <div className="flex items-center">
              <img
                src={receiver ? receiver?.artwork_url : img}
                alt="profile pic"
                className="rounded-full w-14 h-14"
              />

              {/* user details - name & last seen */}
              <div className="ml-4 text-white">
                <p
                  onClick={() => {
                    setSelectedChat(null);
                  }}
                >
                  {receiver?.name}
                </p>
              </div>
            </div>
          </Link>
        </div>

        {/* future options */}
        {/* <div className="flex justify-end items-center gap-3 text-[var(--Color7B9DB4)]">
          <BsSearch className="text-2xl cursor-pointer" />
          <IoMdMore className="text-3xl cursor-pointer" />
        </div> */}
      </div>
    </>
  );
}

export default ChatHeaderOpenChat;
