const VideoCard1 = ({ mainText, subText, image }: any) => {
  return (
    <div className="w-[80%] mx-auto mt-[120px] relative z-20 max-sm:mt-[30px] cursor-default">
      <div className="flex items-center justify-center gap-2 white-text max-sm:flex-col-reverse max-md:flex-col-reverse">
        {/* left section */}
        <div className="flex flex-col items-center text-center md:items-start md:text-left w-[50%] max-sm:w-full max-md:w-full pr-10 max-sm:p-0 max-md:p-0">
          <h1 className="title-font mb-4 text-[28px] white-text w-[100%] font-[700] max-sm:text-[20px]">
            {mainText}
          </h1>
          <div className="mt-2 gray-text">
            <div className=" mb-3 text-[18px] font-[400] max-sm:text-[15px]">
              <p className="">{subText}</p>
            </div>
          </div>
        </div>

        {/* right section */}
        <div className="w-[50%] max-sm:w-full max-md:w-full">
          <img
            className="z-20 object-cover object-end w-[100%] h-[300px] max-sm:h-[200px] rounded-2xl shadow-xl scale-90 hover:scale-100 hover:duration-500"
            alt="hero"
            src={image}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoCard1;
