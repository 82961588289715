import ppv1 from "Assets/Images/ppv-1.jpeg";
import ppv2 from "Assets/Images/ppv-2.jpeg";
import ppv3 from "Assets/Images/ppv-3.jpeg";
import ppv4 from "Assets/Images/ppv-4.jpeg";
import ppv5 from "Assets/Images/ppv-5.jpeg";
import ppv6 from "Assets/Images/ppv-6.jpeg";
import ppvHeader from "Assets/Images/ppv-landing-header.jpeg";
import ppvimage from "Assets/Images/ppv-what.jpg";
import Logo from "Assets/ayozat_logo.svg";
import HeroSection from "pages/seoLandingPage/shared/HeroSection";
import ContentNavbar from "components/ContentNavbar";
import WelcomePageSidebar from "pages/welcome/WelcomePageSidebar";
import { contentNavData } from "constant/ContentNavBarData";
import Footer from "components/Footer";
import { useEffect } from "react";
import { RouteName } from "constant/routeNames";
import ServiceCardsSection from "./shared/ServiceCardsSection";
import LeftTextSection from "pages/videoPage/landingPage/shared/LeftTextSection";
import RightTextSection from "pages/videoPage/landingPage/shared/RightTextSection";
import SectionTitle from "pages/videoPage/landingPage/shared/SectionTitle";
import ProcessStep from "pages/videoPage/landingPage/shared/ProcessStep";
import CenterTextSection from "pages/videoPage/landingPage/shared/CenterTextSection";
import Paralex from "pages/videoPage/landingPage/shared/Paralex";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const PPVLanding = ({ isActive, handleClick, setIsActive }: any) => {

  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  
  return (
    <>
    <Helmet>
        <title>
          Watch Exclusive Pay Per View content with AYOZAT.com
        </title>
        <meta
          name="description"
          content="Join AYOZAT.com for PPV streams featuring exclusive content, interactive events, and special shows. Subscribe now for the ultimate fan experience."
        />
        <link rel="canonical" href={`${FE_URL}/ppv/influencer `}/>
        <meta
          property="og:title"
          content="Watch Exclusive Pay Per View content with AYOZAT.com"
        />
        <meta
          property="og:description"
          content="Join AYOZAT.com for PPV streams featuring exclusive content, interactive events, and special shows. Subscribe now for the ultimate fan experience."
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:type" content="website" />
        <meta name="og:url" content={`${FE_URL}/ppv/influencer `}/>

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Watch Exclusive Pay Per View content with AYOZAT.com"
        />
        <meta
          name="twitter:description"
          content="Join AYOZAT.com for PPV streams featuring exclusive content, interactive events, and special shows. Subscribe now for the ultimate fan experience."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT STREAM" />
        <meta name="twitter:url" content={`${FE_URL}/ppv/influencer `} />
      </Helmet>

      <ContentNavbar
        handleClick={handleClick}
        isActive={isActive}
        setIsActive={setIsActive}
      />
      <WelcomePageSidebar
        isActive={isActive}
        navData={contentNavData}
        setIsActive={setIsActive}
      />
      <div
        className={
          isActive
            ? "md:pl-[100px] pl-[80px] duration-500 p-2 mt-2 ml-3 max-sm:pl-0 max-sm:ml-2 "
            : "pl-0 duration-500 p-2 mt-2 ml-8 max-sm:pl-0 max-sm:ml-2 "
        }
      >
        <HeroSection
          Logo={Logo}
          link={RouteName.PayPerView.replace("*", "")}
          image={ppvHeader}
        />

        <CenterTextSection
          title="Revolutionize Your Income – Tap Into Fresh Revenue Sources"
          description="In today's digital landscape, content creators and musicians need
          innovative ways to generate revenue. Pay-Per-View (PPV) videos offer
          an additional source of income while giving creators full control over
          their content."
        />

        <Paralex src={ppvimage} alt="paralex" />

        <CenterTextSection
          title="What Is Pay-Per-View?"
          description="Pay-Per-View (PPV) allows viewers to access premium live content for a
          fee. With AYOZAT, you can earn money every time you hold a PPV event.
          This flexible model caters to diverse content types, including
          tutorials, workshops, events, and exclusive interviews."
        />

        <ServiceCardsSection />

        <ProcessStep />

        <SectionTitle text={"Creator and Influencer Benefits for PPV Events"} />

        <LeftTextSection
          mainText={`Guaranteed Earnings`}
          subText={`Ensure a reliable income stream from your live events, regardless of audience size.`}
          image={ppv1}
        />

        <RightTextSection
          mainText={`Creative Autonomy`}
          subText={`Retain full ownership and control over your content, minimizing dependency on advertising through Pay-Per-View options.`}
          image={ppv2}
        />

        <LeftTextSection
          mainText={`Audience Analytics`}
          subText={`Access detailed insights into your viewers, including demographics and preferences.`}
          image={ppv3}
        />

        <RightTextSection
          mainText={`Global Reach`}
          subText={`Expand your audience and maximize revenue by reaching viewers worldwide.`}
          image={ppv4}
        />

        <LeftTextSection
          mainText={`Live Show Monetization`}
          subText={`Run live shows as Pay-Per-View events, reaching both your audience and AYOZAT's extensive user base. Benefit from instant monetization with a majority revenue share split.`}
          image={ppv5}
        />

        <RightTextSection
          mainText={`Diverse Content Opportunities`}
          subText={`PPV offers influencers, content creators, musicians, sports bodies, and more the chance to secure a majority share of the revenue from new sources.`}
          image={ppv6}
        />

        <div className="mt-[100px] w-[80%] flex flex-col items-center justify-center mx-auto text-center mb-[100px] max-sm:w-[90%] cursor-default">
          <p className="font-Plus Jakarta Sans white-text text-[24px] font-[700] text-center max-sm:text-[20px]">
            With Pay-Per-View, you're in control of your content and your
            earnings. Start monetizing your live videos and events today, and
            unleash your creative potential.
          </p>

          <Link to={RouteName.PayPerView.replace("*", "")}>
            <div className="white-text z-50  button-red px-8  py-1.5 mt-[50px] font-medium rounded-full xl:text-lg lg:text-lg md:text-md sm:text-sm">
              Explore content
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PPVLanding;
