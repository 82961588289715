import LiveButton from "pages/PayPerView/shared/LiveButton";
import React from "react";
import { TbLivePhoto } from "react-icons/tb";

const ChatHeader = ({ event, liveUsers }: any) => {
  // console.log(event);
  return (
    <div>
      <div className="flex items-center justify-between w-full p-4 max-sm:block">
        <h1 className="font-bold text-white text-[17px] w-[80%] ">
          {event.title}
        </h1>
        <div className="font-light text-[12px] text-gray-600 flex items-center  ">
          {event?.streaming_status === "streaming" && (
            <>
              Live <TbLivePhoto className="mx-3 text-[#40ff66] animate-ping" />
            </>
          )}
        </div>
      </div>
      <hr className="h-[1px] opacity-20" />
    </div>
  );
};

export default ChatHeader;
