import { FaApple, FaFacebookF } from "react-icons/fa";
import { HiOutlineX } from "react-icons/hi";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form } from "antd";

//import logo
import Logo from "Assets/Images/a_logo.png";
import { RouteName } from "constant/routeNames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loginFailure,
  loginStart,
  loginSuccess,
  updateFavorites,
} from "redux/userRedux";
import { toast } from "react-toastify";
import AuthService from "services/auth/AuthService";
import LikeService from "services/music/LikeService";
import AntDInputField from "pages/editProfile/AntDInputField";
import LoadingSpin from "components/Loading spins/LoadingSpin";

function LoginModel({ clickLogin, setShowLoginModal }: any) {
  let navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [searchParams] = useSearchParams();
  const email_verify_message = searchParams.get("email_verify_message");

  const [loadingSpin, setLoadingSpin] = useState<boolean>(false);
  const [emailVerfiedError, setEmailVerfiedError] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({
    email: [],
    password: [],
  });

  // useEffect(() => {
  //   if (loggedInUser) {
  //     navigate(RouteName.Home);
  //   }
  // }, [loggedInUser, navigate]);

  useEffect(() => {
    if (email_verify_message) {
      toast.info(email_verify_message);
    }
  }, [email_verify_message]);

  const submit = async (e: any) => {
    setLoadingSpin(true);
    e.preventDefault();
    removeErrors();
    dispatch(loginStart());
    const formData = form.getFieldsValue();
    await AuthService.login(formData)
      .then((response: any) => {
        setLoadingSpin(true);

        if (response?.status === 200) {
          // console.log(response?.data?.data?.user);
          dispatch(loginSuccess(response?.data?.data?.user));
          toast.success("Login successful");
          const loggedUser = response?.data?.data?.user;
          
          if (loggedUser) {
            // get user favorites
            LikeService.getFavoriteById(loggedUser?.id, loggedUser?.id)
              .then((res) => {
                // if (res?.status === 200) {
                // console.log(res);
                dispatch(updateFavorites(res.profile));
                // window.location.reload();
                // }
              })
              .catch((e: any) => {});

            if (response?.data?.data?.user?.old_user === 0) {
              clickLogin();
            } else {
              navigate(RouteName.UpdateOldUserPassword);
            }
            window.location.reload();
            // navigate(RouteName.Home);
          } else {
            navigate(RouteName.Login);
          }
        } else {
          toast.error("There is an issue when attempting to log in");
          dispatch(loginFailure);
        }
        setLoadingSpin(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingSpin(false);
        if (error?.response && error?.response?.status === 422) {
          const errorData = error.response.data.errors;
          setErrors(errorData);
        } else if (error?.response?.status === 401) {
          const emailError = [
            "The provided credentials do not match our records. Please try again.",
          ];
          updatePasswordError(emailError);
        } else if (error?.response?.status === 403) {
          setEmailVerfiedError(true);
        } else {
          toast.error("An error occurred. Please try again.");
        }
        dispatch(loginFailure);
      });
  };

  const updatePasswordError = (error: any) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: error,
    }));
  };

  const removeErrors = () => {
    setErrors({
      email: [],
      password: [],
    });
    setEmailVerfiedError(false);
  };

  const handleVerifyEmail = async () => {
    const formData = form.getFieldsValue();
    if (formData.email === "") {
      toast.error("Please enter your email address");
      return;
    }
    setLoadingSpin(true);
    await AuthService.resendVerifyEmail(formData.email)
      .then((response) => {
        if (response.status) {
          setLoadingSpin(false);
          form.resetFields();
          setEmailVerfiedError(false);
          toast.success("We have sent you the verification email");
        }
      })
      .catch((error) => {
        setLoadingSpin(false);
        if (error?.response && error?.response?.status === 403) {
          toast.error("Your email is already verified");
        } else if (error?.response?.status === 404) {
          toast.error("Email not found");
        } else {
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  // const seoImage = "https://ayozat.co.uk/logo192.png";
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  return (
    // login model in main navbar
    <div className="fixed top-0 left-0 flex items-center justify-center w-full mx-auto ease-in-out rounded-lg bg-slate-600 login_modal ">
      <div className="navbar w-[500px] mx-auto my-10 p-6 rounded text-center h-auto overflow-hidden max-sm:w-[95%]">
        {/* close button in right side */}
        <div className="flex justify-end z-100">
          <HiOutlineX
            className="text-lg font-bold cursor-pointer white-text"
            onClick={clickLogin}
          />
        </div>

        {/* image section with multiple circles */}
        <div className="relative -top-[150px] red-circle-1 rounded-full w-[400px] h-[400px] mx-auto mb-10 flex items-center max-sm:w-[280px] max-sm:h-[280px] max-sm:top-[-130px]">
          <div className="red-circle-2 rounded-full w-[300px] h-[300px] mx-auto flex items-center shadow-lg max-sm:w-[240px] max-sm:h-[240px]">
            <div className="red-circle-3 rounded-full w-[200px] h-[200px] mx-auto flex items-center shadow-lg max-sm:w-[180px] max-sm:h-[180px]">
              <div className="bg-white rounded-full w-[100px] h-[100px] mx-auto flex items-center ">
                <img
                  src={Logo}
                  alt="logo"
                  className="w-16 h-16 mx-auto mb-2 "
                />
              </div>
            </div>
          </div>
        </div>

        {/* welcome */}

        {/* main topic */}
        <div className="relative mt-[-320px] max-sm:mt-[-260px]">
          <div className="text-center">
            <h1 className="mb-2 text-4xl font-bold white-text sm:text-4xl">
              Welcome!
            </h1>
            <p className="mb-4 text-sm tracking-wide gray-text">
              Already a user? Log in.
            </p>
          </div>
          <Form form={form}>
            <AntDInputField
              className=" !mb-2"
              name="email"
              type="email"
              placeholder="Email"
              size="large"
              password={false}
            />
            {errors?.email ? (
              <p className="red-text">{errors?.email}</p>
            ) : (
              <></>
            )}
            <AntDInputField
              className=" !mb-2"
              name="password"
              placeholder="Password"
              type="password"
              password={true}
            />
            {errors?.password ? (
              <p className="red-text">{errors?.password}</p>
            ) : (
              <></>
            )}
            <p className="mt-2 text-xs gray-text text-start">
              <Link to={RouteName.ForgotPassword}>
                <span className="cursor-pointer ">Forgot password?</span>
              </Link>
            </p>

            {emailVerfiedError && (
              <div className="w-full px-4 py-3 mt-3 text-left bg-red-400 rounded">
                <h4 className="mb-1 text-sm font-bold">
                  Email Verification Required
                </h4>
                <p className="mb-2 text-sm">
                  Please check your inbox for the verification link, or click
                  below to receive a new one.
                </p>
                <button
                  className="text-xs underline"
                  onClick={handleVerifyEmail}
                >
                  Get verfication email
                </button>
              </div>
            )}

            <button
              className="w-full py-4 my-4 text-lg font-bold rounded-full white-text button-red"
              type="submit"
              onClick={submit}
            >
              {loadingSpin ? (
                <LoadingSpin fontSize={20} color={"white"} />
              ) : (
                `Log In`
              )}
            </button>
          </Form>

          <p className="text-sm ">
            <div className="flex">
              <div className="grid grid-cols-7 w-[100%] mx-auto mb-3">
                <hr className="col-span-3 mt-4 opacity-50 " />
                <p className="col-span-1 text-sm text-center gray-text  border-[gray] border rounded-lg py-2 ">
                  or
                </p>
                <hr className="col-span-3 mt-4 opacity-50" />
              </div>
            </div>
            <Link to={RouteName.Register}>
              <button className="w-full py-4 my-4 text-lg font-bold rounded-full white-text border border-[white]">
                Register Now
              </button>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginModel;
