import { FaFacebookF, FaInstagramSquare, FaLinkedinIn, FaTiktok, FaTwitter, FaYoutube } from "react-icons/fa";

export const FooterSocialMedia=[ 
    {   title : "facebook",
        icon: FaFacebookF
    },
    {   title: "twitter",
        icon: FaTwitter
    },
    {
        title: "linkedin",
        icon: FaLinkedinIn
    },
    {
        title: "instagram",
        icon: FaInstagramSquare
    },
    {
        title: "youtube",
        icon: FaYoutube
    },
    {
        title: "tiktok",
        icon: FaTiktok
    }
]
