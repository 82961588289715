import React, { useEffect, useState } from "react";
import SubText from "components/shared/SubText";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ClearQueueButton from "./OtherButtons/ClearQueueButton";
import DraggablePlaylistCard from "./DraggablePlaylistCard";
import {
  changePodcastOrderOfQueue,
  changeSongOrderOfQueue,
} from "redux/AudioPlayerRedux";
import { useDispatch } from "react-redux";

function FullScreenQueue({ musics, currentSong, music, radio, podcast }: any) {
  const height = `${window.innerHeight - 262}px`;
  const dispatch = useDispatch<any>();
  // const { playlist } = useSelector((state: any) => state?.audio);
  const [items, setItems] = useState<any>(musics);

  useEffect(() => {
    if (music) {
      dispatch(changeSongOrderOfQueue(items));
    } else if (podcast) {
      dispatch(changePodcastOrderOfQueue(items));
    }
  }, [items]);

  useEffect(() => {
    setItems(musics);
  }, [musics]);

  const moveItem = (fromIndex: number, toIndex: number) => {
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setItems(updatedItems);
  };
  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <SubText text={"Listening Queue"} className={""} />
        <ClearQueueButton spinSize={20} text={"Clear Queue"} />
      </div>
      <div
        className={`!overflow-y-scroll scrollbar-hide relative`}
        style={{
          height: `${height}`,
        }}
      >
        <DndProvider backend={HTML5Backend}>
          {items.map((song: any, index: number) => (
            <DraggablePlaylistCard
              index={index}
              song={song}
              currentSong={currentSong}
              key={index}
              QueueCount={items?.length}
              moveItem={moveItem}
              music={music}
              radio={radio}
              podcast={podcast}
            />
          ))}
        </DndProvider>
      </div>
    </>
  );
}

export default FullScreenQueue;
