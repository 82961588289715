import { RouteName } from "constant/routeNames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PayperviewLazyLoadHero from "./PayperviewLazyLoadHero";
import { formatToLocalDateString } from "utils/timeFunctions";

function HeroMainSlider({ value }: any) {
  const [hover, setHover] = useState<boolean>(false);
  console.log(value);
  return (
    <>
      {value?.is_coming_soon === 0 ? (
        <>
          <Link
            to={RouteName.PayperviewDetailsRoute.replace(":slug", value?.slug)}
          >
            <div className="relative ">
              <div className="w-full rounded-lg h-[65vh] mb-5 white-text max-sm:w-full overflow-hidden mx-auto max-sm:h-[250px] max-xl:h-[350px] max-2xl:h-[420x] 2xl:h-[600px]">
                <div className="flex justify-center">
                  <PayperviewLazyLoadHero
                    imgSrc={value?.featured_thumbnail}
                    imgAlt={"ppv"}
                    imgHeight={"100%"}
                    imgWidth={"120%"}
                    className={"cover rounded-md"}
                  />
                </div>

                <div className="absolute w-[30%] right-0 p-1 px-2 text-white uppercase top-2 bg-gradient-to-l from-black to-black/0 ">
                  <p className="text-[12px] text-right ">
                    {value?.featured_caption}
                  </p>
                </div>

                {/* card inside content */}
                <div className="absolute inset-x-0 bottom-0 flex justify-between p-2 bg-gradient-to-t from-black to-black/30 h-[100px] backdrop-blur-lg">
                  <div className="flex items-center justify-between w-full h-full">
                    <div>
                      <p className="text-[15px] text-white ml-2 font-[700]">
                        {value?.featured_title}
                      </p>
                      <p className="text-[12px] text-[#b0b0b0] ml-2 font-[500]">
                      {formatToLocalDateString(value?.event_formatted_date)}
                      </p>
                    </div>
                    <div>
                      {value?.streaming_status === "ended" ? (
                        <button className="flex justify-center  px-3 py-2 bg-red-600 rounded-full max-sm:w-full max-lg:w-[90%] items-center hover:bg-transparent hover:border ">
                          <h2 className="text-[15px] text-white  my-auto mx-3">
                            Reserve Now
                          </h2>
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {/* <div className="flex items-center">
              <div
                className={`flex items-center justify-center w-12 h-12 rounded-full shadow-lg cursor-pointer ${
                  hover ? "bg-red-600" : `bg-stone-400`
                } duration-300 ease-in-out`}
              >
                <Link
                  to={RouteName.PayperviewDetailsRoute.replace(
                    ":slug",
                    value?.slug
                  )}
                >
                  <FaPlay className="w-5 h-5 text-white " />
                </Link>
                </div>
              </div>  */}
                </div>
              </div>
            </div>
          </Link>
        </>
      ) : (
        <>
          <div className="relative ">
            <div className="w-full rounded-lg h-[65vh] mb-5 white-text max-sm:w-full overflow-hidden mx-auto max-sm:h-[250px] max-xl:h-[350px] max-2xl:h-[420x] 2xl:h-[600px]">
              <div className="flex justify-center">
                <PayperviewLazyLoadHero
                  imgSrc={value?.featured_thumbnail}
                  imgAlt={"ppv"}
                  imgHeight={"100%"}
                  imgWidth={"120%"}
                  className={"cover rounded-md"}
                />
              </div>

              <div className="absolute w-[30%] right-0 p-1 px-2 text-white uppercase top-2 bg-gradient-to-l from-black to-black/0 ">
                <p className="text-[12px] text-right ">
                  {value?.featured_caption}
                </p>
              </div>

              {/* card inside content */}
              <div className="absolute inset-x-0 bottom-0 flex justify-between p-2 bg-gradient-to-t from-black to-black/30 h-[100px] backdrop-blur-lg">
                <div className="flex items-center justify-between w-full h-full">
                  <div>
                    <p className="text-[15px] text-white ml-2 font-[700]">
                      {value?.featured_title}
                    </p>
                    <p className="text-[12px] text-[#b0b0b0] ml-2 font-[500]">
                      {value?.show_time}
                    </p>
                  </div>
                  <div>
                    {value?.streaming_status === "ended" ? (
                      <button className="flex justify-center  px-3 py-2 bg-red-600 rounded-full max-sm:w-full max-lg:w-[90%] items-center hover:bg-transparent hover:border ">
                        <h2 className="text-[15px] text-white  my-auto mx-3">
                          Reserve Now
                        </h2>
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* <div className="flex items-center">
              <div
                className={`flex items-center justify-center w-12 h-12 rounded-full shadow-lg cursor-pointer ${
                  hover ? "bg-red-600" : `bg-stone-400`
                } duration-300 ease-in-out`}
              >
                <Link
                  to={RouteName.PayperviewDetailsRoute.replace(
                    ":slug",
                    value?.slug
                  )}
                >
                  <FaPlay className="w-5 h-5 text-white " />
                </Link>
                </div>
              </div>  */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default HeroMainSlider;
