import SingleEpisode from "./SingleEpisode";

function PPVEpisodes({ videoDetails, loading, event }: any) {
  return (
    <>
      {!loading && videoDetails ? (
        <>
          <div className="w-full overflow-y-scroll scrollbar-hide ">
            {videoDetails?.map((e: any, i: number) => (
              <SingleEpisode
                data={e}
                key={i}
                // id={videoDetails?.id}
                event={event}
                //   season={selectedSeason.id}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="w-[60px]  h-[20px] mr-2 bg-[#1a1919] animate-pulse max-sm:w-full"></div>
          <div className="flex items-center my-8">
            {Array.from({ length: 3 }, (_, i) => (
              <div
                className="w-[80px]  h-[40px] mr-2 bg-[#1a1919] animate-pulse max-sm:w-full"
                key={i}
              ></div>
            ))}
          </div>
          {Array.from({ length: 5 }, (_, i) => (
            <div
              className="w-[250px]  h-[45px] my-2 bg-[#1a1919] animate-pulse max-sm:w-full"
              key={i}
            ></div>
          ))}
        </>
      )}
    </>
  );
}

export default PPVEpisodes;
