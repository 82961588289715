import LoadingSpin from "components/Loading spins/LoadingSpin";
import { RouteName } from "constant/routeNames";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setLiveTVSuccess, setVideoStart } from "redux/VideoPlayerRedux";
import LiveTVService from "services/liveTv/LiveTVService";
import TvGuide from "./liveTVPlayerPage/TvGuide";
import VideoPlayerInsideLiveTV from "./liveTVPlayerPage/VideoPlayerInsideLiveTV";
import YouAreWatching from "./liveTVPlayerPage/YouAreWatching";
import LazyLoadImg from "components/LazyLoadImg";
import TvChannelSkeleton from "components/skeleton/TvChannelSkeleton";
import TvChannelSmallCardSkeleton from "components/skeleton/TvChannelSmallCardSkeleton";
import BannerSkeleton from "components/skeleton/BannerSkeleton";
import { Adsense } from "@ctrl/react-adsense";
import GeneralServices from "services/General/GeneralServices";

function LiveTvHomeWithPlayer() {
  const { currentLiveTv } = useSelector((state: any) => state?.video);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { slug } = useParams();
  const [banners, setBanners] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState("");
  const [dataList, setDataList] = useState<any[]>([]);
  const [gAdSlot, setgAdSlot] = useState<any>();
  const [gAdSlotHoriz, setgAdSlotHoriz] = useState<any>();
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;
  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_livetv_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSquSlot" && item?.value !== null
          );
          const filteredHoriz = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          setgAdSlotHoriz(filteredHoriz[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    let cleanedSlug = slug && slug.endsWith(".html") ? slug.slice(0, -5) : slug;

    const loadChannelData = async () => {
      setLoading(true);
      setTimeout(async () => {
        await LiveTVService.getCHannelDetailsBySlug(cleanedSlug)
          .then((res) => {
            dispatch(setVideoStart());

            const obg = {
              ...res.data,
              vast_tag_url:
                res.data?.vast_tag_url &&
                res.data?.vast_tag_url.replace(/&amp;/g, "&"),
            };
            console.log(obg);
            // let c: any = convertFromGetAllChannels(res.data);
            dispatch(setLiveTVSuccess(obg));
            setLoading(false);
          })
          .catch((err) => {
            toast.error("Content Not Found");
            setError("Content Not Found");
            navigate(RouteName.pageNotFound);
          });
      }, 1000);
    };
    loadChannelData();
  }, [slug]);

  useEffect(() => {
    // setPlayerIsActive(isActive);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // const [published, setPublished] = useState(true);

  //Banners
  useEffect(() => {
    LiveTVService.getTVChannelBanner().then((res) => {
      // console.log("banner", res.data);
      setBanners(res.data);
    });
  }, []);

  const navigateBack = () => {
    navigate(RouteName.Home);
  };
  return (
    <>
      <Helmet>
        <title>
          {currentLiveTv && currentLiveTv?.seo_title
            ? `${currentLiveTv && currentLiveTv?.seo_title}`
            : `${currentLiveTv && currentLiveTv?.name}`}
        </title>
        <meta
          name="title"
          content={currentLiveTv && currentLiveTv?.seo_title}
        />
        <meta
          name="description"
          content={currentLiveTv && currentLiveTv?.meta_description}
        />
        <meta
          name="keywords"
          content={currentLiveTv && currentLiveTv?.focus_keyword}
        />
        <meta
          name="keywords"
          content={currentLiveTv && currentLiveTv?.focus_keyword}
        />

        <link rel="canonical" href={`${FE_URL}/live-tv/${currentLiveTv && currentLiveTv?.slug}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={currentLiveTv && currentLiveTv?.name}
        />
        <meta
          name="twitter:description"
          content={currentLiveTv && currentLiveTv?.meta_description}
        />
        <meta
          name="twitter:image"
          content={currentLiveTv && currentLiveTv?.thumbnail}
        />

        <meta
          property="og:title"
          content={currentLiveTv && currentLiveTv?.name}
        />
        <meta
          property="og:description"
          content={currentLiveTv && currentLiveTv?.meta_description}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${FE_URL}/live-tv/${currentLiveTv && currentLiveTv?.slug}`}
        />
        <meta
          property="og:image"
          content={currentLiveTv && currentLiveTv?.thumbnail}
        />
      </Helmet>

      {banners?.length > 0 ? (
        <>
          <div className="flex justify-between max-sm:block ">
            <div className="flex justify-center w-[90%] sm:-ml-28 max-sm:w-full mb-5 max-sm:ml-0 max-sm:block  ">
              {/* <CustomVideoPlayer cdn={url} /> */}
              {/* {VideoPlayerInsideLiveTV()} */}

              {!loading ? (
                <VideoPlayerInsideLiveTV />
              ) : (
                <div className="flex justify-center max-sm:h-[200px]">
                  <div className="flex items-center ">
                    <LoadingSpin fontSize={"60px"} color={"#f30543"} />
                  </div>
                </div>
              )}
              <div className=" sm:hidden max-sm:mt-5">
                <YouAreWatching
                  currentLiveTv={currentLiveTv}
                  dataList={dataList}
                />
              </div>
            </div>
            <div className="w-[35%] ml-[-50px] max-sm:ml-0 flex-col flex  justify-between max-sm:w-full max-sm:flex max-sm:gap-3 max-am:block ">
              <div className="flex flex-col">
                {/* Description and Details */}
                {!loading ? (
                  <div className="w-[88%] p-3 border border-gray-500 rounded-lg flex-col h-auto max-sm:w-full cursor-default">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <div className="w-10 h-10 overflow-hidden rounded-lg">
                          <LazyLoadImg
                            imgSrc={currentLiveTv?.thumbnail}
                            imgAlt={"Card pic"}
                            imgHeight={"40px"}
                            imgWidth={"40px"}
                            style={{
                              backgroundSize: "cover",
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                        <p className="text-white ">
                          {currentLiveTv?.channel_number}
                        </p>
                      </div>

                      <p className="text-[12px] text-red-600">
                        {currentLiveTv?.category}
                      </p>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: currentLiveTv?.description,
                      }}
                      className="text-white text-[12px] font-normal w-[full] mt-5 max-sm:w-full"
                    />
                  </div>
                ) : (
                  <>
                    <TvChannelSmallCardSkeleton />
                  </>
                )}
              </div>

              <div className="flex justify-end mb-[20px] bg-[#d8cccc] w-[85%] max-sm:mt-0 max-sm:w-full max-sm:my-3">
                {/* <Adsense
                  client="ca-pub-5773929890067376"
                  slot="9259680441"
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    height: "90px",
                    justifyContent: "center",
                  }}
                  layout="in-article"
                  data-full-width-responsive="true"
                  data-ad-format="auto"
                  // format="fluid"
                /> */}
              </div>

              {/* </div>
            <iframe id='a72c3cfb' name='a72c3cfb' src='http://ayotising.com/www/delivery/afr.php?zoneid=32&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='160' height='600' allow='autoplay'><a href='http://ayotising.com/www/delivery/ck.php?n=a7f3ae02&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='http://ayotising.com/www/delivery/avw.php?zoneid=32&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a7f3ae02' alt='' /></a></iframe>
          <div> */}
            </div>
          </div>
          <div className="flex  w-[65%] items-center justify-center max-sm:hidden">
            <YouAreWatching currentLiveTv={currentLiveTv} dataList={dataList} />
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between max-sm:block ">
            <div className="flex justify-center w-[90%] sm:-ml-28 max-sm:w-full mb-5 max-sm:ml-0 max-sm:block ">
              {/* <CustomVideoPlayer cdn={url} /> */}
              {/* {VideoPlayerInsideLiveTV()} */}

              {!loading ? (
                <VideoPlayerInsideLiveTV />
              ) : (
                <div className="flex items-center justify-center max-md:mt-32 md:max-h-screen ">
                  <div className="flex items-center max-sm:h-[200px] mt-[-100px]">
                    <LoadingSpin fontSize={"60px"} color={"#f30543"} />
                  </div>
                </div>
              )}
              <div className=" sm:hidden max-sm:mt-5">
                <YouAreWatching
                  currentLiveTv={currentLiveTv}
                  dataList={dataList}
                />
              </div>
            </div>
            <div className="w-[35%] ml-[-50px] max-sm:ml-0 flex-col flex  max-sm:w-full max-sm:flex max-sm:gap-3  ">
              <div className="flex flex-col">
                {/* Description and Details */}
                {!loading ? (
                  <div className="w-[88%] p-3 border border-gray-500 rounded-lg flex-col cursor-default h-auto max-sm:w-full ">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <div className="w-10 h-10 overflow-hidden rounded-lg">
                          <LazyLoadImg
                            imgSrc={currentLiveTv?.thumbnail}
                            imgAlt={"Card pic"}
                            imgHeight={"40px"}
                            imgWidth={"40px"}
                            style={{
                              backgroundSize: "cover",
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                        <p className="text-white ">
                          {currentLiveTv?.channel_number}
                        </p>
                      </div>

                      <p className="text-[12px] text-red-600">
                        {currentLiveTv?.category}
                      </p>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: currentLiveTv?.description,
                      }}
                      className="text-white text-[12px] font-normal w-[full] mt-5 max-sm:w-full"
                    />
                  </div>
                ) : (
                  <>
                    <TvChannelSmallCardSkeleton />
                  </>
                )}
              </div>
              <div className="flex justify-center mt-[40px] mb-[20px]  w-[88%] max-sm:mt-0 max-sm:w-full max-sm:my-3">
                {/* <p className="text-[12px] text-red-600">
                        {currentLiveTv?.category}
                      </p> */}
                {/* {gAdSlot && (
                <Adsense
                  client="ca-pub-5773929890067376"
                  slot={gAdSlot}
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    height: "90px",
                    justifyContent: "center",
                  }}
                  layout="in-article"
                  data-full-width-responsive="true"
                  data-ad-format="auto"
                  // format="fluid"
                />
              )} */}
              </div>
            </div>
          </div>
          <div className="max-sm:hidden flex  w-[65%] items-center justify-center">
            <YouAreWatching currentLiveTv={currentLiveTv} dataList={dataList} />
          </div>
        </>
      )}

      <div className="w-[65%] max-sm:w-full  items-center justify-center">
        {gAdSlotHoriz && (
          <Adsense
            client="ca-pub-5773929890067376"
            slot={gAdSlotHoriz}
            style={{
              display: "flex",
              marginBottom: "20px",
              marginTop: "20px",
              height: "90px",
              justifyContent: "center",
            }}
            format="horizontal"
            data-full-width-responsive="true"
            // format="fluid"
          />
        )}
      </div>
      {/* shoe playing now channel */}

      <TvGuide setDataList={setDataList} />
    </>
  );
}

export default LiveTvHomeWithPlayer;