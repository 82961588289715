import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserProfileService from "services/UserProfileService";
import UserProfileButtonSection from "./shared/UserProfileButtonSection";
import UserProfileHeroSection from "./shared/UserProfileHeroSection";
import LikeService from "services/music/LikeService";
import { useSelector } from "react-redux";
import UserProfileSkeleton from "components/skeleton/UserProfileSkeleton";
import UserProfileButtonSectionSkeleton from "components/skeleton/UserProfileButtonSectionSkeleton";
import PrivateAccount from "./shared/PrivateAccount";
import { Helmet } from "react-helmet-async";
import PlaylistService from "services/music/PlaylistService";
import PayperviewService from "services/payperview/PayperviewServie";
import ProfileHeroSection from "pages/newProfilePage/shared/ProfileHeroSection";

function UserProfileHome({}: any) {
  const { username } = useParams();
  const [user, setUser] = useState<any>();
  const [allData, setAllData] = useState<any>();
  // const [haveData, setHaveData] = useState<boolean>(false);
  const [userPlaylists, setUserPlaylists] = useState<any>([]);
  const [isLoadingPlaylist, setIsLodingPlaylist] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [purchased, setPurchased] = useState<any>([]);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    setIsLoading(true);
    if (username) {
      UserProfileService.getUserByUserName(username).then((res: any) => {
        setIsLoading(false);
        setUser(res.user);
        // console.log("user", user);
      });
    }
  }, [username]);

  useEffect(() => {
    // console.log("aaa", user?.id);
    if (user && user?.id === loggedInUser?.id) {
      setAllData(favorite);
    } else {
      if (user) {
        LikeService.getFavoriteById(loggedInUser?.id, user?.id)
          .then((res) => {
            // console.log(res);
            setAllData(res.profile);
          })
          .catch((e: any) => {
            // console.log(e);
            setAllData(null);
          });
      }
    }
  }, [user]);

  useEffect(() => {
    setIsLodingPlaylist(true);
    setIsLoading(true);
    if (loggedInUser) {
      PlaylistService.getUserPlaylists().then((res) => {
        setIsLoading(false);
        setIsLodingPlaylist(false);
        setUserPlaylists(res.data.data);
        // if (res.data.data.length > 0) {
        //   setHaveData(true);
        // }
      });
    }
  }, [loggedInUser]);

  useEffect(() => {
    setIsLoading(true);
    PayperviewService.getPurchasedEvents(loggedInUser?.id)
      .then((res) => {
        // console.log("event", res.data);
        setIsLoading(false);
        setPurchased(res.data);
      })
      .catch((e: any) => {
        console.log("getPurchasedEvents ", e);
      });
  }, [loggedInUser]);

  return (
    <>
      <Helmet>
        <title>
          {/* {`${name} - Ayozat`} */}
          {user?.name ? `${user?.name} - AYOZAT` : "AYOZAT"}
        </title>
        <meta name="title" content={`${user?.name} - AYOZAT`} />
        <meta name="description" content={user?.username} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${user?.name} - AYOZAT`} />
        <meta name="twitter:description" content={user?.username} />
        <meta name="twitter:image" content={user?.artwork_url} />
        <meta name="twitter:site" content="AYOZAT" />
        <meta
          name="twitter:url"
          content={`${FE_URL}/profile/${user?.username}`}
        />

        <meta property="og:title" content={`${user?.name} - AYOZAT`} />
        <meta property="og:image" content={user?.artwork_url} />
        <meta property="og:description" content={user?.username} />
        <meta
          property="og:url"
          content={`${FE_URL}/profile/${user?.username}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <>
        {!isLoading && <> {user && <ProfileHeroSection user={user} />}</>}
        {isLoading && <UserProfileSkeleton />}
      </>
      <>
        {!isLoading && (
          <>
            {" "}
            {user && (
              <>
                {user?.id === loggedInUser?.id ? (
                  <UserProfileButtonSection
                    user={user}
                    favorite={allData}
                    userPlaylist={userPlaylists}
                    isLoadingPlaylist={isLoadingPlaylist}
                    ppvEvent={purchased}
                  />
                ) : (
                  <>
                    {/* {user?.activity_privacy === 0 ? ( */}
                    <UserProfileButtonSection
                      user={user}
                      favorite={allData}
                      isLoadingPlaylist={isLoadingPlaylist}
                      // haveData={haveData}
                      userPlaylist={userPlaylists}
                      ppvEvent={purchased}
                    />
                    {/* ) : ( */}
                    {/* <PrivateAccount /> */}
                  </>
                )}
              </>
            )}
          </>
        )}
        {isLoading && <UserProfileButtonSectionSkeleton />}
      </>
    </>
  );
}

export default UserProfileHome;
