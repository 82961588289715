const LeftSideSkeleton = () => {
  return (
    <div className="w-full ml-4 text-left mt-15 max-sm:w-full ">
      {" "}
      <h1 className="h-[20px] animate-pulse  w-[90%] bg-[#1a1919] animate-pulse"></h1>
      <div className="">
        <h1 className="h-[15px] mt-5 w-[20%] bg-[#1a1919] animate-pulse"></h1>
      </div>
      <div className="mt-5">
        <h1 className="h-[15px] my-2  w-[40%] bg-[#1a1919] animate-pulse max-sm:w-[50%]"></h1>
      </div>
      <div className="mt-6 max-sm:w-full ">
        <h1 className="text-[14px] my-2  w-[90%] bg-[#1a1919] animate-pulse h-[100px] "></h1>
      </div>
    </div>
  );
};

export default LeftSideSkeleton;