import React, { useState } from "react";
import GIFPicker from "./Media/GIFPicker";

const MediaWindow = ({ setInputValue, setShowMedia }: any) => {
  const [activeTab, setActiveTab] = useState("GIFs");

  const renderContent = () => {
    switch (activeTab) {
      case "GIFs":
        return (
          <GIFPicker
            setInputValue={setInputValue}
            setShowMedia={setShowMedia}
          />
        );
      case "Images":
        return <div className="text-[12px]">Images will be displayed here</div>;
      case "Videos":
        return <div className="text-[12px]">Videos will be displayed here</div>;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="w-full mx-auto border-[1px] border-[#303030] rounded-lg shadow-2xl ">
        <div className="flex justify-around overflow-hidden rounded-lg bg-[#131313]">
          <button
            className={`py-3 px-4 focus:outline-none w-full  ${
              activeTab === "GIFs"
                ? "border-b-2 border-[#f30543] text-[#f30543] font-light rounded-lg"
                : ""
            }`}
            onClick={() => setActiveTab("GIFs")}
          >
            GIFs
          </button>
          {/* <button
            className={`py-3 px-4 focus:outline-none w-full ${
              activeTab === "Images"
                ? "border-b-2 border-[#f30543] text-[#f30543] font-light rounded-lg"
                : ""
            }`}
            onClick={() => setActiveTab("Images")}
          >
            Images
          </button> */}
          {/* <button
            className={`py-3 px-4 focus:outline-none w-full ${
              activeTab === "Videos"
                ? "border-b-2 border-[#f30543] text-[#f30543] font-light rounded-lg"
                : ""
            }`}
            onClick={() => setActiveTab("Videos")}
          >
            Videos
          </button> */}
        </div>
        <div className="p-4 text-center bg-[#131313] h-[400px]">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default MediaWindow;
