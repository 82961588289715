import { Badge, Tooltip } from "antd";
import { useContext, useState } from "react";
import { MdOutlineChat } from "react-icons/md";
import AntDDrawer from "components/Drawer/AntDDrawer";
import UserChatDrawer from "pages/UserChat/shared/UserChatDrawer";
import { ChatContext } from "context/ChatContext";

const UserChatNavIcon = () => {
  const {
    unreadChats
  } = useContext(ChatContext);
  const [openChatDrawer, setOpenChatDrawer] = useState<boolean>(false);
  const showDrawer = () => {
    setOpenChatDrawer(true);
  };
  const closeDrawer = () => {
    setOpenChatDrawer(false);
  };
  return (
    <>
      <Tooltip title="Chat">
        <div
          className="flex items-center text-white cursor-pointer text-[25px] max-sm:ml-4"
          onClick={showDrawer}
        >
          <Badge count={unreadChats.length} overflowCount={10} size="default">
            <MdOutlineChat className="text-2xl text-white" />
          </Badge>
        </div>
      </Tooltip>
      {/* drawer */}
      <AntDDrawer
        onClose={closeDrawer}
        open={openChatDrawer}
        children={
          <UserChatDrawer chatFromProfile={false} onClose={closeDrawer} />
        }
      />
    </>
  );
};

export default UserChatNavIcon;
