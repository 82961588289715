import React, { useEffect, useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import PaymentService from "services/PaymentService";
import CheckoutForm from "./CheckoutForm";
import { useLocation, useNavigate } from "react-router-dom";
import PpvCheckoutImage from "components/skeleton/PpvCheckoutImage";
import { Helmet } from "react-helmet-async";

const stripeKey: string | undefined = process.env.REACT_APP_STRIPE_PUB_KEY;

let stripePromise: Stripe | PromiseLike<Stripe | null> | null;
if (stripeKey) {
  stripePromise = loadStripe(stripeKey);
} else {
  console.error("Stripe public key is not defined.");
}

const PayPerViewCheckout = ({ setIsActive }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const ppv_event = location.state.ppv_event;
  const [clientSecret, setClientSecret] = useState("");

  const [isLoading, setIsLoading] = useState<any>(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState<any>(false);
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  const getStripePaymentIntense = async () => {
    setIsLoading(true);
    const data = {
      ppv_event_id: ppv_event?.id,
    };
    try {
      const response = await PaymentService.getPaymentIntense(data);
      setClientSecret(response?.clientSecret);
      setIsLoading(false);
    } catch (error) {
      console.error("Error occurred during payment intense:", error);
    }
  };

  useEffect(() => {
    getStripePaymentIntense();
    console.log("ppv_event", ppv_event);
  }, []);

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: "night",
      variables: {
        colorPrimary: "#f30544",
      },
    },
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  const finalServiceFee =
    parseFloat(ppv_event?.price) * (parseFloat(ppv_event?.service_fee) / 100);

  // console.log(finalServiceFee + "Service Fee");

  const finalDiscount =
    parseFloat(ppv_event?.price) * (parseFloat(ppv_event?.discount) / 100);

  // console.log(finalDiscount + "Discount Fee");

  // const withService = parseFloat(ppv_event?.price) + finalServiceFee;

  const totalFee =
    parseFloat(ppv_event?.price) + finalServiceFee - finalDiscount;

  return (
    <>
      <Helmet>
        <title>
          Check Out -{" "}
          {ppv_event?.title ? `${ppv_event?.title}` : "AYOZAT Payperview"}
        </title>
        <meta name="description" content={ppv_event?.meta_description} />

        <meta name="keywords" content={ppv_event?.focus_keyword} />
        <meta property="og:title" content={ppv_event?.title} />
        <meta property="og:description" content={ppv_event?.meta_description} />

        <meta property="og:image" content={ppv_event?.bannerImage} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={ppv_event?.title} />
        <meta
          name="twitter:description"
          content={ppv_event?.meta_description}
        />
        <meta name="twitter:image" content={ppv_event?.bannerImage} />
        <meta name="twitter:site" content="AYOZAT Payperview" />
      </Helmet>
      <div className="flex items-center justify-center h-[90vh] mb-40 max-sm:h-auto max-sm:mb-0 ">
        <div className="w-10/12 h-auto px-8 py-10 mx-auto bg-gray-900 rounded max-sm:w-full max-lg:h-[90vh] max-lg:w-[97%] max-lg:m-0 max-sm:h-auto  ">
          <button
            className="flex items-center justify-center w-10 h-10 text-2xl text-gray-500 bg-white rounded-full cursor-pointer top-6 z-100 left-6 hover:text-gray-700 mb-7 "
            onClick={() => {
              navigate(-1);
            }}
          >
            <HiArrowLeft className="text-black" />
          </button>

          <div className="grid grid-cols-2 gap-10 max-sm:grid-cols-1 max-lg:grid-cols-1">
            <div>
              <h1 className="text-[21px] font-bold text-gray-400">
                {ppv_event?.title}
              </h1>
              <div>
                <h1 className="text-[27px] font-bold text-gray-300">
                  £{totalFee.toFixed(2)}
                </h1>
              </div>
              <div className="mt-4">
                <img
                  src={ppv_event?.bannerImage}
                  alt={ppv_event?.title}
                  className="object-cover w-11/12 mt-4 rounded-lg max-lg:w-full"
                />
              </div>
            </div>

            {!isLoading && (
              <div>
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm ppv_event={ppv_event} />
                  </Elements>
                )}
              </div>
            )}
            {isLoading && <PpvCheckoutImage />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayPerViewCheckout;
