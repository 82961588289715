import FeaturePages from "layout/FeaturePages";
import React from "react";
import { Route, Routes } from "react-router-dom";
import PayPerViewHome from "./PayPerView/PayPerViewHome";
import PayPerViewPlayer from "./PayPerView/PayPerViewPlayer";
import { RouteName } from "constant/routeNames";
import DiscoverByPayperviewCategory from "./PayPerView/DiscoverByPayperviewCategory";
import PayperviewDetailsPage from "./PayPerView/PayperviewDetailsPage";
import PayperviewSuccesPage from "./PayPerView/PayperviewSuccesPage";
import SeeAllPayPerCategories from "./PayPerView/SeeAllPayPerCategories";
import PaidSubscriptionsPage from "./PayPerView/PaidSubscriptionsPage";
import PayPerViewCheckout from "./PayPerView/PayPerViewCheckout";
import RequireAuth from "routes/RequireAuth";
import UpcomingSeeAllPage from "./PayPerView/UpcomingSeeAllPage";
import PastEventSeeAllPage from "./PayPerView/PastEventSeeAllPage";
import StreamingSeeAllPage from "./PayPerView/StreamingSeeAllPage";
import SeeAllPurchasedPage from "./PayPerView/SeeAllPurchasedPage";
import PPVReplayVideoPlayerPage from "./PayPerView/PPVReplayVideoPlayerPage";

const PayPerView = ({ handleClick, isActive, setIsActive }: any) => {
  return (
    <div>
      <Routes>
        <Route
          path=""
          element={
            <FeaturePages
              Content={PayPerViewHome}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Pay Per View"}
            />
          }
        />
        <Route
          path={RouteName.PayperviewDiscoverByGenreId}
          element={
            <FeaturePages
              Content={DiscoverByPayperviewCategory}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Pay Per View"}
            />
          }
        />
        <Route
          path={RouteName.PayperviewDetails}
          element={
            <FeaturePages
              Content={PayperviewDetailsPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Pay Per View"}
            />
          }
        />
        <Route
          path={RouteName.SeeAllPayperviewCategories}
          element={
            <FeaturePages
              Content={SeeAllPayPerCategories}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Pay Per View"}
            />
          }
        />
        <Route
          path={RouteName.upcomingSeeAllPage}
          element={
            <FeaturePages
              Content={UpcomingSeeAllPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Pay Per View"}
            />
          }
        />
        <Route
          path={RouteName.purchasedSeeAllPage}
          element={
            <FeaturePages
              Content={SeeAllPurchasedPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Pay Per View"}
            />
          }
        />
        <Route
          path={RouteName.pastSeeAllPage}
          element={
            <FeaturePages
              Content={PastEventSeeAllPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Pay Per View"}
            />
          }
        />
        <Route
          path={RouteName.streamingSeeAllPage}
          element={
            <FeaturePages
              Content={StreamingSeeAllPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Pay Per View"}
            />
          }
        />
        <Route
          path={RouteName.PayperviewSuccesPage}
          element={
            <FeaturePages
              Content={PayperviewSuccesPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Pay Per View"}
            />
          }
        />
        <Route
          path={RouteName.PaidSubscriptionsPage}
          element={
            <FeaturePages
              Content={PaidSubscriptionsPage}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Pay Per View"}
            />
          }
        />
        <Route
          path={RouteName.PayPerViewCheckoutPath}
          element={
            <FeaturePages
              Content={PayPerViewCheckout}
              handleClick={handleClick}
              isActive={isActive}
              setIsActive={setIsActive}
              title={"Checkout"}
            />
          }
        />

        <Route
          path={RouteName.PayPerViewPlayer}
          element={
            <>
              <RequireAuth component={<PayPerViewPlayer />} />
            </>
          }
        />

        <Route
          path={RouteName.PayPerViewReplayPlayer}
          element={
            <>
              <RequireAuth component={<PPVReplayVideoPlayerPage />} />
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default PayPerView;
