import LazyLoadImg from "components/LazyLoadImg";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdVerified } from "react-icons/md";
import verify from "Assets/Images/verify.png";
import { useSelector } from "react-redux";
import FollowArtist from "components/shared/FollowArtist";

const ArtistDetails = (props: any) => {
  const { artwork_url, name, song_count, album_count, slug, verified } =
    props.value;
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;
  const navigate = useNavigate();
  // console.log(props);

  return (
    <>
      <Helmet>
        <title>
          {/* {`${name} - AYOZAT`} */}
          {name ? `${name} - AYOZAT Music` : "AYOZAT"}
        </title>
        <meta name="title" content={`${name} - AYOZAT Music`} />
        <meta name="description" content={name} />

        <link rel="canonical" href={`${FE_URL}/music/artist/${slug}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${name} - AYOZAT Music`} />
        <meta name="twitter:description" content={name} />
        <meta name="twitter:image" content={artwork_url} />
        <meta name="twitter:site" content="AYOZAT" />
        <meta name="twitter:url" content={`${FE_URL}/music/artist/${slug}`} />

        <meta property="og:title" content={`${name} - AYOZAT Music`} />
        <meta property="og:image" content={artwork_url} />
        <meta property="og:description" content={name} />
        <meta property="og:url" content={`${FE_URL}/music/artist/${slug}`} />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="relative h-auto ">
        <div
          className="relative h-auto mb-30"
          style={{
            backgroundImage: `url(${artwork_url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="p-3 full-backdrop-bottom">
            <div>
              <button
                className="w-10 h-10 mt-5 bg-white rounded-full max-sm:ml-[8px]"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <AiOutlineArrowLeft className="ml-3" />
              </button>
            </div>
            <div className="flex items-end max-sm:h-[250px]  ">
              <div className="mt-5 max-sm:hidden h-[224px] overflow-hidden">
                {/* <img
                  src={artwork_url}
                  alt=""
                  className="rounded-md album-page-image max-sm:h-[20%]"
                /> */}
                <LazyLoadImg
                  imgSrc={artwork_url}
                  imgAlt={"first artist"}
                  imgHeight={"224px"}
                  imgWidth={"224px"}
                  className=" max-sm:scale-50"
                  style={{
                    borderRadius: "6px",
                    // width: "24px",
                    // height: "24px",
                    backgroundSize: "cover",
                    marginRight: "8px",
                  }}
                />
              </div>
              <div className="ml-10 max-sm:ml-5 ">
                <p className="text-base font-medium fafafa max-sm:text-sm">
                  Artist
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-3">
                    <h1
                      className="text-6xl font-bold font-secular fafafa max-sm:text-[30px] max-sm:w-[65%]"
                      dangerouslySetInnerHTML={{ __html: name }}
                    ></h1>
                    {verified ? (
                      <div className="flex items-center justify-center">
                        <img
                          src={verify}
                          alt=""
                          className="mx-3 mt-2 place-self-center verify-blue-tik max-sm:mx-2"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                {/* <p className="text-[12px] text-[#7B9DB4]">{description}</p> */}
                <div className="flex flex-row gap-5 max-sm:mt-3">
                  <p className="text-lg font-semibold Color7B9DB4 max-sm:text-sm">
                    {song_count} songs
                  </p>
                  <p className="text-lg font-semibold Color7B9DB4 max-sm:text-sm">
                    {album_count} Albums
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtistDetails;
