import { useContext, useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { formatDateTime } from "utils/timeFunctions";
import img from "../../../Assets/Images/ProfileAvatar.png";
import { Badge } from "antd";
import { ChatContext } from "context/ChatContext";
import { IUnreadChat } from "hooks/useUserChatWebSocket";

function ChatUser({ chatData, setSelectedChat, popUp }: any) {
  const { unreadChats, setUnreadChats, messages } = useContext(ChatContext);
  const [recieverName, setRecieverName] = useState("Unknown User");
  const [userData, setUserData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const handleClick = () => {
    setSelectedChat(chatData);

    setUnreadChats((prevChats: IUnreadChat[]) => {
      return prevChats.filter((chat: IUnreadChat) => {
        return chat.chatId != chatData.id;
      })
    });
  };
  
  useEffect(()=>{
    const chat: IUnreadChat = unreadChats.find((chat: IUnreadChat) => chat.chatId == chatData?.id);
    setUnreadCount(chat ? chat.count : 0);
  }, [messages])

  useEffect(() => {
    if (chatData.receiver) {
      setUserData(chatData.receiver);
      setRecieverName(getStringAccordingToLength(chatData.receiver?.name, 15));
    }
    // else {
    //   setIsLoading(true);
    //   const userId = chatData?.chat_name?.split("-")[1];
    //   // console.log("userId : ", userId);
    //   UserProfileService.getUserById(userId).then((res) => {
    //     // console.log("res : ", res?.profile);
    //     setUserData(res?.profile);
    //     setRecieverName(getStringAccordingToLength(res?.profile?.name, 15));
    //     setIsLoading(false);
    //   });
    // }
  }, [chatData]);

  const getStringAccordingToLength = (str: string, length: number) => {
    if (str?.length > length) {
      return `${str?.slice(0, length)}...`;
    } else {
      return str;
    }
  };

  return (
    <div
      className={`flex items-center gap-3 border-b-[1px] border-solid border-[var(--chat-border)] py-3 px-2 cursor-pointer hover:sm:bg-[var(--apple)]`}
      onClick={popUp !== true ? handleClick : undefined}
    >
      <div>
        {/* <img
          src={img}
          alt="profile_pic"
          className={`rounded-full ${
            popUp === true ? "w-10 h-10 " : "w-14 h-14"
          }`}
        /> */}

        <img
          // src={
          //   chatData?.receiver
          //     ? chatData?.receiver?.artwork_url &&
          //       chatData?.receiver?.artwork_url !=
          //         "https://dapi.ayozat.co.uk/common/default/user.png"
          //       ? chatData?.receiver?.artwork_url
          //       : img
          //     : user?.artwork_url &&
          //       user?.artwork_url !=
          //         "https://dapi.ayozat.co.uk/common/default/user.png"
          //     ? user?.artwork_url
          //     : img
          // }
          src={
            userData?.artwork_url &&
            userData?.artwork_url !=
              "https://dapi.ayozat.co.uk/common/default/user.png"
              ? userData?.artwork_url
              : img
          }
          alt="profile_pic"
          className={`rounded-full ${
            popUp === true ? "w-10 h-10 " : "w-14 h-14"
          }`}
        />
      </div>
      <div className="w-full">
        <div className="flex items-center justify-between w-full">
          <p className="text-base text-white" data-tooltip-id={`chat-username`}>
            {!isLoading && recieverName}
          </p>
          {chatData?.receiver && chatData.receiver?.name?.length > 15 && (
            <div className="text-sm">
              <ReactTooltip
                id={`chat-username`}
                place="top"
                content={chatData.name}
              />
            </div>
          )}
          {popUp !== true && (
            <div>
              <p className="text-xs text-[var(--red)]">
                {formatDateTime(chatData?.latest_message?.updated_at)}
              </p>
              <Badge count={unreadCount} />
            </div>
          )}
        </div>
        <div className="flex items-center w-full gap-2">
          <p className="text-sm text-[var(--Color7B9DB4)]">
            {!isLoading && (
              <>
                {chatData?.latest_message
                  ? getStringAccordingToLength(
                      chatData?.latest_message?.content,
                      15
                    )
                  : `No messages`}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChatUser;
