import React from "react";
import { Link } from "react-router-dom";

function FollowFollowingText({ route, count, text }: any) {
  return (
    <>
      <Link to={route}>
        <div className="flex items-center sm:ml-4 text-[16px] font-[300] text-slate-700 max-sm:my-2 max-md:my-3 ">
          <p>
            <span className="text-2xl text-slate-300">{count}</span>{" "}
            {count > 1 ? `${text}s` : `${text}`}
          </p>
        </div>
      </Link>
    </>
  );
}

export default FollowFollowingText;
