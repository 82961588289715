import { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import PodcastService from "services/podcast/PodcastService";
import BlogCategoryCard from "./shared/BlogCategoryCard";
import BlogService from "services/blogs/BlogService";

function SeeAllBlogCategory() {
  const [categories, setCategories] = useState<any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    BlogService.getAllBlogCategories().then((res) => {
      setCategories(res.data);
      // console.log(res.data);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  return (
    <div>
      {/* main topic section */}

      <div>
        <button
          className="w-10 h-10 mb-5 bg-white rounded-full ml-[14px]"
          onClick={() => {
            navigate(-1);
          }}
        >
          <AiOutlineArrowLeft className="ml-3" />
        </button>
      </div>
      <div className="mb-8">
        <h1 className="text-2xl font-medium text-white lg:text-2xl md:text-2xl max-sm:w-[60%] max-sm:ml-2">
          All Categories
        </h1>
      </div>

      {categories && (
        <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 max-sm:w-full">
          {categories &&
            categories?.map((genre: any, index: number) => (
              <div key={index} className="mb-5 mr-4">
                <BlogCategoryCard genre={genre} />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default SeeAllBlogCategory;
