import React from "react";
import LogoStream from "Assets/AyozatStreamwhite.png";

function ConditionPageNavBar() {
  return (
    <div>
      {/* logo */}
      <>
        <div className="flex items-center mt-5 text-xl font-extrabold w-[230px] h-[15px] max-sm:hidden">
          <img src={LogoStream} alt="logo" className="" />
        </div>
      </>
    </div>
  );
}

export default ConditionPageNavBar;
