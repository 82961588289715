import AntDDrawer from "components/Drawer/AntDDrawer";
import { useUserChatWebSocket } from "hooks/useUserChatWebSocket";
import { useContext, useEffect, useState } from "react";
import { MdOutlineMessage } from "react-icons/md";
import { useSelector } from "react-redux";
import UserChatDrawer from "./UserChatDrawer";
import { ChatContext } from "context/ChatContext";

function NewChatButton({ user }: any) {
  //   console.log(user);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [openChatDrawer, setOpenChatDrawer] = useState<boolean>(false);
  const [isNewChat, setIsNewChat] = useState<boolean>(false);

  const {
    messages,
    chats,
    createNewChat,
    sendMessage,
    notifyTyping,
    showChatProgress,
    initialScroll,
    latestChat,
    handleLatestChat,
    selectedChat,
    setSelectedChat,
    isLoading,
    setIsLoading,
    allChats,
    setAllChats,
  } = useContext(ChatContext);

  // const [userChat, setUserChat] = useState<any>(null);
  const showDrawer = () => {
    const existingChat = allChats?.find((chat: any) => {
      return (
        chat?.receiver?.id == user?.id ||
        chat?.chat_name?.split("-")[1] == user?.id
      );
    });

    if (existingChat) {
      console.log("existing chat", existingChat);
      setSelectedChat(existingChat);
    } else {
      setSelectedChat(null);
      console.log("chat not found", selectedChat);
      handleLatestChat(null);
      const chatObject = {
        id: 0,
        chat_name: `${loggedInUser?.id}-${user?.id}-chat`,
        created_at: "2024-08-08T08:19:06.000000Z",
        latest_message: null,
        pivot: {
          user_id: loggedInUser?.id,
        },
        receiver: user,
        status: "pending",
        updated_at: "2024-08-08T09:20:18.000000Z",
      };
      setSelectedChat(chatObject);
    }
    setOpenChatDrawer(true);
  };

  const closeDrawer = () => {
    setSelectedChat(null);
    // setUserChat(null);
    setOpenChatDrawer(false);
  };

  // useEffect(() => {
  //   const chat = allChats?.find((chat: any) => chat?.receiver?.id == user?.id);
  //   setUserChat(chat);
  // }, [allChats]);
  return (
    <>
      <button
        className={`flex items-center min-w-[128px] h-[40px] p-4 text-center border-solid border-2 border-white text-white rounded-full max-sm:p-2 `}
        onClick={showDrawer}
      >
        <div className="flex items-center justify-center w-full gap-2 mx-auto">
          <span>
            <MdOutlineMessage />
          </span>{" "}
          Message
        </div>
      </button>
      {/* drawer */}
      <AntDDrawer
        onClose={closeDrawer}
        open={openChatDrawer}
        children={
          <UserChatDrawer
            // chats={chats}
            // onClose={closeDrawer}
            // sendMessage={sendMessage}
            // messages={messages}
            // notifyTyping={notifyTyping}
            // showChatProgress={showChatProgress}
            chatFromProfile={true}
            // selectedChatData={userChat}
            openChatDrawer={openChatDrawer}
            // createNewChat={createNewChat}
            // latestChat={latestChat}
            user={user}
          />
        }
      />
      ;
    </>
  );
}

export default NewChatButton;
