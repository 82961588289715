import { useContext, useEffect, useState } from "react";
import { AllChatData } from "constant/ChatData";
import AllChats from "./AllChats";
import OpenChat from "./OpenChat";
import { ChatContext } from "context/ChatContext";
import { IUserChat } from "types/IUserChat";

function UserChatDrawer(props: IUserChat) {
  const { selectedChat, setSelectedChat } = useContext(ChatContext);

  // useEffect(() => {
  //   if (props.openChatDrawer && props.chatFromProfile) {
  //     setSelectedChat(props.selectedChatData);
  //   }
  // }, [props.openChatDrawer]);

  return (
    <div className="bg-[var(--Color1E1E1E)] h-screen !z-50">
      {!selectedChat ? (
        <>
          <AllChats />
        </>
      ) : (
        <>
          <OpenChat sidePanel={true} />
        </>
      )}
    </div>
  );
}

export default UserChatDrawer;
