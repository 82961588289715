import React, { useEffect, useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import TopTitle from "./Headings/TopTitle";
import NormalText from "./Headings/NormalText";
import Footer from "components/Footer";
import CustomPagesServices from "services/custompages/CustomPagesServices";
import LoadingSpin from "components/Loading spins/LoadingSpin";
import WelcomePageSidebar from "./welcome/WelcomePageSidebar";
import ContentNavbar from "components/ContentNavbar";
import { contentNavData } from "constant/ContentNavBarData";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ConditionPageNavBar from "components/ConditionPageNavBar";
import BottomSection from "components/BottomSection";

const PrivacyPolicy = ({ handleClick, isActive }: any) => {
  let navigate = useNavigate();

  const slug = "privacy-policy";
  const [pageData, setPageData] = useState<any | null>({});
  const [loadingSpin, setLoadingSpin] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    if (slug) {
      setLoadingSpin(true);
      CustomPagesServices.getCustomPageDetailsBySlug(slug).then((res) => {
        setPageData(res);
        setLoadingSpin(false);
      });
    }
    // }, 1000);
  }, [slug]);

  const seoImage = "https://ayozat.co.uk/logo192.png";
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Privacy Policy - AYOZAT</title>
          <meta name="title" content="Privacy Policy - AYOZAT" />
          <meta name="description" content="Privacy Policy - AYOZAT" />
          <meta property="og:image" content={seoImage} />

          <meta name="twitter:title" content="Privacy Policy - AYOZAT" />
          <meta name="twitter:description" content="Privacy Policy - AYOZAT" />
          <meta name="twitter:image" content={seoImage} />

          <meta property="og:title" content="Privacy Policy - AYOZAT" />
          <meta property="og:description" content="Privacy Policy - AYOZAT" />
          <meta property="og:image:secure_url" content={seoImage} />
        </Helmet>
      </HelmetProvider>
      {/* <ContentNavbar handleClick={handleClick} /> */}
      {/* <WelcomePageSidebar isActive={isActive} navData={contentNavData} /> */}
      <div
      className="!w-[80%] !mx-auto" 
        // className={
        //   isActive
        //     ? "md:pl-[100px] pl-[80px] duration-500 p-2 mt-2 ml-3 max-sm:pl-0 max-sm:ml-2 "
        //     : "pl-0 duration-500 p-2 mt-2 ml-8 max-sm:pl-0 max-sm:ml-2 "
        // }
      >
        <ConditionPageNavBar />
        <div className="mx-auto mt-10 max-sm:ml-5">
          {/* <div
            className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer mb-4`}
          >
            <HiArrowLeft
              className="text-black"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div> */}
          <TopTitle pageName={pageData?.title} />
          <hr className="opacity-50" />

          {loadingSpin ? (
            <div className="flex items-center justify-center mx-auto">
              <LoadingSpin fontSize={40} color={"white"} />
            </div>
          ) : (
            <NormalText content={pageData?.content} />
          )}
        </div>
      </div>
      {/* <Footer /> */}
      <BottomSection />
    </>
  );
};

export default PrivacyPolicy;
