import axios from "axios";
import authHeader from "utils/auth-header";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;
const originURL = process.env.REACT_APP_API_ORIGIN;

// login user
const login = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${baseURL}/auth/login`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return response;
};
// register user
const register = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${baseURL}/auth/signup`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response;
};

// register user
const forgotPasswordRequest = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${baseURL}/forgot-password`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response;
};

// register user
const resetPasswordRequest = async (data: any) => {
  const response = await axios({
    method: "post",
    data: data,
    url: `${baseURL}/reset-password`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response;
};

const resendVerifyEmail = async (data: any) => {
  const response = await axios({
    method: "post",
    data: { email: data },
    url: `${baseURL}/email/verify/resend`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response;
};

const logout = async () => {
  return await axios({
    method: "post",
    url: `${baseURL}/user/logout`,
    headers: authHeader(),
  });
};

const AuthService = {
  login,
  logout,
  register,
  forgotPasswordRequest,
  resetPasswordRequest,
  resendVerifyEmail,
};

export default AuthService;
