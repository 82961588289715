const ContentSideBar = ({ openModal, event }: any) => {
  // console.log(event);
  return (
    <div className={`w-full text-left ${event?.replay_videos?.length > 1 ? "":"mt-14"} max-sm:w-full `}>
      {" "}
      <h1 className="text-[35px] font-bold max-sm:text-[25px]">
        {event?.title}
      </h1>
      <div className="">
        <h1 className="text-[10px] text-center w-[30%] font-normal text-white  px-2 py-1 bg-slate-600">
          {event?.category}
        </h1>
      </div>
      {event?.duration && (
        <div className="mt-5">
          <h1 className="text-[13px] my-2  w-[40%] font-semibold text-white max-sm:w-full">
            Duration: {event?.duration}
          </h1>
        </div>
      )}
      {event?.isEnableChat === 0 ? (
        <>
          <div className="mt-6 max-sm:w-full ">
            <h1 className="text-[14px] my-2  w-[90%] font-normal text-white ">
              {event?.description}
            </h1>
          </div>
        </>
      ) : (
        <>
          <div className="mt-6 max-sm:w-full">
            <h1 className="text-[14px] my-2  w-[90%] font-normal text-white ">
              {event?.description}
            </h1>
          </div>
        </>
      )}
    </div>
  );
};

export default ContentSideBar;
