import SubText from "components/shared/SubText";
import "swiper/css/navigation";
import "swiper/css";
import CreatorVideoCard from "./CreatorVideoCard";
import CreatorVideoSkeleton from "components/skeleton/CreatorVideoSkeleton";
import NoSearch from "pages/searchPage/shared/NoSearch";

function CreatorHomeVideoGrid({ creatorData, isLoading }: any) {
  //console.log(episodes);
  const recent_videos = creatorData?.recent_videos || [];
  // console.log(creatorData?.general_info.slug);
  return (
    <div>
      {!isLoading && (
        <>
          {recent_videos.length === 0 && recent_videos ? (
            <NoSearch text="Recent Videos" />
          ) : (
            <>
              <div className="flex justify-between mt-10 ml-6">
                <SubText text="Videos" className="font-bold" />
              </div>
              <div className="mt-4 mb-5 w-[95%] grid grid-cols-2 gap-5 max-sm:grid-cols-1 max-md:grid-cols-2 max-lg:grid-cols-2 max-xl:grid-cols-2 max-2xl:grid-cols-2 max-[1700px]:grid-cols-4 max-sm:mx-auto max-sm:w-full max-sm:gap-4">
                {recent_videos?.slice(0, 2).map((video: any, index: any) => (
                  <div key={index} className="mb-5 mr-4">
                    <CreatorVideoCard
                      video={video}
                      slug={creatorData?.general_info.slug}
                      // season={episode?.season}
                      // id={episode?.id}
                      isLoading={isLoading}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
      {isLoading && <CreatorVideoSkeleton />}
    </div>
  );
}

export default CreatorHomeVideoGrid;
