import LoadingSpin from "components/Loading spins/LoadingSpin";
import React, { useState } from "react";
import { MdPlaylistRemove } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { clearDataWhenLogout } from "redux/AudioPlayerRedux";
import { Tooltip as ReactTooltip } from "react-tooltip";

function ClearQueueButton({ text, spinSize }: any) {
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { radioVisible } = useSelector((state: any) => state?.audio);

  const handleClearQueue = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch(clearDataWhenLogout());
    }, 1000);
  };
  return (
    <div>
      {text ? (
        <>
          <div className="flex items-center justify-end gap-2 ">
            {loading && <LoadingSpin fontSize={spinSize} color={"red"} />}
            <p
              className="text-sm duration-200 ease-in-out cursor-pointer hover:text-red-600 border-2 border-solid px-5 py-1 rounded-full hover:border-red-600"
              onClick={handleClearQueue}
            >
              {text}
            </p>
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <LoadingSpin fontSize={spinSize} color={"red"} />
          ) : (
            <>
              <MdPlaylistRemove
                className="text-2xl duration-300 ease-in-out cursor-pointer hover:text-red-600"
                data-tooltip-id={`audio-player-clear-queue`}
                onClick={handleClearQueue}
              />
              <div className="text-sm">
                <ReactTooltip
                  id={`audio-player-clear-queue`}
                  place="top"
                  content={radioVisible ? `Close` : "Clear Queue"}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ClearQueueButton;
