import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import UserProfileService from 'services/UserProfileService';
import ProfileHeroSection from './shared/ProfileHeroSection';
import UserProfileButtonSection from 'pages/userProfile/shared/UserProfileButtonSection';
import { useSelector } from 'react-redux';
import UserProfileButtonSectionSkeleton from 'components/skeleton/UserProfileButtonSectionSkeleton';
import PayperviewService from 'services/payperview/PayperviewServie';
import PlaylistService from 'services/music/PlaylistService';
import LikeService from 'services/music/LikeService';

function ProfileHome() {
  const { username } = useParams();
  const [user, setUser] = useState<any>();
  const [allData, setAllData] = useState<any>();
  const [userPlaylists, setUserPlaylists] = useState<any>([]);
  const [isLoadingPlaylist, setIsLodingPlaylist] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [purchased, setPurchased] = useState<any>([]);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const favorite = useSelector((state: any) => state?.user?.userFavorites);

    useEffect(() => {
        setIsLoading(true);
        if (username) {
          UserProfileService.getUserByUserName(username).then((res: any) => {
            setIsLoading(false);
            setUser(res.user);
            // console.log("user", user);
          });
        }
      }, [username]);

      useEffect(() => {
        // console.log("aaa", user?.id);
        if (user && user?.id === loggedInUser?.id) {
          setAllData(favorite);
        } else {
          if (user) {
            LikeService.getFavoriteById(loggedInUser?.id, user?.id)
              .then((res) => {
                // console.log(res);
                setAllData(res.profile);
              })
              .catch((e: any) => {
                // console.log(e);
                setAllData(null);
              });
          }
        }
      }, [user]);
    
      useEffect(() => {
        setIsLodingPlaylist(true);
        setIsLoading(true);
        if (loggedInUser) {
          PlaylistService.getUserPlaylists().then((res) => {
            setIsLoading(false);
            setIsLodingPlaylist(false);
            setUserPlaylists(res.data.data);
            // if (res.data.data.length > 0) {
            //   setHaveData(true);
            // }
          });
        }
      }, [loggedInUser]);
    
      useEffect(() => {
        setIsLoading(true);
        PayperviewService.getPurchasedEvents(loggedInUser?.id)
          .then((res) => {
            console.log("event", res.data);
            setIsLoading(false);
            setPurchased(res.data);
          })
          .catch((e: any) => {
            console.log("getPurchasedEvents ", e);
          });
      }, [loggedInUser]);
  return (
    <div>
        {!isLoading && <> {user && <ProfileHeroSection user={user} />}</>}

        <>
        {!isLoading && (
          <>
            {" "}
            {user && (
              <>
                {user?.id === loggedInUser?.id ? (
                  <UserProfileButtonSection
                    user={user}
                    favorite={allData}
                    userPlaylist={userPlaylists}
                    isLoadingPlaylist={isLoadingPlaylist}
                    ppvEvent={purchased}
                  />
                ) : (
                  <>
                    {/* {user?.activity_privacy === 0 ? ( */}
                    <UserProfileButtonSection
                      user={user}
                      favorite={allData}
                      isLoadingPlaylist={isLoadingPlaylist}
                      // haveData={haveData}
                      userPlaylist={userPlaylists}
                      ppvEvent={purchased}
                    />
                    {/* ) : ( */}
                    {/* <PrivateAccount /> */}
                  </>
                )}
              </>
            )}
          </>
        )}
        {isLoading && <UserProfileButtonSectionSkeleton />}
      </>
    </div>
  )
}

export default ProfileHome