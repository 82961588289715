import { useWebSocket } from "hooks/useWebSocket";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ChatBody from "./components/ChatBody";
import ChatHeader from "./components/ChatHeader";
import ChatInput from "./components/ChatInput";
import ScrollBottomBtn from "./components/ScrollBottomBtn";

const ChatWidget = ({ event, setIsNewChat, isActiveRight, isNewChat }: any) => {
  const CHAT_SOCKET_URL = process.env.REACT_APP_CHAT_SERVER;
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollButtonRef = useRef<HTMLButtonElement | null>(null);
  const websocketRef = useRef<WebSocket | null>(null);
  const [isNotify, setIsNotify] = useState<boolean>(false);

  const {
    messages,
    sendMessage,
    notifyTyping,
    showChatProgress,
    initialScroll,
  } = useWebSocket(
    event.id,
    loggedInUser?.access_token,
    loggedInUser?.id,
    CHAT_SOCKET_URL ? `${CHAT_SOCKET_URL}/ppv-chat` : "",
    // "ws://localhost:3001/ppv-chat",
    () => setIsNewChat(true)
  );

  //mobile scroll
  useEffect(() => {
    setIsMobile(true);
  }, []);

  useEffect(() => {
    if (isMobile) {
      scrollToBottom();
      setIsMobile(false);
    }
  }, [messages]);

  //update notify status
  useEffect(() => {
    if (isNewChat === true) {
      setIsNotify(true);
    }
  }, [messages, isNewChat]);

  // Initial scroll to bottom when the component mounts
  useEffect(() => {
    if (initialScroll === true) {
      console.log("Initial Scoll is " + initialScroll);
      scrollToBottom();
    }
  }, [isActiveRight, initialScroll]);

  // Function to scroll to the bottom of the chat
  const scrollToBottom = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
      setShowScrollButton(false);
      setIsNotify(false);
    }
  };

  // Scroll to bottom whenever user update message but in near bottom
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const isNearBottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 800; //gif height
      if (isNearBottom) {
        scrollToBottom();
      }
    }
  }, [messages]);

  //typing is active when user is near bottom
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const isNearBottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 100; // 100px threshold from the bottom
      if (isNearBottom) {
        scrollToBottom();
      }
    }
  }, [showChatProgress]);

  // Function to handle scrolling
  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const isNearBottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 100; // 100px threshold from the bottom
      setShowScrollButton(!isNearBottom);
    }
  };

  // Attach scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className="flex flex-col h-[85vh] rounded-2xl border-gray-700 border-[2px] p-[15px]">
      <ChatHeader event={event} liveUsers={10} />
      <div
        ref={containerRef}
        className="flex-1 px-4 pb-4 overflow-y-auto scrollbar-hide"
        style={{ maxHeight: "calc(100% - 40px)" }}
      >
        <ChatBody
          chats={messages}
          event={event}
          showChatProgress={showChatProgress}
        />
        <ScrollBottomBtn
          showScrollButton={showScrollButton}
          scrollButtonRef={scrollButtonRef}
          scrollToBottom={scrollToBottom}
          isNotify={isNotify}
        />
      </div>
      <ChatInput
        inputValue={inputValue}
        setInputValue={setInputValue}
        event={event}
        socket={websocketRef.current}
        sendMessage={sendMessage}
        notifyTyping={notifyTyping}
      />
    </div>
  );
};

export default ChatWidget;
