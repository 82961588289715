import LazyLoadImg from "components/LazyLoadImg";
import { BsPlayCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { addStationToRadio } from "redux/AudioPlayerRedux";
import AddRadioToFav from "./AddRadioToFav";
import { useState } from "react";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";

function RadioCard({ station, user, favorite }: any) {
  const [underline, setUnderline] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  // const [hover, setHover] = useState(false);
  // const [isLiked, setIsLiked] = useState<boolean>();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  const handleRadio = () => {
    dispatch(addStationToRadio(station));
  };

  // const [favorite, setFavorite] = useState<any>();

  // useEffect(() => {
  //   if (user) {
  //     LikeService.getFavoriteById(user?.id).then((res) => {
  //       const favoritesIds = res.profile.favorite_radio_stations.map(
  //         (item: any) => item.id
  //       );
  //       setFavorite(favoritesIds);
  //     });
  //   }
  // }, [user?.id]);

  // useEffect(() => {
  //   if (favorite?.includes(station.id)) {
  //     setIsLiked(true);
  //   } else {
  //     setIsLiked(false);
  //   }
  // }, [favorite, station.id]);

  // const handleFavorite = async () => {
  //   const favoritePodcast = {
  //     id: station.id.toString(),
  //     object_type: "radio",
  //   };

  //   if (!isLiked) {
  //     await RadioService.likeRadio(favoritePodcast)
  //       .then((res) => {
  //         toast.info(`You Liked ${station.title}`);
  //       })
  //       .catch((e) => {
  //         toast.error(`Request cannot be completed`);
  //       });
  //     setIsLiked(true);
  //   } else {
  //     await RadioService.unlikeRadio(favoritePodcast)
  //       .then((res) => {
  //         toast.info(`You Unliked ${station.title}`);
  //       })
  //       .catch((e) => {
  //         toast.error(`Request cannot be completed`);
  //       });
  //     setIsLiked(false);
  //   }
  // };

  return (
    <div className=" p-3  rounded-md  shadow-lg mb-[15px] h-[250px]  inline-block w-[178px]  hover:text-slate-300 text-white hover:bg-slate-800 hover:shadow-xl">
      {/* image */}
      <div
        className="relative w-[100%] rounded-lg h-[165px] bg-img white-text max-sm:w-[90%] max-sm:h-[180px]"
        // style={{
        //   background: `url(${station.artwork_url}) no-repeat center`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        // }}
      >
        <LazyLoadImg
          imgSrc={station?.artwork_url}
          imgAlt={station?.title}
          imgHeight={"165px"}
          imgWidth={"158px"}
          // classname={"w-[50px] h-[50px] cursor-pointer"}

          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            borderRadius: "5px",
          }}
        />
        {/*centralize  play icon */}
        <div className="flex items-center justify-center h-[full]">
          <button
            className="absolute top-[50px] items-center p-1 rounded-full add-button ml-3 hover:shadow-2xl"
            onClick={handleRadio}
          >
            <BsPlayCircle className="w-12 h-12 my-auto duration-300 ease-in-out text-md action-icon-size hover:text-red-600" />
          </button>
        </div>

        {/* card inside content */}
        <div className="absolute inset-x-0 bottom-0 w-[165px] flex justify-end p-2 mb-2 pr-5">
          {loggedInUser && user?.id === loggedInUser?.id && (
            <div
              className="items-center p-1 rounded-full add-button"
              // onMouseEnter={() => setHover(true)}
              // onMouseLeave={() => setHover(false)}
            >
              <AddRadioToFav station={station} RadioCard={true} />
            </div>
          )}

          {/* <div>
            <button
              className="items-center p-1 rounded-full add-button"
              onClick={handleRadio}
            >
              <BsPlayCircle className="text-md action-icon-size " />
            </button>
          </div> */}
        </div>
      </div>
      <div className="relative top-1">
        <Link
          to={RouteName.RadioDescriptionRoute.replace(":slug", station?.slug)}
          className="flex"
          onMouseEnter={() => {
            setUnderline(true);
          }}
          onMouseLeave={() => {
            setUnderline(false);
          }}
        >
          <div className="my-[4px]">
            <p
              className={`text-[15px] font-[600] text-white ${
                underline && `underline`
              }`}
            >
              {station.title}
            </p>
            <div className="flex items-center justify-between">
              {/* <div>
              <img
              src={fmIcon}
              alt="fm icon"
              className="w-[20px] h-[20px]  object-cover overflow-hidden rounded-md"
              />
            </div> */}
              <div>
                {/* <p className="text-[12px] w-[171px] font-[400] text-[#7B9DB4] mt-[5px] ml-2 max-sm:w-[80%]">
                {station.description}
              </p> */}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default RadioCard;
