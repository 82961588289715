import React, { useState, useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import PayPerViewCard from "./shared/PayPerViewCard";
import { useSelector } from "react-redux";
import PayperviewService from "services/payperview/PayperviewServie";
import PayperviewPaidSkeleton from "components/skeleton/PayperviewPaidSkeleton";
import SubText from "components/shared/SubText";
import { RouteName } from "constant/routeNames";
import { Helmet } from "react-helmet-async";

function PaidSubscriptionsPage() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [paidEvents, setPaidEvents] = useState<any>();
  const navigate = useNavigate();
  const [isLoading, setIsLoding] = useState<any>(false);
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    setIsLoding(true);
    PayperviewService.getPurchasedEvents(loggedInUser?.id)
      .then((res) => {
        setIsLoding(false);
        setPaidEvents(res.data);
      })
      .catch((e: any) => {
        console.log("getPurchasedEvents ", e);
      });
  }, []);

  const handleGoHome = async () => {
    navigate(RouteName.PayPerView.replace("/*", ""));
  };

  return (
    <>
    <Helmet>
        <title>Subscriptions - AYOZAT</title>
        <meta
          name="description"
          content="Stream Movies, Series, Live TV, Music, Radio & Podcasts plus Live Pay-Per-View Events. Watch on AYOZAT STREAM. Your Entertainment Hub!"
        />
        <link rel="canonical" href={`${FE_URL}/ppv/purchased`} />
        <meta property="og:title" content="AYOZAT Payperview" />
        <meta
          property="og:description"
          content="Stream Movies, Series, Live TV, Music, Radio & Podcasts plus Live Pay-Per-View Events. Watch on AYOZAT STREAM. Your Entertainment Hub!"
        />
        {/* <meta property="og:image:secure_url" content="https://ayozat.co.uk/logo192.png" /> */}
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:url" content={`${FE_URL}/ppv/purchased`} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AYOZAT Payperview" />
        <meta
          name="twitter:description"
          content="Stream Movies, Series, Live TV, Music, Radio & Podcasts plus Live Pay-Per-View Events. Watch on AYOZAT STREAM. Your Entertainment Hub!"
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT Payperview" />
        <meta name="twitter:url" content={`${FE_URL}/ppv/purchased`} />
      </Helmet>
    <div>
      {/* main topic section */}
      <div>
        <button
          className="w-10 h-10 mb-5 bg-white rounded-full max-sm:ml-[16px]"
          onClick={() => {
            navigate(-1);
          }}
        >
          <AiOutlineArrowLeft className="ml-3" />
        </button>
      </div>
      <div className="mb-8">
        <h1 className="text-xl font-medium text-white lg:text-2xl md:text-3xl max-sm:w-[60%] max-sm:ml-2">
          Paid Subscriptions
        </h1>
      </div>
      {!isLoading && (
        <>
          {paidEvents?.length != 0 ? (
            <div className="grid w-full grid-cols-2 mt-4 mb-28 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 max-sm:w-full">
              {paidEvents &&
                paidEvents?.map((paidEvent: any, index: number) => (
                  <div key={index} className="mb-5 mr-4">
                    <PayPerViewCard event={paidEvent} isPurchased={true} />
                  </div>
                ))}
            </div>
          ) : (
            <>
              <div className="flex flex-row items-center justify-center mt-20">
                <SubText
                  text="No Paid Subscriptions Available"
                  className="font-light"
                />
              </div>
              <div className="flex flex-row items-center justify-center mt-10">
              <button
                className=" w-auto font-semibold text-center text-white p-3 rounded-full button-red max-sm:py-3  max-sm:text-[15px] max-sm:w-full max-sm:mx-auto"
                onClick={handleGoHome}
              >
                Purchase
              </button>
              </div>
              
            </>
          )}
        </>
      )}
      {isLoading && <PayperviewPaidSkeleton />}
    </div>
    </>
  );
}

export default PaidSubscriptionsPage;
