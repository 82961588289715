import {
  MdAddCircleOutline,
  MdArrowForwardIos,
  MdRemoveCircleOutline,
} from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PlaylistService from "services/music/PlaylistService";

import { useEffect, useState } from "react";
import { RouteName } from "constant/routeNames";
import { triggerSuccessNotification } from "utils/notification";
import { Notification } from "constant/notifications";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoMdShareAlt } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import MusicService from "services/music/MusicService";
import { addToQueue } from "redux/AudioPlayerRedux";

function MusicPlayListPopUpDesktop({
  className,
  list,
  openModal,
  closeModal,
  userPlaylists,
  setUserPlaylists,
  mediaId,
  removeFromPlaylist,
  setRemovedSongId,
  setOpenedComponent,
}: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const { musicPlaylistId, playerVisible } = useSelector(
    (state: any) => state?.audio
  );
  const dispatch = useDispatch();
  // const { id } = useParams();
  const [hover, setHover] = useState<boolean>(false);
  const baseUrl = window.location.origin;

  const addSongToPlaylist = async (playlistId: any, playlistName: any) => {
    const data = {
      mediaId: mediaId,
      mediaType: "song",
      playlist_id: playlistId,
    };
    // console.log(data);
    await PlaylistService.addSongToPlaylist(data)
      .then((response: any) => {
        // console.log(response);
        if (response.data.success === true) {
          PlaylistService.getUserPlaylists().then((res) => {
            setUserPlaylists(res.data.data);
            // console.log(res.data.data);

            if (musicPlaylistId === playlistId && playerVisible) {
              MusicService.getTrackDataByID(mediaId).then((res) => {
                dispatch(addToQueue(res));
              });
            }
            toast.success(`Add to ${playlistName}`);
          });
        } else {
          if (response.data.message === "Duplicated row") {
            toast.warning(`Already in the ${playlistName}`);
          } else {
            toast.error(`There is an issue when adding to ${playlistName}`);
          }
        }
        closeModal();
      })
      .catch((e: any) => {
        toast.error("An error occurred. Please try again.");
      });
  };

  // const handleRemoveSongFromPlaylist = async (playlistId: any) => {
  //   const data = {
  //     song_id: mediaId,
  //     playlist_id: playlistId,
  //   };

  //   await PlaylistService.removeSongFromPlaylist(data)
  //     .then((response: any) => {
  //       // console.log(response);
  //       if (response.data.success === true) {
  //         toast.success(`Removed from the playlist`);
  //         setRemovedSongId(mediaId);
  //       } else {
  //         toast.error(`There is an issue when removing from playlist`);
  //       }
  //       closeModal();
  //     })
  //     .catch((e: any) => {
  //       toast.error("An error occurred. Please try again.");
  //     });
  // };

  const copyToClipboard = async () => {
    const currentUrl =
      baseUrl +
      RouteName.Music.replace(
        "*",
        RouteName.MusicTrack.replace(":id", mediaId)
      );
    await navigator.clipboard.writeText(currentUrl);
    triggerSuccessNotification(Notification.CopyToClipboard);
    closeModal();
  };

  const [submenuPosition, setSubmenuPosition] = useState<"left" | "right">(
    "right"
  );

  useEffect(() => {
    const popupElement = document.querySelector(".watchlist-dropdown");
    if (popupElement) {
      const popupRect = popupElement.getBoundingClientRect();
      const distanceToRight = window.innerWidth - popupRect.right;
      const distanceToLeft = popupRect.left;

      if (distanceToRight < distanceToLeft) {
        setSubmenuPosition("right");
      } else {
        setSubmenuPosition("left");
      }
    }
  }, []);

  const handleOpenPlaylist = () => {
    setOpenedComponent("createPlaylist");
    openModal();
  };

  const handleOpenShareModel = () => {
    setOpenedComponent("shareDetails");
    // copyToClipboard();
    openModal();
  };

  return (
    <div
      className={`watchlist-dropdown px-4 py-2 rounded-md text-sm absolute white-text duration-500 border-2 border-1 border-neutral-500 overflow-y-visible ${
        className && className
      }`}
    >
      <div className="my-2">
        <li
          className="flex items-center justify-between music-playlist-popup-li"
          onMouseEnter={() => setHover(false)}
          // onClick={handleOpenShareModel}
          onClick={copyToClipboard}
        >
          Copy Song Link
          <span className="">
            <IoMdShareAlt />
          </span>
        </li>

        {loggedInUser && (
          // desktop view
          <div className="relative">
            <li
              className="flex items-center justify-between music-playlist-popup-li"
              // onClick={() => setHover(!hover)}
              onMouseEnter={() => setHover(true)}
              // onMouseLeave={() => setHover(false)}
            >
              Add to playlist
              <span className="">
                <MdArrowForwardIos />
              </span>
            </li>

            {/* submenu */}
            {hover && (
              <div
                className={`watchlist-dropdown px-4 py-2 rounded-md text-sm absolute ${
                  submenuPosition === "right" ? "right-[235px]" : "left-[235px]"
                } bottom-[-10px] white-text duration-500 border-2 border-1 border-neutral-500 !min-w-[250px] overflow-y-scroll overflow-x-visible scrollbar-hide max-h-[200px]`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                {userPlaylists?.map((item: any, i: number) => (
                  <li
                    className="mb-2 cursor-pointer hover:text-[#828282] ease-in-out duration-100 list-none"
                    key={i}
                    onClick={() => addSongToPlaylist(item?.id, item?.title)}
                  >
                    {/* {item.title} */}

                    {item?.title.length > 25 ? (
                      <>
                        <span data-tooltip-id={item?.id}>{`${item?.title.slice(
                          0,
                          25
                        )}...`}</span>
                        <ReactTooltip
                          className="absolute w-full text-sm"
                          id={item?.id}
                          place="top"
                          content={item?.title}
                        />
                      </>
                    ) : (
                      item?.title
                    )}
                  </li>
                ))}

                {/* remove from play list */}
                {/* {removeFromPlaylist && (
                  <>
                    {userPlaylists?.length > 0 && (
                      <hr className="cursor-pointer border-1 border-neutral-500 " />
                    )}
                    <li
                      className="flex items-center cursor-pointer hover:text-[#828282] ease-in-out duration-100 my-2"
                      onClick={() => handleRemoveSongFromPlaylist(id)}
                      onMouseEnter={() => setHover(true)}
                    >
                      <MdRemoveCircleOutline />
                      <p className="ml-1 ">Remove from the {list}</p>
                    </li>
                    <hr className="cursor-pointer border-1 border-neutral-500 " />
                  </>
                )} */}

                {/* Create new playlist */}
                {/* {userPlaylists?.length > 0 && ( */}

                {/* )} */}
                <li
                  className="flex items-center cursor-pointer hover:text-[#828282] ease-in-out duration-100 my-2"
                  onClick={handleOpenPlaylist}
                  onMouseEnter={() => setHover(true)}
                >
                  <MdAddCircleOutline />
                  <p className="ml-1 ">Create new {list}</p>
                </li>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default MusicPlayListPopUpDesktop;
