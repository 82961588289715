import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;

//  search all data
const searchAll = async (text: string) => {
  const encodedText = encodeURIComponent(text);

  try {
    const response = await axios({
      method: "get",
      url: `${baseURL}/search?q=${encodedText}`,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        return {
          data: [],
          message: "The search query value is null or too short",
        };
      }
    }
    throw error;
  }
};

//  search stations
const searchStations = async (text: string) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/search/station?q=${text}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};
//  search songs
const searchSongs = async (text: string) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/search/song?q=${text}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};
//  search Podcast
const searchPodcasts = async (text: string) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/search/podcast?q=${text}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};
//  search Playlist
const searchPlaylists = async (text: string) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/search/playlist?q=${text}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

//  search Artist
const searchArtists = async (text: string) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/search/artist?q=${text}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

//  search Albums
const searchAlbums = async (text: string) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/search/Album?q=${text}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};

//  search Users
const searchUsers = async (text: string) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/search/user?q=${text}`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
};
const SearchServie = {
  searchAll,
  searchStations,
  searchSongs,
  searchPodcasts,
  searchPlaylists,
  searchArtists,
  searchAlbums,
  searchUsers,
};

export default SearchServie;
