import CategoriesCoursel from "pages/videoPage/home/CategoriesCoursel";
import HeroCarousel from "pages/videoPage/home/HeroCarousel";
import VideoPageSeriesCoursel from "pages/videoPage/home/VideoPageSeriesCoursel";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import VideoService from "services/video/VideoService";
import VideoPageMovieCoursel from "./home/VideoPageMovieCoursel";
import { Adsense } from "@ctrl/react-adsense";
import GeneralServices from "services/General/GeneralServices";
import PickupCarousel from "./home/PickupCoursel";
import CreatorCardCarousal from "./home/CreatorCardCarousal";
import RandomCreatorVideosCarousal from "./home/RandomCreatorVideosCarousal";

function VideoPageHome() {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [videos, setVideos] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);
  const [isLoadingMovies, setIsLoadingMovies] = useState<any>(false);
  const [isLoadingSeries, setIsLoadingSeries] = useState<any>(false);
  const [gAdSlot, setgAdSlot] = useState<any>();
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_video_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    setIsLoadingMovies(true);
    VideoService.getRandomMovies()
      .then((res: any) => {
        setVideos(res.data);
        setIsLoadingMovies(false);
      })
      .catch((e) => {
        // console.log(e);
      });

    setIsLoadingSeries(true);
    VideoService.getRandomTVSeries()
      .then((res) => {
        // console.log(res);

        setSeries(res.data);
        setIsLoadingSeries(false);
      })
      .catch((e) => {
        // console.log(e);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>AYOZAT Video</title>
        <meta
          name="description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <link rel="canonical" href={`${FE_URL}/video/`} />
        <meta property="og:title" content="AYOZAT Video" />
        <meta
          property="og:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        {/* <meta property="og:image:secure_url" content="https://ayozat.co.uk/logo192.png" /> */}
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:url" content={`${FE_URL}/video/`} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AYOZAT Video" />
        <meta
          name="twitter:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT Video" />
        <meta name="twitter:url" content={`${FE_URL}/video/`} />
      </Helmet>

      <div>
        <h1 className="hidden">AYOZAT Video</h1>
        <HeroCarousel />
        {/* <PickupCarousel /> */}
        {gAdSlot && (
          <Adsense
            client="ca-pub-5773929890067376"
            slot={gAdSlot}
            style={{
              display: "flex",
              marginBottom: "30px",
              marginTop: "20px",
              height: "90px",
              justifyContent: "center",
            }}
            format="horizontal"
            data-full-width-responsive="true"
            // format="fluid"
          />
        )}
        <PickupCarousel />
        <CreatorCardCarousal />
        <VideoPageMovieCoursel
          movies={videos}
          user={loggedInUser}
          isLoading={isLoadingMovies}
        />
        <VideoPageSeriesCoursel
          user={loggedInUser}
          series={series}
          isLoading={isLoadingSeries}
        />
        {/* <AdvertisementsSection /> */}
        {gAdSlot && (
          <Adsense
            client="ca-pub-5773929890067376"
            slot={gAdSlot}
            style={{
              display: "flex",
              marginBottom: "30px",
              marginTop: "20px",
              height: "90px",
              justifyContent: "center",
            }}
            format="horizontal"
            data-full-width-responsive="true"
            // format="fluid"
          />
        )}
        <CategoriesCoursel />
        <RandomCreatorVideosCarousal />
      </div>
    </>
  );
}

export default VideoPageHome;
