import { ChatContext } from "context/ChatContext";
import React, { useContext, useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import img from "../../../Assets/Images/ProfileAvatar.png";
import { IUnreadChat } from "hooks/useUserChatWebSocket";
import { Badge } from "antd";

function ChatRequest({ chatData, setSelectedChat, popUp = true }: any) {
  const { changeChatStatus, unreadChats, setUnreadChats, messages } =
    useContext(ChatContext);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const handleClick = () => {
    setSelectedChat(chatData);

    setUnreadChats((prevChats: IUnreadChat[]) => {
      return prevChats.filter((chat: IUnreadChat) => {
        return chat.chatId != chatData.id;
      });
    });
  };
  const handleApproveChat = (status: string) => {
    // UserChatService.approveChat(chatData.id).then((res) => {
    //   if (res.message === "Chat status updated") {
    //     setApprovedChats((prevChats: any) => {
    //       return [...prevChats, chatData];
    //     });
    //     setPendingChats((prevChats: any) => {
    //       return prevChats.filter((chat: any) => chat.id !== chatData.id);
    //     });
    //   }
    // });

    changeChatStatus(chatData.id, chatData.receiver.id, status);
  };
  useEffect(() => {
    const chat: IUnreadChat = unreadChats.find(
      (chat: IUnreadChat) => chat.chatId == chatData?.id
    );
    setUnreadCount(chat ? chat.count : 0);
  }, [messages]);

  // console.log(chatData);
  return (
    <div
      className={`flex items-center gap-3 border-b-[1px] border-solid border-[var(--chat-border)] py-3 px-2 cursor-pointer hover:sm:bg-[var(--apple)]`}
      onClick={handleClick}
    >
      <div>
        <Badge count={unreadCount} size="default">
          <img
            src={
              chatData?.receiver?.artwork_url &&
              chatData?.receiver?.artwork_url !=
                "https://dapi.ayozat.co.uk/common/default/user.png"
                ? chatData?.receiver?.artwork_url
                : chatData?.latest_message?.user?.artwork_url
                ? chatData?.latest_message?.user?.artwork_url
                : img
            }
            alt="profile_pic"
            className={`rounded-full ${
              popUp === true ? "w-10 h-10 " : "w-14 h-14"
            }`}
          />
        </Badge>
      </div>
      <div className="w-full">
        <div className="flex items-center justify-between w-full">
          {/* name and msg */}
          <div>
            <p
              className="text-base text-white"
              data-tooltip-id={`chat-username`}
            >
              {chatData?.receiver || chatData?.latest_message?.user?.name
                ? chatData.receiver?.name?.length < 15 ||
                  chatData?.latest_message?.user?.name < 15
                  ? chatData?.receiver?.name ||
                    chatData?.latest_message?.user?.name
                  : `${
                      chatData?.receiver?.name?.slice(0, 15) ||
                      chatData?.latest_message?.user?.name.slice(0, 15)
                    }...`
                : `false`}
            </p>
            {chatData?.receiver && chatData.receiver?.name?.length > 15 && (
              <div className="text-sm">
                <ReactTooltip
                  id={`${chatData.receiver?.id}-username`}
                  place="top"
                  content={chatData.name}
                />
              </div>
            )}

            <div className="flex items-center w-full gap-2">
              <p className="text-sm text-[var(--Color7B9DB4)]">
                {chatData?.latest_message
                  ? chatData?.latest_message?.content?.length < 35
                    ? chatData?.latest_message?.content
                    : `${chatData?.latest_message?.content?.slice(0, 35)}...`
                  : `No messages`}
              </p>
            </div>
          </div>

          {/* approve reject buttons */}
          <div className="flex items-center justify-end gap-3">
            <p
              className="text-green-600 cursor-pointer"
              onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                e.stopPropagation();
                handleApproveChat("approved");
              }}
            >
              Approve
            </p>
            <p
              className="text-red-600 cursor-pointer"
              onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                e.stopPropagation();
                handleApproveChat("reject");
              }}
            >
              Reject
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatRequest;
