import React, { useEffect, useState } from "react";
import BlogCard from "../shared/BlogCard";
import SubText from "components/shared/SubText";
import { Link } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import BlogService from "services/blogs/BlogService";
import BlogCardSkeleton from "../skeleton/BlogCardSkeleton";

const BlogHeroHome = () => {
  const [blogs, setBlogs] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    setIsLoading(true);
    BlogService.getAllBlogs().then((res) => {
      setIsLoading(false);
      setBlogs(res.data);
      console.log(res.data);
    });
  }, []);
  return (
    <div>
      <div className="flex justify-between w-full ">
        <div>
          <SubText text="Latest Blogs" className="font-bold" />

          <p className="text-[10px] cursor-default font-[400] text-[#7B9DB4] my-[5px] mb-3 max-sm:text-[10px]">
            Latest Blogs
          </p>
        </div>
        <Link to={RouteName.SeeAllBlogs}>
          <p className="font-[500] text-[15px] text-[#F50537] cursor-pointer mr-4">
            See all
          </p>
        </Link>
      </div>

      {!isLoading ? (
        <>
          <div className="grid w-[99%] grid-cols-3 gap-5 max-sm:grid-cols-1 max-md:grid-cols-1 max-lg:grid-cols-3">
            {blogs?.map((blog: any, i: number) => (
              <div key={i}>
                <BlogCard isLoading={true} blog={blog} />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <BlogCardSkeleton />
        </>
      )}
    </div>
  );
};

export default BlogHeroHome;
