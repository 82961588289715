import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  version: 14,
  currentUser: null,
  isFetching: false,
  error: false,
  userFavorites: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    loginStart: (state: any) => {
      state.isFetching = true;
    },
    loginSuccess: (state: any, action: any) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.error = false;
    },
    loginFailure: (state: any) => {
      state.isFetching = false;
      state.error = true;
    },
    logout: (state: any) => {
      if (state.version !== initialState.version) {
        return initialState;
      }
      localStorage.removeItem("persist:root");
      state.currentUser = null;
      state.userFavorites = null;
    },
    updatePrfile: (state: any, action: any) => {
      const updatedFields = action.payload;

      // Update only the fields that are present in updatedFields
      for (const field in updatedFields) {
        if (field in state.currentUser) {
          state.currentUser[field] = updatedFields[field];
        }
      }
    },
    updateFavorites: (state: any, action: any) => {
      state.userFavorites = action.payload;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  updatePrfile,
  updateFavorites,
} = userSlice.actions;
export default userSlice.reducer;
