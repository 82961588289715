import ContentNavbar from "components/ContentNavbar";
import Footer from "components/Footer";
import { contentNavData } from "constant/ContentNavBarData";
import GridCardsSection from "pages/welcome/GridCardSection";
import MusicCarousel from "pages/welcome/MusicCarousel";
import TrendMovies from "pages/welcome/TrendMovies";
import WelcomePageSidebar from "pages/welcome/WelcomePageSidebar";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import TVChannelSection from "./welcome/TVChannelSection";
import WelcomePagePodcast from "./welcome/WelcomePagePodcast";
import WelcomeTVSeries from "./welcome/WelcomeTVSeries";
import LikeService from "services/music/LikeService";
import HeroSlider from "pages/liveTvPage/home/HeroSlider";
import WelcomePageRadioSection from "./welcome/WelcomePageRadioSection";
import WelcomePageDiscoverPlaylists from "./welcome/shared/WelcomePageDiscoverPlaylists";
import { Helmet } from "react-helmet-async";

import { Adsense } from "@ctrl/react-adsense";
import WelcomePagePayperview from "./welcome/WelcomePagePayperview";
import WelcomePageCreatorVideoSection from "./welcome/WelcomePageCreatorVideoSection";
import PopUpModel from "components/PopUpModel";
import TermsPopUp from "components/TermsPopUp";
import TermsPopUpModel from "components/TermsPopUpModel";
import GeneralServices from "services/General/GeneralServices";

function Welcome({
  handleClick,
  isActive,
  setIsActive,
  // setPlayerIsActive,
  anyPlayerActive,
}: // user chat dat

any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [favoriteMovies, setFavoriteMovies] = useState<any>();
  const [favoriteTvSeries, setFavoriteTvSeries] = useState<any>();
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;
  const adContainerRef = useRef<HTMLDivElement | null>(null);
  const [gAdSlot, setgAdSlot] = useState<any>();
  const enabled = localStorage.getItem("ppv_enabled");
  const [termsValue, setTermsValue] = useState(() => {
    const storedValue = localStorage.getItem("terms_value");
    return storedValue !== null ? storedValue : "0";
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 1);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        const PageStatus = res?.data?.filter(
          (item: any) => item?.title === "is_home_gAd" && item?.value !== null
        );
        if (PageStatus[0]?.value === "1") {
          const filteredData = res?.data?.filter(
            (item: any) => item?.title === "gAdSlot" && item?.value !== null
          );
          setgAdSlot(filteredData[0]?.value);
          console.log("setgAdStatus", filteredData[0]?.value);
        }
      })
      .catch((e) => {});
  }, []);

  // useEffect(() => {
  //   const loadRubiconAd = () => {
  //     const scriptCode = `
  //       rp_account = '25894';
  //       rp_site = '505590';
  //       rp_zonesize = '3017318-1';
  //       rp_adtype = 'js';
  //       rp_smartfile = '[SMART FILE URL]';
  //     `;

  //     const scriptElement = document.createElement('script');
  //     scriptElement.type = 'text/javascript';
  //     scriptElement.innerHTML = scriptCode;

  //     document.head.appendChild(scriptElement);

  //     const externalScript = document.createElement('script');
  //     externalScript.type = 'text/javascript';
  //     externalScript.src = 'https://ads.rubiconproject.com/ad/25894.js';

  //     externalScript.onload = () => {
  //       // Once the external script is loaded, embed the ad into the div ref
  //       if (adContainerRef.current) {
  //         adContainerRef.current.appendChild(externalScript);
  //       }
  //       console.log('External Rubicon script loaded.');
  //     };

  //     document.head.appendChild(externalScript);
  //   };

  //   loadRubiconAd();
  // }, []);

  //show model
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (termsValue === "0") {
      openModal();
    }
  }, []);

  const isActiveTermsPopUp = () => {
    setTermsValue("1");
    localStorage.setItem("terms_value", "1");
    closeModal();
  };

  useEffect(() => {
    // setIsLoding(true);
    if (loggedInUser) {
      LikeService.getFavoriteById(loggedInUser?.id, loggedInUser?.id)
        .then((res) => {
          // setIsLoding(false);
          const favoritesMoviesIds = res?.profile?.favorite_movies?.map(
            (item: any) => item.id
          );
          const favoritesTvSeriesIds = res?.profile?.favorite_tv_series?.map(
            (item: any) => item.id
          );
          setFavoriteMovies(favoritesMoviesIds);
          setFavoriteTvSeries(favoritesTvSeriesIds);
        })
        .catch(() => {});
    }
  }, [loggedInUser]);

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  // console.log("formattedDate", formattedDate);
  const created_date = loggedInUser?.created_at?.toString().split("T")[0];
  // console.log(created_date);

  // const seoImage = "https://ayozat.co.uk/logo192.png";

  return (
    <>
      <Helmet>
        <title>
          Watch AYOZAT – Streaming Movies, Series, Live TV, Music, Radio &
          Podcasts plus Live Pay-Per-View Events.
        </title>
        <meta
          name="description"
          content="Stream Movies, Series, Live TV, Music, Radio & Podcasts plus Live Pay-Per-View Events. Watch on AYOZAT STREAM. Your Entertainment Hub!"
        />
        <link rel="canonical" href={FE_URL} />
        <meta
          property="og:title"
          content="Watch AYOZAT – Streaming Movies, Series, Live TV, Music, Radio & Podcasts plus Live Pay-Per-View Events."
        />
        <meta
          property="og:description"
          content="Stream Movies, Series, Live TV, Music, Radio & Podcasts plus Live Pay-Per-View Events. Watch on AYOZAT STREAM. Your Entertainment Hub!"
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:type" content="website" />
        <meta name="og:url" content={`${FE_URL}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Watch AYOZAT – Streaming Movies, Series, Live TV, Music, Radio & Podcasts plus Live Pay-Per-View Events."
        />
        <meta
          name="twitter:description"
          content="Stream Movies, Series, Live TV, Music, Radio & Podcasts plus Live Pay-Per-View Events. Watch on AYOZAT STREAM. Your Entertainment Hub!"
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT STREAM" />
        <meta name="twitter:url" content={`${FE_URL}`} />
      </Helmet>

      <div className={`${anyPlayerActive ? `mb-[140px]` : `mb-30`}`}>
        <ContentNavbar handleClick={handleClick} isActive={isActive} />

        <WelcomePageSidebar
          isActive={isActive}
          navData={contentNavData}
          setIsActive={setIsActive}
        />
        <div
          // className={
          //   isActive
          //     ? "md:pl-[75px] pl-[80px] duration-500 p-2 mt-2 ml-8"
          //     : "pl-0 duration-500 p-2 mt-2 ml-5"
          // }
          className={
            isActive
              ? "pl-[80px] duration-500 p-2 mt-2 ml-4 max-sm:pl-0 max-sm:ml-2 "
              : "pl-0 duration-500 p-2 mt-2 ml-8 max-sm:pl-0 max-sm:ml-2 "
          }
        >
          <div className="ml-3">
            {/* {!isLoading && ( */}
            <div className="mb-8 cursor-default">
              {loggedInUser ? (
                formattedDate === created_date ? (
                  <>
                    <h1 className="text-2xl font-medium text-white font-rubik lg:text-3xl md:text-3xl">
                      Welcome{" "}
                      <span className="font-rubik">
                        {loggedInUser?.username}
                      </span>{" "}
                      to AYOZAT
                    </h1>
                    <p className="text-sm gray-text md:text-md">
                      We are so excited to have you check out
                    </p>
                  </>
                ) : (
                  <>
                    <h1 className="text-2xl font-medium text-white font-rubik lg:text-3xl md:text-3xl">
                      Welcome back{" "}
                      <span className="font-rubik">
                        {loggedInUser?.username}
                      </span>
                      ,
                    </h1>
                    <p className="text-sm gray-text md:text-md">
                      Start back from where you left off
                    </p>
                  </>
                )
              ) : (
                <>
                  <h1 className="text-2xl font-medium text-white font-rubik lg:text-3xl md:text-3xl">
                    Welcome to AYOZAT,
                  </h1>
                </>
              )}
            </div>
            <HeroSlider />
            {/* <AdBanner468 />  */}
            {gAdSlot && (
              <Adsense
                client="ca-pub-5773929890067376"
                slot={gAdSlot}
                style={{
                  display: "flex",
                  marginBottom: "30px",
                  height: "90px",
                  justifyContent: "center",
                }}
                format="horizontal"
                data-full-width-responsive="true"
                // format="fluid"
              />
            )}
            {/* <div ref={adContainerRef}></div> */}
            {/* {gAdSlot && (
              <AdsComponent dataAdSlot={gAdSlot} />
            )} */}
            <TVChannelSection />
            <TrendMovies favorite={favoriteMovies} />
            {/* {gAdSlot && (
              <AdsComponent dataAdSlot={gAdSlot} />
            )} */}
            <WelcomeTVSeries favorite={favoriteTvSeries} />
            <WelcomePageCreatorVideoSection />
            {gAdSlot && (
              <Adsense
                client="ca-pub-5773929890067376"
                slot={gAdSlot}
                style={{
                  display: "flex",
                  marginBottom: "30px",
                  height: "90px",
                  justifyContent: "center",
                }}
                format="horizontal"
                data-full-width-responsive="true"
                // format="fluid"
              />
            )}
            <MusicCarousel user={loggedInUser} />
            {/* {gAdSlot && (
              <AdsComponent dataAdSlot={gAdSlot} />
            )} */}
            <WelcomePagePodcast />
            <WelcomePageRadioSection text="Live Radio Channels" />
            {enabled === "1" && <WelcomePagePayperview />}
            <WelcomePageDiscoverPlaylists welcomePage={true} />
            {gAdSlot && (
              <Adsense
                client="ca-pub-5773929890067376"
                slot={gAdSlot}
                style={{
                  display: "flex",
                  marginBottom: "30px",
                  height: "90px",
                  justifyContent: "center",
                }}
                format="horizontal"
                data-full-width-responsive="true"
                // format="fluid"
              />
            )}
            {/* {gAdSlot && (
              <AdsComponent dataAdSlot={gAdSlot} />
            )} */}
            <GridCardsSection />
          </div>
        </div>
        <Footer />
      </div>
      {showModal && (
        <div className="z-50">
          <TermsPopUpModel
            isOpen={showModal}
            onClose={closeModal}
            width={"800px"}
          >
            <TermsPopUp onClose={isActiveTermsPopUp} />
          </TermsPopUpModel>
        </div>
      )}
    </>
  );
}

export default Welcome;
