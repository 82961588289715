import { RouteName } from "constant/routeNames";
import React from "react";
import { Link } from "react-router-dom";

function TermsPopUp({ onClose }: any) {
  const openInNewWindow = (url: any) => {
    // Customize your window's features like size, position, scrollbars, etc.
    const windowFeatures =
      "width=1000,height=700,left=100,top=100,resizable=yes,scrollbars=yes,status=yes";
    window.open(url, "_blank", windowFeatures);
  };

  return (
    <div className="mb-5">
      <h1 className="font-bold text-white text-md">
        Read our updated
        <span
          onClick={() => openInNewWindow(RouteName.TermsAndConditions)}
          style={{
            textDecoration: "none",
            color: "#ef1c51",
            cursor: "pointer",
          }}
        >
          {" "}
          Terms and Condition{" "}
        </span>
        &
        <span
          onClick={() => openInNewWindow(RouteName.PrivacyPolicy)}
          style={{
            textDecoration: "none",
            color: "#ef1c51",
            cursor: "pointer",
          }}
        >
          {" "}
          Privacy Policy.{" "}
        </span>
        By clicking "Agree and Continue", You hereby agree and consent to the
        updated Terms of Service and Privacy Policy.
      </h1>

      <div className="flex items-center justify-center gap-5 mt-5 ">
        <button
          onClick={onClose}
          className="text-white hover:text-slate-500 border-[2px] border-solid border-[#ef1c51] p-2 rounded-full"
        >
          Agree and Continue
        </button>
      </div>
    </div>
  );
}

export default TermsPopUp;
