import { HiMenu, HiOutlineBell, HiOutlineViewGrid } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

import Logo2 from "Assets/Images/a_logo.png";
import Logo from "Assets/ayozat_logo.svg";
import LogoStream from "Assets/AyozatStreamwhite.png";
import NavbarPageNav from "components/shared/NavbarPageNav";
import { RouteName } from "constant/routeNames";
import { useRef, useState } from "react";
import { BiMenuAltLeft } from "react-icons/bi";
import { useSelector } from "react-redux";
import NavIconLayout from "./ContentNavBar/NavIconLayout";
import ProfileIconSubMenu from "./ContentNavBar/ProfileIconSubMenu";
import NotificationContent from "./shared/NotificationContent";
import { MdOutlineChat } from "react-icons/md";
import UserChatDrawer from "pages/UserChat/shared/UserChatDrawer";
import AntDDrawer from "./Drawer/AntDDrawer";
import { useUserChatWebSocket } from "hooks/useUserChatWebSocket";
import UserChatNavIcon from "./user-chat/UserChatNavIcon";

function ContentNavbar({ handleClick, title, isActive }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [openChatDrawer, setOpenChatDrawer] = useState<boolean>(false);
  const [isNewChat, setIsNewChat] = useState<boolean>(false);

  // user chat
  const showDrawer = () => {
    setOpenChatDrawer(true);
  };
  const closeDrawer = () => {
    setOpenChatDrawer(false);
  };
  // end of user chat

  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");

  const clickLogin = (e: any) => {
    // setLoginModel((current) => !current);
    navigate(RouteName.Login);
  };

  const validateKeyword = (keyword: any) => {
    // Add your validation logic here
    return keyword.trim() !== ""; // This example checks if the keyword is not empty after trimming whitespaces
  };

  // Event handler for key press in the input field
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && validateKeyword(keyword)) {
      // console.log("Searching for:", keyword);
      // const replacedKeyword = keyword.replace(" ", "-");
      if (keyword !== "") {
        navigate(RouteName.Search.replace(":keyword", keyword));
      }
    }
  };

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <>
      {/* navbar in videopage */}
      <div className="sticky top-0 z-50 navbar min-h-[60px]">
        <div className="flex w-[98%] h-16  justify-between items-center  pr-5">
          {/* side bar toggle */}

          <span
            className="ml-8 text-3xl text-white cursor-pointer top-5"
            onClick={handleClick}
          >
            {isActive ? (
              <>
                <HiMenu
                  size={35}
                  className="px-1 mx-auto mr-8 bi bi-filter-left"
                />
              </>
            ) : (
              <>
                {/* <HiMenu
                  size={35}
                  className="px-1 mx-auto mr-8 bi bi-filter-left"
                /> */}
                <BiMenuAltLeft
                  size={35}
                  className="px-1 mr-8 bi bi-filter-left"
                />
              </>
            )}
          </span>

          {/* logo */}

          <Link to={RouteName.Home}>
            {/* AYOZAT */}
            {title ? (
              <>
                <div className="flex items-center ml-3 text-xl font-extrabold navbar-image max-sm:hidden">
                  <img src={Logo} alt="logo" />
                  <span className="ml-2 text-xl font-normal text-white">
                    {title && title}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center ml-3 text-xl font-extrabold w-[230px] h-[15px] max-sm:hidden">
                  <img src={LogoStream} alt="logo" className="" />
                </div>
              </>
            )}
          </Link>

          <Link to={RouteName.Home}>
            <div className="flex items-center text-xl font-extrabold max-sm:w-7 sm:hidden ">
              {/* AYOZAT */}
              <img src={Logo2} alt="logo" />
            </div>
          </Link>

          {/* search bar section */}

          <div className="justify-center navbar-search grow ">
            <div className="md:block  ml-[35%]">
              <div className="relative flex items-center white-text">
                <span
                  className="absolute flex items-center pr-3 left-4 lg-hidden max-sm:ml-[35%] "
                  onClick={() => setIsSearchOpen(true)}
                >
                  <svg
                    xmlns="http://ww50w3.org/2000/svg"
                    className="w-5 fill-current "
                    viewBox="0 0 35.997 36.004"
                  >
                    <path
                      id="Icon_awesome-search"
                      data-name="search"
                      d="M35.508,31.127l-7.01-7.01a1.686,1.686,0,0,0-1.2-.492H26.156a14.618,14.618,0,1,0-2.531,2.531V27.3a1.686,1.686,0,0,0,.492,1.2l7.01,7.01a1.681,1.681,0,0,0,2.384,0l1.99-1.99a1.7,1.7,0,0,0,.007-2.391Zm-20.883-7.5a9,9,0,1,1,9-9A8.995,8.995,0,0,1,14.625,23.625Z"
                    ></path>
                  </svg>
                </span>
                {/* <input
                  type="text"
                  name="leadingIcon"
                  id="leadingIcon"
                  placeholder="Search.."
                  className="w-[50%] pl-12 pr-4 py-1.5 rounded-md text-md outline-none "
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyPress={handleKeyPress}
                /> */}
                <div
                  className={`w-[100%] pl-12 pr-4 py-1.5 rounded-md text-md outline-none   ${
                    isSearchOpen ? "max-sm:block " : "max-sm:hidden"
                  }`}
                >
                  <input
                    ref={searchInputRef}
                    type="text"
                    name="leadingIcon"
                    id="leadingIcon"
                    placeholder="Search.."
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    className="w-[50%] px-2 py-1.5 rounded-md text-md outline-none max-sm:w-[250px] max-sm:mx-auto max-sm:absolute max-sm:top-[30px] max-sm:right-[-100px] max-sm:z-20 "
                    onBlur={() => setIsSearchOpen(false)} // Hide the input on blur
                  />
                </div>
              </div>
            </div>
          </div>

          {/* right part of navbar */}
          {loggedInUser === null ? (
            <div className="flex flex-row items-center flex-none navbar-end">
              <NavIconLayout
                icon={<HiOutlineViewGrid />}
                content={<NavbarPageNav />}
                className="max-md:hidden"
              />
              <button
                className="px-8 py-2 ml-4 rounded-full white-text button-red"
                onClick={clickLogin}
              >
                Login
              </button>
            </div>
          ) : (
            <div className="flex flex-row flex-none navbar-end ">
              <UserChatNavIcon />
              <NavIconLayout
                icon={<HiOutlineViewGrid />}
                content={<NavbarPageNav />}
                className="max-md:hidden"
              />
              <NavIconLayout
                icon={<HiOutlineBell />}
                content={<NotificationContent />}
              />
              <NavIconLayout
                // img={
                //   "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                // }
                img={loggedInUser?.artwork_url}
                content={<ProfileIconSubMenu />}
              />
            </div>
          )}
        </div>
      </div>
      <ReactTooltip
        id={`chat icon`}
        place="bottom"
        content={`Chat`}
        className="z-50"
      />
    </>
  );
}

export default ContentNavbar;
