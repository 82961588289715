import LazyLoadImg from "components/LazyLoadImg";
import { RouteName } from "constant/routeNames";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CreatorDetailsOnPlayer = ({
  videoDetails,
  isLoading,
  title,
  thumbnail,
}: any) => {
  const { currentVideo } = useSelector((state: any) => state?.video);
  return (
    <>
      {isLoading ? (
        <>
          <div className="flex items-center justify-start my-4 ml-5 ">
            <div className="w-[80px] h-[80px] rounded-full  bg-black overflow-hidden">
              {/* <h1 className="text-5xl font-extrabold">PET Talk</h1> */}
              <Link
                to={RouteName.VideoCreator.replace(
                  "*",
                  `@${videoDetails?.creator?.username}`
                )}
              >
                <LazyLoadImg
                  imgSrc={thumbnail}
                  imgAlt={"Artist"}
                  imgHeight={"80px"}
                  imgWidth={"80px"}
                  className="w-[120px] h-full drop-shadow-2xl object-cover overflow-hidden !rounded-full"
                  style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                />
                {/* <img
                  src={thumbnail}
                  alt="Video Thumbnail"
                  className="object-cover w-full h-full rounded-full"
                /> */}
              </Link>
            </div>
            <div className="ml-5">
              <p className="font-medium text-white">{title}</p>
              <p className="text-gray-600 font-small">
                {videoDetails?.playlist?.title}
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Skeleton */}
          <div className="flex items-center justify-start w-full my-4 ml-5 rounded-full animate-pulse ">
            <div className="w-[100px] h-[80px] animate-pulse  rounded-full bg-[#1a1919] max-sm:w-[100px]"></div>
            <div className="block w-full">
              <div className="ml-5 animate-pulse w-[65%] h-[30px] bg-[#1a1919] mb-2"></div>
              <div className="ml-5 animate-pulse w-[20%] h-[20px] bg-[#1a1919]"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CreatorDetailsOnPlayer;
