import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { HiArrowLeft } from "react-icons/hi";
import { TfiLayersAlt } from "react-icons/tfi";
import { useNavigate, useParams } from "react-router-dom";

import { Tooltip as ReactTooltip } from "react-tooltip";

import VideoPlayer from "../home/shared/VideoPlayer";
import GeoLocationPopup from "../videoPlayer/shared/GeoLocationPopup";
import { TabNames } from "constant/tabNames";
import Episodes from "../videoPlayer/Episodes";
import MovieTabs from "../videoPlayer/MovieTabs";
import SourceInfo from "../videoPlayer/SourceInfo";
import TVSeriesTabs from "../videoPlayer/TVSeriesTabs";
import { RouteName } from "constant/routeNames";
import PopUpModel from "components/PopUpModel";
import LoadingSpin from "components/Loading spins/LoadingSpin";
import VideoPlayerLeftSidePanel from "../videoPlayer/VideoPlayerLeftSidePanel";

function CommonPlayerSection({
  videoDetails,
  contentType,
  openGeoLoactionModel,
  setOpenGeoLoactionModel,
  error,
  searchPage,
  currentTime,
}: any) {
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;
  const [exploreSeries, setExploreSeries] = useState(false);
  const navigate = useNavigate();
  const { slug } = useParams();
  const [selectedTab, setSelectedTab] = useState("");
  const { isFetching } = useSelector((state: any) => state?.video);
  const [height, setHeight] = useState<number>();
  // const scrollToTopRef = useRef<HTMLDivElement | null>(null);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   }, 2);

  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const navigateBack = () => {
    if (!exploreSeries) {
      if (slug) {
        if (searchPage) {
          navigate(-1);
        } else {
          const state = { playerPage: true };
          navigate(RouteName.VideoDetailRoute.replace(":slug", slug), {
            state,
          });
        }
      }
    }
  };

  // geo location function
  const closeModal = () => {
    setOpenGeoLoactionModel(false);
    // navigate(RouteName.VideoDetailRoute.replace(":slug", videoDetails?.slug));
    navigate(RouteName.Video.replace("/*", ""));
  };

  useEffect(() => {
    setSelectedTab(
      videoDetails.no_of_seasons !== undefined
        ? TabNames.Episodes
        : TabNames.Info
    );
  }, [videoDetails]);

  const heightHalfGenerator = () => {
    const aspectRatio = 16 / 9;
    const maxWidth = window.innerWidth;
    const halfHeight = maxWidth / aspectRatio;

    setHeight(halfHeight);
  };

  console.log(height);

  useEffect(() => {
    const handleResize = () => {
      heightHalfGenerator();
    };

    heightHalfGenerator();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {videoDetails?.seo_title ? `${videoDetails?.seo_title}` : "AYOZAT"}
        </title>
        <meta name="title" content={`${videoDetails?.seo_title}`} />
        <meta
          name="description"
          content={
            videoDetails?.description?.length > 100
              ? `${videoDetails?.description.slice(0, 100)}...`
              : videoDetails?.description
          }
        />
        {/* <meta property="og:image" content={videoDetails?.thumbnail_url} /> */}
        <meta name="keywords" content={videoDetails?.focus_keyword} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${videoDetails?.title} - AYOZAT`}
        />
        <meta name="twitter:image" content={videoDetails?.thumbnail_url} />
        <meta
          name="twitter:description"
          content={
            videoDetails?.description?.length > 100
              ? `${videoDetails?.description.slice(0, 100)}...`
              : videoDetails?.description
          }
        />
        <meta
          name="twitter:url"
          content={`${FE_URL}/watch/${videoDetails?.slug || ""}`}
        />
        {/* <meta name="twitter:image" content={videoDetails?.thumbnail_url} /> */}

        <meta property="og:title" content={`${videoDetails?.title} - AYOZAT`} />
        <meta
          property="og:description"
          content={
            videoDetails?.description?.length > 100
              ? `${videoDetails?.description.slice(0, 100)}...`
              : videoDetails?.description
          }
        />
        <meta property="og:image" content={videoDetails?.thumbnail_url} />
        <meta
          property="og:url"
          content={`${FE_URL}/watch/${videoDetails?.slug || ""}`}
        />
        <meta property="og:type" content="website" />
        <link
          rel="canonical"
          href={`${FE_URL}/watch/${videoDetails?.slug || ""}`}
        />
      </Helmet>

      {!isFetching ? (
        <VideoPlayerLeftSidePanel
          isActive={exploreSeries}
          videoDetails={videoDetails && videoDetails}
          loading={isFetching}
        />
      ) : (
        <></>
      )}

      <h1 className="hidden">AYOZAT Video Player</h1>

      <div className={`fixed z-50 flex  justify-between w-full px-10 mt-0`}>
        <div className="!z-10 flex items-center mt-10 opacity-10 hover:opacity-100 duration-700 max-sm:opacity-100">
          <div
            className={`!z-20 flex items-center justify-center w-10 h-9 text-2xl rounded-full max-sm:scale-[80%] max-sm:ml-[-20px] max-sm:mt-[-20px] ${
              exploreSeries
                ? "bg-gray-700 duration-500 text-gray-900"
                : "bg-white duration-500 cursor-pointer"
            }`}
            onClick={navigateBack}
          >
            <HiArrowLeft />
          </div>

          <div
            className={`!z-50 flex items-center justify-center w-9 h-9 text-2xl ml-4 text-white rounded-full cursor-pointer max-md:hidden ${
              exploreSeries
                ? "bg-red-600 duration-500"
                : "bg-black/80 duration-500"
            }`}
            onClick={() => setExploreSeries((val) => !val)}
            data-tooltip-id={`AiOutlineInfoCircle - ${contentType}`}
          >
            {contentType === "movie" ? (
              <AiOutlineInfoCircle />
            ) : (
              <TfiLayersAlt />
            )}
          </div>
          <div
            className={`pt-1.5 pl-10 text-base duration-500 font-bold bg-white rounded-full w-44 h-9 -ml-9  ${
              exploreSeries
                ? "w-44 block pl-10 duration-200"
                : "w-0 hidden duration-200"
            }`}
          >
            Explore {contentType === "movie" ? "movie" : "series"}
          </div>
          {/* <div
            className={`!z-50 absolute flex items-center justify-center right-10 w-10 h-10 text-2xl bg-white rounded-full cursor-pointer mt-0 max-sm:hidden ${
              openSideModal
                ? "mr-[400px] duration-500 z-30"
                : "mr-5 duration-500"
            }`}
            onClick={() => setOpenSideModal((val) => !val)}
          >
            {openSideModal ? <FiLogOut /> : <CiLogout />}
          </div> */}
        </div>
      </div>
      <div
        className="w-[99.98%] mx-auto overflow-hidden md:h-screen sm:bg-black"
        // ref={mainDivRef}
        // ref={scrollToTopRef}
      >
        <div className="flex justify-center mx-auto overflow-hidden">
          {!isFetching && !openGeoLoactionModel ? (
            <VideoPlayer
              videoDetails={videoDetails}
              currentTime={currentTime}
            />
          ) : (
            <div
              className={`flex flex-col items-center justify-center  max-md:mt-10 md:h-[100vh] max-sm:h-[250px] max-md:h-[500px]`}
            >
              <LoadingSpin fontSize={"60px"} color={"#f30543"} />
              {error ? (
                <div className="flex flex-col items-center justify-center mx-auto mt-5 ">
                  <p className="text-base text-gray-500">{error}</p>
                  <button
                    className="p-2 mt-5 text-white bg-red-700 rounded-md"
                    onClick={navigateBack}
                  >
                    Try Again!
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}

          {/* <VideoPlayer cdn="https://vz-6c38baf6-966.b-cdn.net/0d99cc81-3b20-411a-a0ea-46206df7aefa/playlist.m3u8" /> */}
        </div>

        {/* geo location popup */}
        {openGeoLoactionModel && (
          <div className="z-50">
            <PopUpModel
              isOpen={openGeoLoactionModel}
              onClose={closeModal}
              width={"800px"}
              showCloseButton={false}
            >
              <GeoLocationPopup onClose={closeModal} />
            </PopUpModel>
          </div>
        )}
      </div>

      {/* In Mobile View */}

      {videoDetails && (
        <div className="relative w-full mt-8 z-100 md:hidden ">
          {/* <VideoPlayerDescMobile /> */}
          <div className="items-center bg-gradient-to-r md:from-black/90 text-gray-100 md:mt-14 !md:w-[600px] md:pl-[80px] px-5 text-left !h-screen justify-center max-sm:w-full">
            {!isFetching ? (
              <>
                <div className="z-50 flex items-center mb-5 md:fixed">
                  {videoDetails.no_of_seasons !== undefined ? (
                    <TVSeriesTabs
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                    />
                  ) : (
                    <MovieTabs
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="bg-[#1a1919] animate-pulse flex items-center mb-5 md:fixed w-full h-5"></div>
              </>
            )}
            {selectedTab === TabNames.Episodes && videoDetails && (
              <Episodes videoDetails={videoDetails} loading={isFetching} />
            )}
            {selectedTab === TabNames.Info && videoDetails && (
              <SourceInfo
                videoDetails={videoDetails && videoDetails}
                loading={isFetching}
              />
            )}
          </div>
        </div>
      )}
      {contentType === "movie"
        ? !exploreSeries && (
            <ReactTooltip
              id={`AiOutlineInfoCircle - movie`}
              place="right"
              content={"Info"}
              className="!z-50 bg-slate-700 opacity-100"
            />
          )
        : !exploreSeries && (
            <ReactTooltip
              id={`AiOutlineInfoCircle - tv-series`}
              place="right"
              content={"Episodes and Info"}
              className="!z-50 bg-slate-700 opacity-100"
            />
          )}
    </>
  );
}

export default CommonPlayerSection;
