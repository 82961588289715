import { useNavigate } from "react-router-dom";
import PaymentService from "services/PaymentService";
import { formatToLocalDateString } from "utils/timeFunctions";

const PaymentModal = ({ ppv_event }: any) => {
  console.log(ppv_event);
  const navigate = useNavigate();

  const handlePaymentProcess = async (e: any) => {
    navigate("/ppv/checkout", { state: { ppv_event } });

    // const data = {
    //   amount: parseFloat(ppv_event?.price) + parseFloat(ppv_event?.service_fee),
    //   ppv_event_id: ppv_event?.id,
    //   ppv_event_name: ppv_event?.title,
    //   image: ppv_event?.thumbnailImage,
    // };

    // try {
    //   const response = await PaymentService.startPayment(data);
    //   if (response.status === 200) {
    //     window.location.replace(response.data);
    //   }
    // } catch (error) {
    //   // Handle the error here
    //   console.error("Error occurred during payment:", error);
    // }
  };

  const finalServiceFee =
    parseFloat(ppv_event?.price) * (parseFloat(ppv_event?.service_fee) / 100);

  // console.log(finalServiceFee + "Service Fee");

  const finalDiscount =
    parseFloat(ppv_event?.price) * (parseFloat(ppv_event?.discount) / 100);

  // console.log(finalDiscount + "Discount Fee");

  // const withService = parseFloat(ppv_event?.price) + finalServiceFee;

  const totalFee =
    parseFloat(ppv_event?.price) + finalServiceFee - finalDiscount;

  // const integerTotalFee = Math.floor(totalFee);

  // console.log(totalFee);
  // console.log(totalFee.toString());

  return (
    <>
      <div className="">
        <div
          className="relative my-2 overflow-hidden rounded-lg "
          // main back image
          style={{
            color: "#fff",
            backgroundImage: `url(${ppv_event?.bannerImage})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "650px",
          }}
        >
          <div
            className="absolute font-rubik right-0 bg-white max-sm:w-full w-[50%] h-[700px] overflow-hidden p-0 rounded-lg scrollbar-hide "
            style={{}}
          >
            <>
              <div className="p-4 ml-5 max-sm:pt-[100px]">
                <h1 className="text-[22px] leading-none font-bold text-black">
                  {ppv_event?.title}
                </h1>
                <div>
                  <h1 className="w-full my-3 leading-snug text-gray-600 text-[13px] h-[90px] overflow-scroll scrollbar-hide">
                    {ppv_event?.description?.length > 190
                      ? `${ppv_event?.description.slice(0, 190)}...`
                      : ppv_event?.description}
                  </h1>
                </div>
                <div className="">
                  {ppv_event?.category === "Movie" ? (
                    <>
                      <h1 className="my-4 text-center bg-red-600 text-white text-[15px] p-1 w-[60%]">
                        Available for Rent: {ppv_event?.rent_duration} Days
                      </h1>
                    </>
                  ) : (
                    <>
                      <h1 className="my-4 text-center bg-red-600 text-white text-[15px] p-1 w-[60%]">
                        {formatToLocalDateString(
                          ppv_event?.event_formatted_date
                        )}
                      </h1>
                    </>
                  )}
                </div>

                <div className="">
                  <h1 className="text-[18px] mt-8 font-bold text-black">
                    Payment
                  </h1>
                  <div className="flex justify-between mt-7">
                    <h1 className="text-[16px] font-bold text-gray-500">
                      Sub Total
                    </h1>
                    <h1 className="text-[16px] font-bold text-gray-500">
                      {"£"}
                      {ppv_event?.price}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2">
                    <h1 className="text-[16px] font-bold text-gray-500">
                      Service Fee
                    </h1>
                    <h1 className="text-[16px] font-bold text-gray-500">
                      {ppv_event?.service_fee}
                      {"%"}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2">
                    <h1 className="text-[16px] font-bold text-gray-500">
                      Discount
                    </h1>
                    <h1 className="text-[16px] font-bold text-gray-500">
                      {ppv_event?.discount}
                      {"%"}
                    </h1>
                  </div>
                  <hr className="mt-4 h-[2px] bg-black " />
                  <div className="flex justify-between mt-2">
                    <h1 className="text-[18px] font-extrabold text-gray-600">
                      Total
                    </h1>
                    <h1 className="text-[18px] font-bold text-gray-600">
                      {"£"}
                      {totalFee.toFixed(2)}
                    </h1>
                  </div>
                  <div className="w-full mt-8 ">
                    <button
                      className="w-full px-5 py-2 bg-red-600 rounded-full"
                      onClick={handlePaymentProcess}
                    >
                      <h1 className="text-[18px] text-white my-auto w-full max-lg:text-[15px]">
                        Complete Payment {"£"}
                        {totalFee.toFixed(2)}
                      </h1>
                    </button>
                  </div>
                </div>
              </div>
            </>
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentModal;
