import React from 'react'
import { AiOutlineHeart } from 'react-icons/ai'
import { FaRegCopyright } from 'react-icons/fa'

function BottomSection() {
  return (
    <div className="w-[80%] mx-auto max-sm:text-[20px]">
         <hr />
        {/* bottom section */}
      <div className="flex justify-between mt-5 mb-5 text-xs gray-text md:text-sm max-sm:block">
        <div className="flex items-center">
          <FaRegCopyright className="mx-2" /> AYOZAT {new Date().getFullYear()}{" "}
          - All Rights Reserved{" "}
        </div>
        <div className="flex items-center">
          Powered with{" "}
          <AiOutlineHeart className="mx-2 text-[15px] text-red-600" />
          <p> by</p>
          <a
            href="https://ayozat.co.uk/ayozat-tlc"
            target="_blank"
            className="ml-2"
            rel="noreferrer"
          >
            AYOZAT TLC
          </a>
        </div>
      </div>
    </div>
  )
}

export default BottomSection