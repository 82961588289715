import {
  HiOutlineLocationMarker,
  HiOutlineMail,
  HiOutlinePhone,
} from "react-icons/hi";

import { FaRegCopyright } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "Assets/ayozat_logo.svg";
import { RouteName } from "constant/routeNames";
import { AiOutlineHeart } from "react-icons/ai";
import CustomPagesServices from "services/custompages/CustomPagesServices";
import { useEffect, useState } from "react";
import GeneralServices from "services/General/GeneralServices";
import { FooterSocialMedia } from "constant/FooterSocialMedia";
import FooterResourcesLink from "./Footer/FooterResourcesLink";

function Footer() {
  const [customPage, setCustomPage] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [socialLinks, setSocialLinks] = useState<any>();
  const enabled = localStorage.getItem("ppv_enabled");
  const blogEnabled = localStorage.getItem("blog_enabled");

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      CustomPagesServices.getAllCustomPages()
        .then((res) => {
          const titles: any[] = res?.map((page: any) => page.title);
          setCustomPage(titles);
          // console.log("customPage", titles);
        })
        .catch((e) => {
          // console.log(e);
        });
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    GeneralServices.getAllConfigData()
      .then((res) => {
        // console.log(res?.data);
        const filteredData = res.data.filter((item: any) => {
          // Check if the item's title matches any title in FooterSocialMedia
          return FooterSocialMedia.some(
            (socialMedia) =>
              socialMedia.title === item.title && item.value !== null
          );
        });
        setSocialLinks(filteredData);
        // console.log(filteredData);
      })
      .catch((e) => {});
  }, []);

  const openInNewWindow = (url: any) => {
    // Customize your window's features like size, position, scrollbars, etc.
    const windowFeatures =
      "width=1000,height=700,left=100,top=100,resizable=yes,scrollbars=yes,status=yes";
    window.open(url, "_blank", windowFeatures);
  };

  return (
    // footer section
    <div className="w-[80%] mx-auto max-sm:text-[20px]">
      <div className="grid grid-cols-1 mt-8 mb-5 white-text sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 ">
        {/* section 1 */}
        <div className="col-span-2 mb-6 max-sm:col-span-1">
          <Link to="/">
            <div className="w-32">
              <img src={Logo} alt="logo" className="w-full" />
            </div>
          </Link>
          <p className="mt-3 mb-5 text-xs gray-text sm:text-sm w-[60%] max-sm:w-full max-sm:text-[13px] cursor-default">
            Stream Movies, Series, Live TV, Music, Radio & Podcasts plus Live
            Pay-Per-View Events. Watch on AYOZAT STREAM. Your Entertainment Hub!
          </p>
          <div className="flex mt-4">
            {socialLinks &&
              socialLinks.map((link: any, i: number) => {
                // Find the matching social media object
                const socialMedia = FooterSocialMedia.find(
                  (media) => media.title === link.title
                );

                if (socialMedia) {
                  const IconComponent = socialMedia.icon;
                  return (
                    <a
                      href={link.value}
                      target="_blank"
                      rel="noreferrer"
                      key={i}
                    >
                      <IconComponent className="mr-4 red-text" />
                    </a>
                  );
                }

                return null; // Return null for items with no matching social media
              })}
          </div>
        </div>

        {/* cestion 2 */}
        {/* <div className="mb-6 text-xs sm:text-sm">
          <h4 className="mb-8">Product</h4>
          <div className="gray-text">
            <p className="mb-1">Features</p>
            <p className="mb-1">Pricing</p>
            <p className="mb-1">Case Studies</p>
            <p className="mb-1">Reviews</p>
            <p className="mb-1">Updates</p>
          </div>
        </div> */}

        {/* section 3 */}
        {/* <div className="mb-6 text-xs sm:text-sm">
          <h4 className="mb-8">Company</h4>
          <div className="gray-text">
            <p className="mb-1">About</p>
            <p className="mb-1">Contact Us</p>
            <p className="mb-1">Careers</p>
            <p className="mb-1">Culture</p>
            <p className="mb-1">Blog</p>
          </div>
        </div> */}

        {/* section 1 */}
        <div className="mb-6 text-xs sm:text-sm max-sm:text-[13px]">
          <h2 className="mb-8 max-sm:text-[15px] cursor-default">Resources</h2>
          <div className="gray-text">
            <FooterResourcesLink
              text={"Download Apps"}
              route={RouteName.Apps}
            />
            <FooterResourcesLink
              text={"Why PayPerView"}
              route={RouteName.PPvLandingPage}
            />
            <FooterResourcesLink
              text={"All TV Channels"}
              route={RouteName.SeeAllChannelsRoute.replace(":slug", "all")}
            />
            <FooterResourcesLink
              text={"Watch Movies"}
              route={RouteName.SeeAllMoviesRoute}
            />
            <FooterResourcesLink
              text={"Watch TV Series"}
              route={RouteName.SeeAllSeriesRoute}
            />
            <FooterResourcesLink
              text={"Listen to Music"}
              route={RouteName.SeeAllAlbumsRoute}
            />
            <FooterResourcesLink
              text={"Listen to Podcasts"}
              route={RouteName.SeeAllNewPodcastRoute}
            />
            <FooterResourcesLink
              text={"Listen to Radio"}
              route={RouteName.SeeAllMightLikeRoute}
            />
            {blogEnabled === "1" && (
              <FooterResourcesLink text={"Blogs"} route={RouteName.BlogRoute} />
            )}
            {enabled === "1" && (
              <FooterResourcesLink
                text={"Watch PPV Events"}
                route={RouteName.PayPerView.replace("/*", "")}
              />
            )}
          </div>
        </div>

        {/* sction 2 */}

        <div className="mb-6 text-xs sm:text-sm max-sm:text-[13px]">
          <h2 className="mb-8 max-sm:text-[15px] cursor-default">Policies</h2>
          <div className="gray-text">
            {isLoading === false ? (
              <>
                {/* <Link to={RouteName.TermsAndConditions}> */}
                <p
                  className="mb-1 cursor-pointer"
                  onClick={() => openInNewWindow(RouteName.TermsAndConditions)}
                >
                  {customPage && customPage[0]}
                </p>
                {/* </Link> */}
                {/* <Link to={RouteName.PrivacyPolicy}> */}
                <p
                  className="mb-1 cursor-pointer"
                  onClick={() => openInNewWindow(RouteName.PrivacyPolicy)}
                >
                  {customPage && customPage[2]}
                </p>
                {/* </Link> */}
                {/* <Link to={RouteName.DMCA}> */}
                <p
                  className="mb-1 cursor-pointer "
                  onClick={() => openInNewWindow(RouteName.DMCA)}
                >
                  {customPage && customPage[1]}
                </p>
                {/* </Link> */}
                {/* <Link to={RouteName.CookiesAndPersonalData}> */}
                <p
                  className="mb-1 cursor-pointer "
                  onClick={() =>
                    openInNewWindow(RouteName.CookiesAndPersonalData)
                  }
                >
                  {customPage && customPage[4]}
                </p>
                {/* </Link> */}
                {/* 
                <Link to={RouteName.AboutUs}>
                  <p className="mb-1 cursor-pointer ">
                    {customPage && customPage[1]}
                  </p>
                </Link>
                
                <Link to={RouteName.LegalInformation}>
                  <p className="mb-1 cursor-pointer ">
                    {customPage && customPage[3]}
                  </p>
                </Link>

                <Link to={RouteName.CookiesAndPersonalData}>
                  <p className="mb-1 cursor-pointer">
                    {customPage && customPage[4]}
                  </p>
                </Link> */}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* sction 3 */}
        <div className="mb-6 text-xs sm:text-sm max-sm:text-[13px] cursor-pointer">
          <h2 className="mb-8 cursor-default">Contact us</h2>
          <div className=" gray-text">
            <a
              className="flex items-center mb-1 max-sm:mb-4"
              href="mailto:support@ayozathelp.com"
            >
              <div className="w-[30px]">
                <HiOutlineMail
                  size={20}
                  className="mr-3 red-text  text-[20px]"
                />
              </div>
              support@ayozathelp.com
            </a>
            {/* <p className="flex items-center mb-1 max-sm:mb-4">
              <div className="w-[30px]">
                <HiOutlinePhone className="mr-3 red-text  text-[20px]" />
              </div>{" "}
              (014)- 006 - 20055
            </p> */}
            <div className="flex mb-1 max-sm:mb-4">
              <div className="w-[30px]">
                <HiOutlineLocationMarker className="mr-3 red-text  text-[20px]" />
              </div>
              <div className="block cursor-default">
                <p className="mb-1 ">Building 3 Chiswick Park,</p>
                <p className="mb-1 ">566 Chiswick Park High Road,</p>
                <p className="mb-1 ">London, W4 5YA, United Kingdom</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      {/* bottom section */}
      <div className="flex justify-between mt-5 mb-5 text-xs gray-text md:text-sm max-sm:block">
        <div className="flex items-center">
          <FaRegCopyright className="mx-2" /> AYOZAT {new Date().getFullYear()}{" "}
          - All Rights Reserved{" "}
        </div>
        <div className="flex items-center">
          Powered with{" "}
          <AiOutlineHeart className="mx-2 text-[15px] text-red-600" />
          <p> by</p>
          <a
            href="https://ayozat.co.uk/ayozat-tlc"
            target="_blank"
            className="ml-2"
            rel="noreferrer"
          >
            AYOZAT TLC
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
