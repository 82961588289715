import { AssetTypes } from "constant/AssetTypes";
import { RouteName } from "constant/routeNames";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { removeAllPlayer } from "redux/AudioPlayerRedux";
import {
  removeLiveTVData,
  setVideoLoaded,
  setVideoStart,
  setVideoSuccess,
} from "redux/VideoPlayerRedux";
import VideoService from "services/video/VideoService";
import { toast } from "react-toastify";
import { getLastWatchTime } from "utils/timeFunctions";
import CommonPlayerSection from "./shared/CommonPlayerSection";

function VideoPlayerPage() {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.state);
  const dispatch = useDispatch<any>();

  const { currentTime } = location.state || {};
  // console.log("currentTime: ", currentTime);

  const { slug } = useParams();

  const [videoDetails, setVideoDetails] = useState<any>({});

  const [openGeoLoactionModel, setOpenGeoLoactionModel] = useState(false);

  const [error, setError] = useState("");

  const { lastWatched } = useSelector((state: any) => state?.video);

  const mainDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mainDivRef.current) {
      mainDivRef.current.scrollTop = 0;
    }
  }, []);

  const scrollToTopRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Check if it's a mobile view (adjust the condition as needed)
    if (window.innerWidth <= 768 && scrollToTopRef.current) {
      scrollToTopRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, []);

  //load data according to the id
  useEffect(() => {
    // console.log("videoDetails",videoDetails);
    let cleanedSlug = slug && slug.endsWith(".html") ? slug.slice(0, -5) : slug;
    dispatch(removeLiveTVData());
    dispatch(removeAllPlayer());
    const loadData = async () => {
      dispatch(setVideoStart());
      setTimeout(async () => {
        await VideoService.getMovieDetailsBySlug(cleanedSlug)
          .then(async (res) => {
            if (res.status) {
              setVideoDetails(res.data);
              if (res?.data?.allowed_to_watch === false) {
                setOpenGeoLoactionModel(true);
              }
              console.log(res);
              const lastWatchTime = getLastWatchTime(
                res.data.id,
                AssetTypes.Movie,
                lastWatched
              );

              const value = await {
                ...res.data,
                userCurrentTime: lastWatchTime,
                poster: res.data?.poster_image,
              };

              await dispatch(setVideoSuccess(value));

              setError("");
            }
          })
          .catch((err) => {
            toast.error("Content Not Found");
            setError("Content Not Found");
            navigate(RouteName.pageNotFound);
            dispatch(setVideoLoaded());
          });

        dispatch(setVideoLoaded());
      }, 1000);
    };
    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return (
    <>
      <CommonPlayerSection
        videoDetails={videoDetails}
        contentType="movie"
        openGeoLoactionModel={openGeoLoactionModel}
        setOpenGeoLoactionModel={setOpenGeoLoactionModel}
        error={error}
        searchPage={location?.state?.searchPage}
        currentTime={currentTime}
      />
    </>
  );
}

export default VideoPlayerPage;
