import { useEffect, useState } from "react";
import CreatorHomeTracksGrid from "./shared/CreatorHomeTracksGrid";
import CreatorHomeVideoGrid from "./shared/CreatorHomeVideoGrid";
import RecentVideosGrid from "./shared/RecentVideosGrid";
import { useSelector } from "react-redux";
import MusicService from "services/music/MusicService";
import CreatorHomeAlbumsGrid from "./shared/CreatorHomeAlbumsGrid";
import CreatorHomeLiveTVGrid from "./shared/CreatorHomeLiveTVGrid";
import CreatorsMovies from "./shared/CreatorsMovies";
import SubText from "components/shared/SubText";
import CreatorsSeries from "./shared/CreatorSeries";

function HomePage({ isLoading, creatorData }: any) {
  // console.log(isLoading);
  const [mostPlayed, setMostPlayed] = useState([]);
  const [userPlaylists, setUserPlaylists] = useState([]);
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);

  useEffect(() => {
    MusicService.getMostPlayed()
      .then((res: any) => {
        setMostPlayed(res.mostPlayedSongs);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <div className="flex max-sm:w-full max-sm:block max-lg:block max-xl:block max-xl:w-full">
        <div className="w-[50%] max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full ">
          <CreatorHomeTracksGrid
            audios={mostPlayed.slice(1, 5)}
            user={loggedInUser}
            userPlaylists={userPlaylists}
            setUserPlaylists={setUserPlaylists}
            isLoading={isLoading}
          />
        </div>
        <div className="px-4 w-[50%] max-sm:w-full max-sm:px-0  max-md:block max-lg:block max-lg:w-full max-lg:px-0 max-lg:mt-3 max-xl:w-full max-xl:block max-xl:px-0 max-xl:mt-3 ">
          <CreatorHomeVideoGrid
            isLoading={isLoading}
            creatorData={creatorData}
          />
        </div>
      </div>

      <div className="flex max-sm:w-full max-sm:block max-lg:block max-xl:block max-xl:w-full">
        <div className="w-[50%] max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full ">
          <CreatorHomeAlbumsGrid />
        </div>
        <div className="px-4 w-[50%] max-sm:w-full max-sm:px-0  max-md:block max-lg:block max-lg:w-full max-lg:px-0 max-lg:mt-3 max-xl:w-full max-xl:block max-xl:px-0 max-xl:mt-3 ">
          <CreatorHomeLiveTVGrid />
        </div>
      </div>

      <div className="flex max-sm:w-full max-sm:block max-lg:block max-xl:block max-xl:w-full">
        <div className="w-[100%] max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full ">
          {creatorData?.movies?.data.length !== 0 && (
            <div className="mt-5">
              <div className="flex justify-between mt-10 ml-3">
                <SubText text="Movies" className="font-bold" />
              </div>
              <CreatorsMovies creatorData={creatorData} />
            </div>
          )}

          {creatorData?.series?.data?.length !== 0 && (
              <div className="mt-5">
                <div className="flex justify-between mt-10 ml-3">
                  <SubText text="Series" className="font-bold" />
                </div>
                <CreatorsSeries creatorData={creatorData} />
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default HomePage;
