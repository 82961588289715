import Logo from "Assets/Images/a_logo.png";
// import { notification } from "antd";
import PackageCard from "components/shared/PackageCard";
import { useEffect, useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import AuthService from "services/auth/AuthService";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { RouteName } from "constant/routeNames";
import LoadingSpin from "components/Loading spins/LoadingSpin";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CoverPoster from "Assets/Images/CoverPoster.jpg";

import { useSelector } from "react-redux";
import { Form } from "antd";
import AntDInputField from "./editProfile/AntDInputField";

function Register() {
  // const [api, contextHolder] = notification.useNotification();
  const [registerClick, setRegisterClick] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [loadingSpin, setLoadingSpin] = useState<boolean>(false);
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(true);
  const [isMailChecked, setIsMailChecked] = useState<boolean>(true);
  const handlePackageChange = (value: any) => {
    setSelectedPackage(value);
  };
  let navigate = useNavigate();
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [form] = Form.useForm();

  useEffect(() => {
    if (loggedInUser) {
      navigate(RouteName.Home);
    }
  }, [loggedInUser, navigate]);

  const openInNewWindow = (url:any) => {
    // Customize your window's features like size, position, scrollbars, etc.
    const windowFeatures = "width=1000,height=700,left=100,top=100,resizable=yes,scrollbars=yes,status=yes";
    window.open(url, '_blank', windowFeatures);
  }

  const [values, setValues] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
    notif_features: isMailChecked,
    // package: "",
  });

  const handleCheckboxChange = (event:any) => {
    setIsTermsChecked(event.target.checked);
  };

  const handleCheckMailBoxChange = (event:any)=>{
    setIsMailChecked(event.target.checked)
  }

  useEffect(() => {
    // setValues({ ...values, package: selectedPackage });
  }, [selectedPackage]);

  const onChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [errors, setErrors] = useState({
    name: [],
    username: [],
    email: [],
    password: [],
  });

  const clickRegister = async (e: any) => {
    setLoadingSpin(true);
    e.preventDefault();
    const formData = {...form.getFieldsValue(),notif_features: isMailChecked};
    console.log("object",formData);
    setErrors((prevErrors) => ({ ...prevErrors, passwordMatch: false }));
    await AuthService.register(formData)
      .then((res) => {
        setLoadingSpin(true);
        if (res.status === 200) {
          toast.success(
            "We've sent you the verification email. Please check your inbox to complete the process",
            {
              autoClose: 7000,
            }
          );
          navigate(RouteName.Login);
        } else {
          toast.error("Registration could not Completed");
        }
        // console.log(res);
        setLoading(false);
      })
      .catch((error) => {
        setLoadingSpin(false);
        if (error.response && error.response.status === 422) {
          const errorData = error.response.data.errors;
          setErrors(errorData);
        } else {
          // Handle other types of errors (e.g., server errors)
          toast.error("An error occurred. Please try again.");
        }
        setLoading(false);
      });
  };
  const seoImage = "https://ayozat.co.uk/logo192.png";
  const FE_URL = process.env.REACT_APP_FRONTEND_URL;
  return (
    <>
      <Helmet>
        <title>AYOZAT - Register</title>
        <meta
          name="description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <link rel="canonical" content={`${FE_URL}/register`} />
        <meta property="og:title" content="AYOZAT - Register" />
        <meta
          property="og:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta property="og:image" content="https://ayozat.co.uk/logo192.png" />
        <meta property="og:url" content={`${FE_URL}/register`} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="webapp" />
        <meta name="twitter:title" content="AYOZAT - Register" />
        <meta
          name="twitter:description"
          content="AYOZAT Streaming Platform - Watch free movies and TV shows online in HD on any device anytime. Free movie website search is over."
        />
        <meta name="twitter:image" content="https://ayozat.co.uk/logo192.png" />
        <meta name="twitter:site" content="AYOZAT - Register" />
        <meta name="twitter:url" content={`${FE_URL}/register`} />
      </Helmet>
      <div
        className="overflow-hidden max-sm:w-[100%]  "
        // main back image
        style={{
          color: "#fff",
          backgroundImage: `url(${CoverPoster})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        {/* red dot top left */}
        <div className="loginpage-back scrollbar-hide">
          <div
            className="absolute h-[800px] w-[800px] -left-[350px] -top-[350px] max-sm:hidden"
            style={{
              color: "#fff",
              backgroundImage: `url('https://i.stack.imgur.com/G7vn7.png')`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="overflow-hidden login-page-form-container scrollbar-hide">
            <div
              className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer mb-4`}
            >
              <HiArrowLeft
                className="text-black"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            {/* ayosat logo part */}
            <Link to={RouteName.Home}>
              <div className="relative left-0 bg-white rounded-full w-[100px] h-[100px] flex items-center mb-10">
                <img src={Logo} alt="logo" className="w-16 h-16 mx-auto mb-2" />
              </div>
            </Link>

            {/* main topic section */}

            <div className="relative mb-20">
              <Form form={form}>
                {registerClick ? (
                  <>
                    <div
                      className={`!z-20 flex items-center justify-center w-10 h-10 text-2xl bg-white rounded-full cursor-pointer mb-4`}
                      onClick={() => setRegisterClick((val) => !val)}
                    >
                      <HiArrowLeft className="text-black" />
                    </div>
                    <div className="text-left">
                      <h1 className="mb-2 text-4xl font-bold white-text sm:text-5xl max-sm:w-[80%]">
                        You are almost there!
                      </h1>
                      <p className="mb-4 tracking-wide gray-text text-md max-sm:my-[10%]">
                        Pick a plan for you to move forward with and remember
                        you can change your plan later
                      </p>
                    </div>

                    <PackageCard
                      packageName="Premium"
                      price="0.99"
                      text1="No advertisements"
                      text2="Access to premium content"
                      text3="Access to premium content"
                      onChange={handlePackageChange}
                      selectedValue={selectedPackage}
                    />
                    <PackageCard
                      packageName="Free"
                      price="0"
                      text1="Advertisements"
                      text2="Access to premium content"
                      text3="Access to premium content"
                      onChange={handlePackageChange}
                      selectedValue={selectedPackage}
                    />
                    <button
                      className="w-full py-4 mb-4 text-lg font-bold rounded-full white-text button-red"
                      type="submit"
                      // onClick={clickRegister}
                    >
                      {loading ? (
                        <LoadingSpin fontSize={30} color={"white"} />
                      ) : (
                        `Register`
                      )}
                    </button>
                  </>
                ) : (
                  <>
                    <div className="text-left">
                      <h1 className="mb-2 text-4xl font-bold white-text sm:text-4xl">
                        Welcome!
                      </h1>
                      <p className="mb-4 text-sm tracking-wide gray-text">
                        Let's take some basic information and register now.
                      </p>
                    </div>
                    {/* buttons  */}
                    {/* <div className="flex ">
                    <button className="flex items-center justify-center w-full py-2 mb-4 mr-1 font-bold rounded-full text-md white-text button-red facebook-button">
                      <FaFacebookF className="mr-1 text-xl " /> Facebook
                    </button>
                    <button className="flex items-center justify-center w-full py-2 mb-4 ml-1 font-bold rounded-full text-md white-text button-red apple-button">
                      <FaApple className="mr-1 text-3xl" />
                      Apple
                    </button>
                  </div>
                  <button className="flex items-center justify-center w-full py-2 mb-4 font-bold border-2 border-white rounded-full text-md white-text ">
                    <img
                      src="https://img.icons8.com/fluency/48/000000/google-logo.png"
                      alt="google"
                      className="w-8 h-8 mr-2"
                    />
                    Google
                  </button>
                  <div className="grid grid-cols-3 w-[80%] mx-auto mb-3">
                    <hr className="mt-3" />
                    <p className="text-sm gray-text">Or sign in with</p>
                    <hr className="mt-3" />
                  </div> */}
                  </>
                )}

                {/* sign in form */}

                {registerClick ? (
                  <></>
                ) : (
                  <>
                    <>
                      {/* <input
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={onChange}
                          autoComplete="name"
                          placeholder="Name"
                          className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] mb-4 text-gray-600"
                        /> */}

                      <AntDInputField
                        className=" !mb-2"
                        type="text"
                        name="name"
                        placeholder="Name"
                        size="large"
                        password={false}
                      />
                      {errors.name?.length > 0 && (
                        <div className="mb-5 text-left text-red-400">
                          <span className="error">{errors.name[0]}</span>
                        </div>
                      )}

                      {/* <input
                          type="text"
                          name="username"
                          value={values.username}
                          onChange={onChange}
                          autoComplete="username"
                          placeholder="Username"
                          className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] mb-4 text-gray-600"
                        /> */}
                      <AntDInputField
                        className=" !mb-2"
                        type="text"
                        name="username"
                        placeholder="Username"
                        size="large"
                        password={false}
                      />
                      {errors.username?.length > 0 && (
                        <div className="mb-5 text-left text-red-400">
                          <span className="error">{errors.username[0]}</span>
                        </div>
                      )}

                      {/* <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={onChange}
                          value={values.email}
                          autoComplete="email"
                          className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] mb-4 text-gray-600"
                        /> */}
                      <AntDInputField
                        className=" !mb-2"
                        name="email"
                        type="email"
                        placeholder="Email"
                        size="large"
                        password={false}
                      />
                      {errors.email?.length > 0 && (
                        <div className="mb-5 text-left text-red-400">
                          <span className="error">{errors.email[0]}</span>
                        </div>
                      )}

                      <div className="flex justify-between gap-3 max-sm:block">
                        {/* <input
                          type="password"
                          name="password"
                          onChange={onChange}
                          value={values.password}
                          placeholder="Password"
                          className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] text-gray-600 mr-1"
                        />
                        <input
                          type="password"
                          name="password_confirmation"
                          onChange={onChange}
                          value={values.password_confirmation}
                          placeholder="Confirm Password"
                          className="w-full pl-4 pr-4 py-1.5 rounded outline-none h-[45px] text-gray-600 mr-1 max-sm:mt-2"
                        /> */}

                        <AntDInputField
                          className="w-full !mb-2"
                          name="password"
                          placeholder="Password"
                          type="password"
                          password={true}
                        />
                        <AntDInputField
                          className="w-full !mb-2"
                          name="password_confirmation"
                          placeholder="Confirm Password"
                          type="password"
                          password={true}
                        />
                      </div>
                      {errors.password?.length > 0 && (
                        <div className="mt-4 mb-5 text-left text-red-400">
                          <span className="error">{errors.password[0]}</span>
                        </div>
                      )}
                      <div className="flex items-center gap-2 ">
                        <input
                          type="checkbox"
                          id="topping"
                          name="topping"
                          value="Paneer"
                          checked={isTermsChecked}
                          onChange={handleCheckboxChange}
                        />
                        <p className="text-[11px] text-white text-left mt-3">
                          By clicking "Get Started", I agree to AYOZAT's
                          <span
                            style={{
                              textDecoration: "none",
                              color: "#ef1c51",
                              cursor:"pointer"
                            }}
                            onClick={() => openInNewWindow(RouteName.TermsAndConditions)}
                          >
                            {" "}
                            Terms and Condition{" "}
                          </span>
                          ,
                          <span
                            style={{
                              textDecoration: "none",
                              color: "#ef1c51",
                              cursor:"pointer"
                            }}
                            onClick={() => openInNewWindow(RouteName.CookiesAndPersonalData)}
                          >
                            {" "}
                            Cookie policy{" "}
                          </span>
                          &
                          <span
                            style={{
                              textDecoration: "none",
                              color: "#ef1c51",
                              cursor:"pointer"
                            }}
                            onClick={() => openInNewWindow(RouteName.PrivacyPolicy)}
                          >
                            {" "}
                            Privacy Policy{" "}
                          </span>
                          .
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id="topping"
                          name="topping"
                          value="Paneer"
                          checked={isMailChecked}
                          onChange={handleCheckMailBoxChange}
                        />
                        <p className="text-[11px] text-white text-left mt-4">
                        Stay ahead of the curve! Join our mailing list for exclusive offers, exciting updates, and a front-row seat to all AYOZAT PPV events
                          .
                        </p>
                      </div>
                      <button
                        disabled={!isTermsChecked}
                        className={`w-full py-4 my-4 text-lg font-bold rounded-full white-text button-red ${!isTermsChecked ? 'disabled-button' : ''}`}
                        // onClick={() => setRegisterClick((val) => !val)}
                        type="submit"
                        onClick={clickRegister}
                      >
                        {loadingSpin ? (
                          <LoadingSpin fontSize={20} color={"white"} />
                        ) : (
                          `Get Started`
                        )}
                      </button>
                      <p className="text-sm gray-text">
                        Already have an account?{" "}
                        <Link to={RouteName.Login}>
                          <span className="cursor-pointer red-text">Login</span>
                        </Link>
                      </p>
                    </>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
