import SubText from "components/shared/SubText";
import SongList from "pages/musicPage/shared/SongList";
import React, { useState, useEffect } from "react";
import SongListHome from "./SongListHome";
import MostPlayedSongSkeleton from "components/skeleton/MostPlayedSongSkeleton";

function CreatorHomeTracksGrid({
  audios,
  user,
  userPlaylists,
  setUserPlaylists,
  isLoading
}: any) {
  const [seeAll, setSeeAll] = useState(false);

  const handleShowMore = () => {
    setSeeAll(!seeAll);
  };
  return (
    <>
     {!isLoading && (
      <>
      <div className="flex justify-between mt-10 ml-6">
        <SubText text="Tracks" className="font-bold" />
      </div>
      <div className="w-[90%] ml-5 text-white max-sm:overflow-x-hidden">
        <table className="w-full px-2">
          <thead className="text-left">
            <tr className="cursor-default">
              {/* <th className="text-[12px] text-[#7B9DB4] w-[5%] pl-3 max-sm:pl-0">
                #
              </th>
              <th className=" text-[12px] text-[#7B9DB4] w-[35%] max-sm:pl-5">
                Track
              </th>
              <th className="text-[12px] text-[#7B9DB4] w-[25%] ">Artist</th>
              <th className="text-[12px] text-[#7B9DB4] w-[25%] max-sm:ml-[10px] ">
                Duration
              </th> */}
            </tr>
          </thead>
          <tr>
            <td colSpan={5} className="pr-2 max-sm:pr-3">
              {/* <hr className="my-5 border-[#7B9DB4] pr-4" /> */}
            </td>
          </tr>
          <tbody className="my-5">
            {audios.map((value: any, index: number) => (
              <SongListHome
                key={index}
                value={value}
                index={index + 1}
                user={user}
                userPlaylists={userPlaylists}
                setUserPlaylists={setUserPlaylists}
              />
            ))}
          </tbody>
        </table>
      </div>
      </>
    )}
    {isLoading && <MostPlayedSongSkeleton />}
    </>
  );
}

export default CreatorHomeTracksGrid;
