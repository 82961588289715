import PopUpModel from "components/PopUpModel";
import SocialMediaPopUp from "components/SocialMediaPopUp/SocialMediaPopUp";
import { RouteName } from "constant/routeNames";
import NewChatButton from "pages/UserChat/shared/NewChatButton";
import { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import FollowButton from "./FollowButton";
import PrivateAccount from "./PrivateAccount";
import UserEditProfileButton from "./UserEditProfileButton";
import UserProfileShareBtn from "./UserProfileShareBtn";

function UserProfileHeroSection({
  user,
  isLoading,
}: // messages,
// chats,
// createNewChat,
// sendMessage,
// notifyTyping,
// showChatProgress,
// initialScroll,
// latestChat,
any) {
  // console.log("first", user);
  const baseUrl = window.location.origin; // Get the base URL
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const [follow, setFollow] = useState<boolean>(false);
  const [updatedUser, setUpdatedUser] = useState<any>(user);

  // useEffect(() => {
  //   UserProfileService.getUserById(updatedUser.id).then((res: any) => {
  //     setUpdatedUser(res.profile);
  //     console.log(res.profile);
  //   });
  //   console.log(follow);
  // }, [follow]);
  const currentUrl = baseUrl + window.location.pathname; // Construct the complete URL

  // const copyToClipboard = async (e: any) => {
  //   const currentUrl = baseUrl + window.location.pathname; // Construct the complete URL
  //   await navigator.clipboard.writeText(currentUrl);
  //   triggerSuccessNotification(Notification.CopyToClipboard);
  // };

  // popup
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    // closeDownPlaylist();
    setShowModal(true);
  };

  const closeModal = () => {
    // closeDownPlaylist();
    setShowModal(false);
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta name="title" content={user?.name} />
          <meta name="description" content={user?.bio} />

          <meta name="twitter:title" content={user?.name} />
          <meta name="twitter:description" content={user?.bio} />
          <meta name="twitter:image" content={user?.artwork_url} />

          <meta property="og:title" content={user?.name} />
          <meta property="og:description" content={user?.bio} />
          <meta property="og:image:secure_url" content={user?.artwork_url} />
        </Helmet>
      </HelmetProvider>
      <div className="w-full">
        <div
          className="relative h-auto shadow-lg "
          style={{
            // backgroundImage: `url(${user.artwork_url})`,
            backgroundImage: `url(${user?.artwork_url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="py-10 pl-6 bg-gradient-to-t from-black to-black/50">
            <div className="flex items-center w-full max-sm:block">
              <div className="p-5 rounded-full w-[300px] h-[176px] mb-4 max-sm:w-full  ">
                {/* <HiLightningBolt className="text-6xl" /> */}
                <img
                  // src={user.artwork_url}
                  src={user?.artwork_url}
                  alt="booster"
                  className="object-cover w-[176px] h-[176px] rounded-full"
                />
              </div>
              <div className="w-[80%]">
                {/* <div className="flex items-center ml-4 ">
                <div>
                  <p className="text-white font-[500] text-[16px] max-sm:text-sm">
                    content creator
                  </p>
                </div>
                <div className="ml-4 bg-blue-500 rounded-full">
                  <AiOutlineCheckCircle className="w-[22px] h-[22px]" />
                </div>
              </div> */}
                <div className="flex max-sm:block ">
                  <div className="flex items-center ml-4 max-sm:ml-2 max-sm:bock">
                    <div>
                      <h1 className="text-[50px] font-rubik font-bold text-white max-sm:text-[28px] max-md:text-[30px] max-xl:text-[30px] max-sm:mt-5">
                        {user?.name}
                      </h1>
                    </div>
                  </div>
                  <div className="flex max-sm:block">
                    {loggedInUser ? (
                      <>
                        {loggedInUser?.id !== user?.id && (
                          <div className="flex items-center mt-2 ml-4 max-sm:ml-0">
                            <FollowButton
                              user={updatedUser}
                              setUpdatedUser={setUpdatedUser}
                              follow={follow}
                              setFollow={setFollow}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="flex items-center mt-2 ml-4 max-sm:ml-0">
                          <FollowButton
                            user={updatedUser}
                            setUpdatedUser={setUpdatedUser}
                            follow={follow}
                            setFollow={setFollow}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* <h6 className="text-[20px] font-bold text-white max-sm:text-[15px] ml-4 max-sm:ml-0">
                ({user.username})
              </h6> */}
                <div className="flex items-center my-5 ml-4 max-sm:ml-2">
                  <p className="text-sm font-medium w-[40%] text-white max-sm:text-xs max-sm:w-full">
                    {user?.bio}
                  </p>
                </div>
                <div className="flex justify-between items-center w-[90%] max-sm:block max-md:block">
                  <div>
                    <div className="flex items-center">
                      <Link
                        to={RouteName.UserProfile.replace(
                          "/*",
                          RouteName.UserFollowersRoute.replace(
                            ":username",
                            user?.username
                          )
                        )}
                      >
                        <div className="flex items-center ml-4 text-[18px] font-[600] text-[#7B9DB4] max-sm:my-2 max-sm:ml-2 ">
                          {user?.follower_count > 1
                            ? `${user?.follower_count} Followers`
                            : `${user?.follower_count} Follower`}
                        </div>
                      </Link>
                      <Link
                        to={RouteName.UserProfile.replace(
                          "/*",
                          RouteName.UserFollowingsRoute.replace(
                            ":username",
                            user?.username
                          )
                        )}
                      >
                        <div className="flex items-center ml-4 text-[18px] font-[600] text-[#7B9DB4] max-sm:my-2 max-md:my-3 ">
                          {updatedUser?.following_count > 1
                            ? `${updatedUser?.following_count} Followings`
                            : `${updatedUser?.following_count} Following`}
                        </div>
                      </Link>
                    </div>
                    <div>
                      {user &&
                        user?.id !== loggedInUser?.id &&
                        user?.activity_privacy === 1 && <PrivateAccount />}
                    </div>
                  </div>
                  <div className="flex items-center justify-end sm:gap-2 max-md:ml-3 max-md:my-auto max-sm:block max-sm:mt-2">
                    {/* <HiOutlineDocumentDuplicate
                      className="flex w-6 h-6 text-white cursor-pointer hover:text-red-500"
                      onClick={copyToClipboard}
                      data-tooltip-id="copy-profile-link"
                    /> */}

                    {loggedInUser && loggedInUser?.id !== user?.id && (
                      <NewChatButton user={user} />
                    )}

                    <UserProfileShareBtn
                      openModal={openModal}
                      // shareUrl={currentUrl}
                    />

                    {loggedInUser?.id === user?.id && (
                      // <div className="flex my-auto ml-3">
                      <UserEditProfileButton />
                      // </div>
                    )}
                  </div>

                  {showModal && (
                    <div className="z-50">
                      <PopUpModel
                        isOpen={showModal}
                        onClose={closeModal}
                        width={"800px"}
                      >
                        <SocialMediaPopUp shareUrl={currentUrl} />
                      </PopUpModel>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReactTooltip
          id="copy-profile-link"
          place="bottom"
          content="Copy to clipboard!"
        />
      </div>
    </>
  );
}

export default UserProfileHeroSection;
