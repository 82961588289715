import React from "react";
import VideoCardGrid from "./shared/VideoCardGrid";
import CreatorsMovies from "./shared/CreatorsMovies";
import SubText from "components/shared/SubText";
import CreatorsSeries from "./shared/CreatorSeries";

function VideosPage({ creatorData, slug, isLoading }: any) {
  return (
    <div>
      {creatorData?.videos?.length !== 0 && (
        <div className="mt-10">
          <VideoCardGrid
            creatorData={creatorData}
            slug={slug}
            isLoading={isLoading}
          />
        </div>
      )}
      {creatorData?.movies?.data.length !== 0 && (
        <div className="mt-5">
          <div className="flex justify-between mt-10 ml-3">
            <SubText text="Related Movies" className="font-bold" />
          </div>
          <CreatorsMovies creatorData={creatorData} />
        </div>
      )}

      {creatorData?.series?.data?.length !== 0 && (
          <div className="mt-5">
            <div className="flex justify-between mt-10 ml-3">
              <SubText text="Related Series" className="font-bold" />
            </div>
            <CreatorsSeries creatorData={creatorData} />
          </div>
        )}
    </div>
  );
}

export default VideosPage;
