import SubText from "components/shared/SubText";
import SongList from "pages/musicPage/shared/SongList";
import React, { useState, useEffect } from "react";
import SongListHome from "./SongListHome";
import AlbumService from "services/music/AlbumService";
import AlbumCard from "pages/musicPage/shared/AlbumCard";
import LiveTVService from "services/liveTv/LiveTVService";
import { ChannelCard } from "pages/liveTvPage/shared/ChannelCard";

function CreatorHomeLiveTVGrid() {
  const [channels, setChannels] = useState<any>([]);
  const [isLoading, setIsLoding] = useState<any>(false);

  useEffect(() => {
    setIsLoding(true);
    LiveTVService.getFeaturedChannels()
      .then((res) => {
      console.log(res.data);
      setIsLoding(false);
      setChannels(res.data);
    });
  }, []);
  return (
    <>
      <div className="flex justify-between mt-10 ml-6">
        <SubText text="Live TV" className="font-bold" />
      </div>
      <div className="w-[90%] mt-5 ml-5 text-white max-sm:overflow-x-hidden">
        {channels.slice(0, 4).map((channel: any, index: number) => (
           <ChannelCard data={channel} img={channel.thumbnail} className="" />
        ))}
      </div>
    </>
  );
}

export default CreatorHomeLiveTVGrid;
